<template>
    <div class="switch">
        <button
            v-for="section in sections"
            :key="section.value"
            class="button"
            :class="{ active: section.value === (current && current.value) }"
            @click="onClick(section)"
        >
            {{ section.label }}
        </button>
    </div>
</template>

<script>
export default {
    name: "Switcher",
    props: {
        sections: {
            type: Array,
            default: () => [],
            required: true,
        },
        current: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        onClick(section) {
            this.$emit("click", section);
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@ads/design-system/src/scss/variables";
.button {
    border: 1px solid $gray-light;
    border-radius: 3px;
    min-height: 50px;
    padding: 12px 28px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    margin: 3px;
}

.button.active {
    border: 1px solid #3367d6;
}
</style>
