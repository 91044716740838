import BusinessEntity from "@/types/businessEntity";

enum VideosTableActionTypes {
    EDIT = "Edit",
    VIEW = "View",
    VIEW_ORIGINAL = "VIEW_ORIGINAL",
    DELETE = "Delete",
}

export const listHeaders = [
    {
        text: "ID",
        value: "id",
        align: "center",
        sortable: true,
        width: "80px",
        cellClass: "datatable-list__cell--slim",
        class: "datatable-list__header--slim",
    },
    {
        text: "Name",
        value: "name",
        align: "left",
        sortable: true,
        class: "datatable-list__header--slim",
        cellClass: "datatable-list__cell--slim datatable-list__name-cell",
    },
    {
        text: "Original Video Resolution",
        value: "originalVideoResolution",
        align: "left",
        sortable: true,
        class: "datatable-list__header--slim",
        cellClass: "datatable-list__cell--slim",
    },
    {
        text: "Original Video Bitrate",
        value: "originalVideoBitrate",
        align: "left",
        sortable: true,
        class: "datatable-list__header--slim",
        cellClass: "datatable-list__cell--slim",
    },
    {
        text: "Rebuilt Interval",
        value: "rebuiltInterval",
        align: "left",
        sortable: true,
        class: "datatable-list__header--slim",
        cellClass: "datatable-list__cell--slim",
    },
    {
        text: "Advertiser",
        value: "businessEntity",
        align: "left",
        sortable: true,
        class: "datatable-list__header--slim",
        cellClass: "datatable-list__cell--slim",
        sort: (a: BusinessEntity, b: BusinessEntity): number => a.name.localeCompare(b.name),
    },
    {
        text: "Status",
        value: "status",
        align: "left",
        sortable: true,
        class: "datatable-list__header--slim",
        cellClass: "datatable-list__cell--slim",
    },
    {
        text: "Type",
        value: "type",
        align: "left",
        sortable: true,
        class: "datatable-list__header--slim",
        cellClass: "datatable-list__cell--slim",
    },
    {
        text: "Created",
        value: "created",
        align: "left",
        sortable: true,
        class: "datatable-list__header--slim",
        cellClass: "datatable-list__cell--slim",
    },
    {
        text: "Last Generated",
        value: "lastGenerated",
        align: "left",
        sortable: true,
        class: "datatable-list__header--slim",
        cellClass: "datatable-list__cell--slim",
    },
    {
        text: "Actions",
        value: "actions",
        align: "right",
        sortable: false,
        class: "datatable-list__header--slim",
        cellClass: "datatable-list__cell--slim",
    },
];

export const listActionRoutes = {
    [VideosTableActionTypes.EDIT]: {
        name: "Edit",
        icon: "Edit",
        routeName: "videos-edit",
    },
    [VideosTableActionTypes.DELETE]: {
        name: "Delete",
        icon: "Trash",
        routeName: "videos-delete",
    },
};

export const videoIntervalsLabels = {
    0: "Never (Static)",
    3: "Live",
    15: "15 min",
    30: "30 min",
    60: "60 min",
    120: "2 hours",
    360: "6 hours",
    720: "12 hours",
    1440: "24 hours",
};
