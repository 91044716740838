import { FetchUrlResponse, LOADING_STATES, Video, VideoLayout } from "@/modules/videos/types";
import { IVideoCreateRequest, IVideoEditRequest, videosService } from "@/modules/videos/services/VideosService";
import { videoLayoutsService } from "@/modules/videos/services/VideoLayoutsService";
import { adBuilderService } from "../services/AdBuilderService";

export default {
    initialize({ dispatch }): void {
        dispatch("fetchVideoLayouts");
    },

    async fetchVideos({ commit }): Promise<void> {
        commit("setLoading", {
            key: LOADING_STATES.ITEMS,
            value: true,
        });

        const items = await videosService.fetchVideos();

        commit("setVideos", items);
        commit("setLoading", {
            key: LOADING_STATES.ITEMS,
            value: false,
        });
    },
    async create({ commit }, video: IVideoCreateRequest): Promise<Video> {
        commit("setLoading", {
            key: LOADING_STATES.CREATE,
            value: true,
        });

        const presignedS3Request = await videosService.createVideo(video);

        commit("setLoading", {
            key: LOADING_STATES.CREATE,
            value: false,
        });

        return presignedS3Request;
    },

    async edit({ commit }, video: IVideoEditRequest): Promise<Video> {
        commit("setLoading", {
            key: LOADING_STATES.EDIT,
            value: true,
        });

        const presignedS3Request = await videosService.editVideo(video);

        commit("setLoading", {
            key: LOADING_STATES.EDIT,
            value: false,
        });

        return presignedS3Request;
    },

    async fetchVideo(_, id: number): Promise<Video> {
        return videosService.fetchVideo(id);
    },

    async viewOriginalVideo(_, id: number): Promise<FetchUrlResponse> {
        return videosService.viewOriginalVideo(id);
    },

    async viewDynamicVideo(_, id: number): Promise<FetchUrlResponse> {
        return videosService.viewDynamicVideo(id);
    },

    async markForDelete(_, id: number): Promise<Video> {
        return videosService.markForDelete(id);
    },

    async fetchVideoLayouts({ commit }): Promise<VideoLayout[]> {
        const layouts = await videoLayoutsService.fetchVideoLayouts();
        commit("setVideoLayouts", layouts);
        return layouts;
    },

    async fetchAdBuilderTemplates({ commit }): Promise<{ id: string; label: string }[]> {
        commit("setLoading", {
            key: LOADING_STATES.AD_BUILDER_TEMPLATES,
            value: true,
        });

        let templates;
        try {
            const response = await adBuilderService.fetchTemplates();
            templates = response.result.data.json.thumbnails.map(({ id, name }) => ({
                id,
                label: name,
            }));
        } catch (error) {
            console.log(`Error occured while fetching ad builder templates: ${templates}`);
            templates = [];
        }

        commit("setAdBuilderTemplates", templates);
        commit("setLoading", {
            key: LOADING_STATES.AD_BUILDER_TEMPLATES,
            value: false,
        });
        return templates;
    },
};
