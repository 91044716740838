<template>
    <section>
        <VideoForm
            v-model="video"
            submit-action="videos/create"
            after-close-route="videos-root"
            after-submit-route="videos-root"
            is-editing="false"
            :after-submit-route-params="{
                createSuccess: {
                    name: video?.name,
                },
            }"
        />
        <RouterView :video="video" />
    </section>
</template>

<script>
import VideoForm from "@/modules/videos/components/VideoForm";
import { VIDEO_TYPE } from "@/modules/videos/types";

export default {
    name: "VideosCreate",
    components: { VideoForm },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            video: {
                name: "",
                businessEntity: null,
                file: null,
                layout: null,
                filter: null,
                type: VIDEO_TYPE.CASINO,
                templateId: null,
                productSetId: null,
            },
        };
    },
    methods: {
        setDefaultProperty(object, name, value) {
            if (!Number.isInteger(object[name])) {
                this.$set(object, name, value);
            }
        },
    },
};
</script>
