import { ACTION_NAME, IRequestOptions, RequestService } from "@ads/iam-library";
import Filter from "@/modules/filters/types/filter";

export default class FiltersService {
    private readonly defaultRequestOptions: Pick<IRequestOptions, "domain" | "resource"> = {
        domain: "dynamic-display",
        resource: "filters",
    };

    private readonly requestService: RequestService;

    private readonly domainPath: string;

    constructor(requestService: RequestService, domainPath?: string) {
        this.requestService = requestService;
        this.domainPath = domainPath;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return {
            ...this.defaultRequestOptions,
            ...options,
        } as IRequestOptions;
    }

    async getById(id: number): Promise<Filter> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/${this.domainPath}/${id}`, requestOptions);
    }

    async getAll(): Promise<Filter[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/${this.domainPath}`, requestOptions);
    }

    async getAllForAdvertiserId(advertiserId: number): Promise<Filter[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/${this.domainPath}/advertisersFilters?advertiserId=${advertiserId}`, requestOptions);
    }

    async getActiveForAdvertiserId(advertiserId: number): Promise<Filter[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(
            `/${this.domainPath}/activeFiltersByAdvertiserId?advertiserId=${advertiserId}`,
            requestOptions,
        );
    }

    async getActiveForAdvertiserAndSportIdId(advertiserId: number, sportId: number): Promise<Filter[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(
            `/${this.domainPath}/activeBySportAndAdvertiserId?advertiserId=${advertiserId}&sportId=${sportId}`,
            requestOptions,
        );
    }

    async getByIdAndAdvertiserId(advertiserId: number, filterId: string): Promise<Filter[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(
            `/${this.domainPath}/byFilterIdAndAdvertiserId?advertiserId=${advertiserId}&filterId=${filterId}`,
            requestOptions,
        );
    }

    async activate(filter: Filter): Promise<Filter> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.EDIT });

        filter.active = !filter.active;
        return this.requestService.put(`/${this.domainPath}`, filter, requestOptions);
    }

    async create(filter: Filter): Promise<Filter> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.CREATE });

        return this.requestService.post(`/${this.domainPath}`, filter, requestOptions);
    }

    async edit(filter: Filter): Promise<Filter> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.EDIT });

        return this.requestService.put(`/${this.domainPath}`, filter, requestOptions);
    }
}
