import {
    METADATA_TYPES,
    MetadataRequest,
    MetadataRequestOptions,
    MetadataResponse,
} from "@/modules/templates/services/MetadataService.types";
import { metadataUrlError } from "@/errors";

export default class MetadataService {
    private readonly baseUrl: string;

    constructor() {
        const { VUE_APP_METADATA_URL } = process.env;

        if (!VUE_APP_METADATA_URL) {
            throw metadataUrlError;
        }

        this.baseUrl = VUE_APP_METADATA_URL;
    }

    private async getMetadata(options: MetadataRequestOptions): Promise<MetadataResponse> {
        const payload: MetadataRequest = {
            affiliate_id: options.affiliateId.toString(),
            metadata_categories: options.types,
        };
        const res = await fetch(this.baseUrl, {
            method: "POST",
            body: JSON.stringify(payload),
        });

        return res.json();
    }

    async getCasinoCategories(affiliateId: number): Promise<string[] | undefined> {
        const metadata = await this.getMetadata({
            affiliateId,
            types: [METADATA_TYPES.CASINO_CATEGORY],
        });

        return metadata.metadata_categories?.casino_category;
    }

    async getLanguages(affiliateId: number): Promise<string[] | undefined> {
        const metadata = await this.getMetadata({
            affiliateId,
            types: [METADATA_TYPES.SPORT_LANGUAGES],
        });

        return metadata.metadata_categories?.sport_languages;
    }
}
