<template>
    <SrModal
        :value="show"
        content-class="v-application v-application--is-ltr theme--light dynamic-display__default-library-modal"
        @input="close"
    >
        <template #header>
            <SrHeadline>Default Library</SrHeadline>

            <div class="sr-description mt-2">Here you can select a background for your dynamic display template.</div>
        </template>
        <template>
            <v-progress-circular v-show="isLoading" class="progress" indeterminate color="primary" />
            <v-item-group v-show="!isLoading" v-model="selectedIndex" class="item-group">
                <v-container>
                    <VRow>
                        <VCol v-for="(image, index) in defaultImages" :key="index" style="flex-grow: initial">
                            <v-item v-slot="{ active, toggle }">
                                <div class="item-wrapper" :class="active ? 'selected' : null" @click="toggle">
                                    <DefaultLibraryImage :size="size" :image-value="image.value" />
                                </div>
                            </v-item>
                        </VCol>
                    </VRow>
                </v-container>
            </v-item-group>
        </template>
        <template #footer>
            <VRow>
                <VCol class="d-flex justify-end">
                    <SrButton @click="close">Cancel</SrButton>
                    <SrButton class="ml-4" type="secondary" @click="save">Select</SrButton>
                </VCol>
            </VRow>
        </template>
    </SrModal>
</template>

<script>
import { SrModal, SrHeadline, SrButton } from "@ads/design-system";
import DefaultLibraryImage from "./DefaultLibraryImage";

export default {
    name: "DefaultLibraryModal",
    components: {
        DefaultLibraryImage,
        SrModal,
        SrHeadline,
        SrButton,
    },
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false,
        },
        size: {
            type: Object,
            default: () => ({}),
        },
        setDefaultImage: {
            type: Function,
            default: () => null,
        },
    },
    data() {
        return {
            defaultImages: [],
            isLoading: false,
            selectedIndex: null,
        };
    },
    watch: {
        size() {
            this.fetchDefaultImages();
        },
    },
    created() {
        this.fetchDefaultImages();
    },
    methods: {
        close() {
            this.$emit("close");
        },
        save() {
            const { value } = this.defaultImages[this.selectedIndex];
            this.$emit("save", value);
        },
        async fetchDefaultImages() {
            this.isLoading = true;

            try {
                const response = await this.$store.dispatch("templates/fetchDefaultImages", this.size);
                this.defaultImagesErrors = [];

                this.defaultImages = response;
            } catch (e) {
                this.productSetsErrors = [e.message];
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.dynamic-display__default-library-modal {
    max-width: 70%;

    .item-group {
        padding-left: 0;
    }

    .item-wrapper {
        padding: 20px 16px 28px;
        border-width: 4px;
        border-style: solid;
        border-radius: 4px;
        border-color: var(--v-gray-light-base);

        &:hover {
            cursor: pointer;
        }

        &.selected {
            border-color: var(--v-primary-base) !important;
        }
    }

    .progress {
        margin: 0 auto;
        display: block;
    }

    .sr-modal-card {
        overflow: hidden;
        padding: 3.125rem 0 0;
        flex-direction: column;
        display: flex;
    }

    .sr-modal-card-text {
        height: 100%;
        overflow: auto;
    }

    .sr-modal-card-title {
        padding: 0 3.125rem !important;
        flex-direction: column !important;
        align-items: flex-start;
    }

    .item-group {
        padding: 0 3.125rem;
    }

    .sr-modal-card-actions {
        padding: 0 3.125rem 0.9125rem !important;
    }
}
</style>
