import { IsLengthOptions } from "validator/lib/isLength";

const lengthValidationMessage = ({ propertyName, options }: { propertyName: string; options: IsLengthOptions }): string => {
    if (options.min && options.max) {
        return `${propertyName} must be between ${options.min} and ${options.max} characters long.`;
    }
    if (!options.min) {
        return `${propertyName} must be up to ${options.max} characters long.`;
    }
    if (!options.max) {
        return `${propertyName} must be at least ${options.min} characters long.`;
    }

    return null;
};

const urlValidationMessage = (propertyName: string): string =>
    `${propertyName} must be a valid URL in format: http(s)://domain.com.`;

export { lengthValidationMessage, urlValidationMessage };
