import FiltersState, { LOADING_STATES } from "@/modules/filters/types/filtersState";
import Filter from "@/modules/filters/types/filter";

export default {
    getFilters: (state: FiltersState): Filter[] => state.items,
    loadingStates:
        (state) =>
        (key: LOADING_STATES): boolean =>
            Boolean(state.loadingStates[key]),
};
