import { RouteConfig } from "vue-router";
import TemplatesRoutes from "../modules/templates/routes";
import FiltersRoutes from "../modules/filters/routes";
import TournamentsRoutes from "../modules/tournaments/routes";
import VideoRoutes from "../modules/videos/routes";

const routes: RouteConfig[] = [
    {
        name: "root",
        path: "/",
        redirect: { name: TemplatesRoutes[0].name },
    },
    ...TemplatesRoutes,
    ...FiltersRoutes,
    ...TournamentsRoutes,
    ...VideoRoutes,
];

export default routes;
