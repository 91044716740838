import isLength, { IsLengthOptions } from "validator/lib/isLength";
import isURL from "validator/lib/isURL";
import { lengthValidationMessage, urlValidationMessage } from "./messages";

type ValidationRuleResult = true | string;
type ValidationRules<T> = { (value: T): ValidationRuleResult }[];
type TextValidationOptions = {
    options: IsLengthOptions & { optional?: boolean };
    propertyName?: string;
};

export const PROPERTY_NAME_DEFAULT = "Input";

export const textValidationRules = ({
    options,
    propertyName = PROPERTY_NAME_DEFAULT,
}: TextValidationOptions): ValidationRules<string> => [
    (value: string): ValidationRuleResult => {
        const message = lengthValidationMessage({ propertyName, options });

        if (options.optional && !value) {
            return true;
        }

        return (!value && message) || isLength(value, options) || message;
    },
];

export const urlValidationRules = ({
    options,
    propertyName = PROPERTY_NAME_DEFAULT,
}: TextValidationOptions): ValidationRules<string> => [
    ...textValidationRules({ propertyName, options }),
    (value: string): ValidationRuleResult => {
        const message = urlValidationMessage(propertyName);
        const urlValidationOptions = {
            require_protocol: true,
            protocols: ["http", "https"],
        };

        return (!value && message) || isURL(value, urlValidationOptions) || message;
    },
];

export const getMinMaxValidation = (min: number, max: number): ValidationRules<number> => [
    (v): ValidationRuleResult => v >= min || `Minimum value can't be less than ${min}`,
    (v): ValidationRuleResult => v <= max || `Maximum value can't be more than ${max}`,
];
