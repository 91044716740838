<template>
    <VForm ref="form" class="template-form-layout-library" :disabled="isLoading">
        <VRow>
            <VCol>
                <SrHeadline level="2" class="mb-6" weight="bold"> Layout Library </SrHeadline>
            </VCol>
        </VRow>
        <SrHeadline level="3" class="mb-4"> Select Layout </SrHeadline>
        <VItemGroup v-show="!isLoading" v-model="template.layout" return-object :value-comparator="comparator">
            <VRow class="flex-wrap layout-container">
                <div v-for="layout in displayedLayouts" :key="layout.id" class="layout-item">
                    <VItem v-slot="{ active, toggle }" :value="layout">
                        <div class="item-wrapper" :class="{ 'item-wrapper--selected': active }" @click="toggle">
                            <div class="item-wrapper-border">
                                <LayoutCard :selected="active" :layout="layout" />
                            </div>
                        </div>
                    </VItem>
                </div>
            </VRow>
        </VItemGroup>
    </VForm>
</template>

<script>
import { SrHeadline } from "@ads/design-system";
import LayoutCard from "@/modules/templates/components/TemplateForm/TemplateFormStepLayoutLibrary/LayoutCard";
import { mapGetters } from "vuex";

export default {
    name: "TemplateFormStepLayoutLibrary",
    components: {
        LayoutCard,
        SrHeadline,
    },
    model: {
        prop: "template",
        event: "input",
    },
    props: {
        template: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            layouts: "templates/layouts",
        }),
        displayedLayouts() {
            return this.layouts.filter((layout) => layout.type === this.template.type);
        },
    },
    watch: {
        "template.type": function (type) {
            if (this.template.layout?.type !== type) {
                this.$set(
                    this.template,
                    "layout",
                    this.layouts.find((layout) => layout.type === type),
                );
            }
        },
    },
    methods: {
        comparator(a, b) {
            return a?.id === b?.id;
        },
    },
};
</script>

<style lang="scss">
.template-form-layout-library {
    .layout-container {
        margin: 0 -14px;
    }

    .layout-item {
        margin: 16px;
    }

    .item-wrapper {
        border-radius: 4px;
        border: 1px solid lightgray;
        transition: border-color 120ms ease-in-out;

        &-border {
            border: 1px solid transparent;
            border-radius: 3px;
            transition: border-color 120ms ease-in-out;
        }

        &:hover,
        &:hover &-border,
        &--selected,
        &--selected .item-wrapper-border {
            cursor: pointer;
            border-color: var(--v-primary-base);
            transition: border-color 120ms ease-in-out;
        }
    }
}
</style>
