import BusinessEntity from "@/types/businessEntity";
import { IamState, LOADING_STATES } from "./state";

export default {
    businessEntities: (state: IamState): BusinessEntity[] => state.businessEntities,
    loadingStates:
        (state) =>
        (key: LOADING_STATES): boolean =>
            Boolean(state.loadingStates[key]),
};
