<template>
    <div class="preview" :class="{ visible: isVisible }" :style="{ width: size?.width, height: size?.height }">
        <iframe
            v-if="templateUrl"
            :key="templateUrl"
            ref="preview"
            sandbox="allow-scripts allow-same-origin"
            title="Template Preview"
            class="iframe"
            :src="templateUrl"
            :width="size.width"
            :height="size.height"
        />
        <div v-if="isCustomHtml && !template.config.customHtml" class="customhtml-fallback">
            Add HTML code to render the template
        </div>
    </div>
</template>

<script>
import debounce from "debounce";
import mergeObjects from "@/utils/mergeObjects";
import StylesConfiguration from "@/lib/template-configuration/StylesConfiguration";
import { CREATIVE_TYPE, EVENT_VERSION_KEYS } from "@/modules/templates/types";
import { templateSets } from "@/modules/templates/components/TemplatePreview/templateSets";
import { ODD_TYPE } from "@/modules/templates/components/TemplateForm/TemplateFormStepCustomization/types";
import mockEvent from "./events/mockEventV1.json";
import mockEventSpreadOdds from "./events/mockEventV2.json";
import mockCasinoEvent from "./events/mockCasinoEventV1.json";
import mockCasinoEventTransparent from "./events/mockCasinoEventV1Transparent.json";
import { getTemplateUrl, TEMPLATE_URL_ORIGIN } from "./templateUrlResolver";

const DEFAULT_JACKPOT_TOTAL = "1000000";

export default {
    name: "TemplatePreview",
    inject: {
        isViewOnly: {
            default: false,
        },
    },
    props: {
        template: {
            type: Object,
            default: () => ({}),
        },
        size: {
            type: Object,
            default: () => ({}),
        },
        font: {
            type: Object,
            default: () => ({}),
        },
        flags: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            isPreviewReady: false,
            isVisible: false,
            placeholders: {},
        };
    },
    computed: {
        templateUrl() {
            if (Number.isInteger(this.size?.id)) {
                const creativeType = this.template.type;
                const templateVersion = this.template.config.eventVersion;
                const defaultSet = templateSets[this.template.type][templateVersion];
                const defaultClientId = Object.keys(defaultSet)[0];

                if (this.template.layout && this.template.eventVersion !== EVENT_VERSION_KEYS.V2F8) {
                    return getTemplateUrl({
                        creativeType,
                        clientId: defaultClientId,
                        templateId: this.template.layout.id,
                        templateVersion,
                        ...this.size,
                    });
                }

                // fallback to legacy behaviour (no library)
                return getTemplateUrl({
                    creativeType,
                    clientId: defaultClientId,
                    templateId: defaultSet[defaultClientId][0],
                    templateVersion,
                    ...this.size,
                });
            }

            return null;
        },
        dynamicConfig() {
            const { config } = this.template;

            const templateConfig = {
                transformation: config.transformation,
                labels: {
                    legalText: config.legalText,
                    offerLine1: config.offerLine1,
                    offerLine2: config.offerLine2,
                    ctaText: config.ctaText,
                    fallbackUrl: config.fallbackUrl,
                },
                canUseTeamColors: config.canUseTeamColors,
                code: {
                    value: config.customHtml,
                },
                dateFormat: config.dateFormat,
                fallbackUrl: config.fallbackUrl,
            };

            if (this.flags) {
                templateConfig.flags = this.flags;
            }

            if (config.isJackpotEnabled) {
                templateConfig.jackpot = {
                    currency: config.currency,
                    jackpotOfferLine: config.jackpotOfferLine,
                    total: DEFAULT_JACKPOT_TOTAL,
                };
            }

            if (this.template.type === CREATIVE_TYPE.SPORT) {
                templateConfig.layout = {
                    logo: config.logoPosition,
                    offers: config.offerLinesPosition,
                    teamsFallback: config.teamsFallback,
                    canUseTeamColors: config.canUseTeamColors,
                };

                templateConfig.oddsType = config.oddsType;
                templateConfig.oddsFormat = config.oddsFormat;
            }

            return templateConfig;
        },
        previewConfig() {
            const { config } = this.template;

            return {
                amountOfEvents: Number.parseInt(config.amountOfEvents, 10) || 1,
                isViewOnly: this.isViewOnly,
                dateFormat: config.dateFormat,
            };
        },
        images() {
            const { config } = this.template;

            return {
                backgroundImage: config.backgroundImage,
                brandedImage: config.brandedImage,
                fallbackImage: config.fallbackImage,
                logoImage: config.logoImage,
                preRollImage: config.preRollImage,
                postRollImage: config.postRollImage,
            };
        },
        isCustomHtml() {
            return this.template.layout?.id === 5;
        },
    },
    watch: {
        templateUrl() {
            this.isPreviewReady = false;
            this.isVisible = false;
        },
        dynamicConfig() {
            this.updateDynamicConfig();
        },
        previewConfig() {
            this.updatePreviewConfig();
        },
        "template.config": {
            deep: true,
            handler() {
                this.setPreviewStyles();
            },
        },
        images: {
            deep: true,
            handler() {
                this.setPreviewStyles();
            },
        },
        fonts() {
            this.setPreviewStyles();
        },
        "template.config.oddsType": function () {
            this.sendMessage("SET_EVENT", this.getMockEvent());
        },
    },
    created() {
        this.updateDynamicConfig = debounce(() => {
            this.sendObjectToPreview(this.dynamicConfig, "DYNAMIC_CONFIG_UPDATE");
        }, 200);

        this.updatePreviewConfig = debounce(() => {
            this.sendObjectToPreview(this.previewConfig, "PREVIEW_CONFIG_UPDATE");
        }, 200);

        window.addEventListener("message", this.previewMessageHandler);
    },
    unmounted() {
        window.removeEventListener("message", this.previewMessageHandler);
    },
    methods: {
        sendObjectToPreview(object, message) {
            const plainObject = JSON.parse(JSON.stringify(object));

            this.sendMessage(message, plainObject);
        },
        sendMessage(type, payload) {
            if (this.$refs.preview && this.isPreviewReady) {
                this.$refs.preview.contentWindow.postMessage(
                    {
                        type,
                        payload,
                    },
                    TEMPLATE_URL_ORIGIN,
                );
            }
        },
        getMockEvent() {
            let event;
            if (this.template.type === CREATIVE_TYPE.CASINO) {
                event = mockCasinoEvent;
                if (this.template.layout?.id === 6) {
                    event = mockCasinoEventTransparent;
                }
            } else if (this.template.config.oddsType === ODD_TYPE.SPREADS_TOTALS) {
                event = mockEventSpreadOdds;
            } else {
                event = mockEvent;
            }
            const eventVersion = EVENT_VERSION_KEYS.V2F8;

            return `(function(){window.EVENT_VERSION = "${eventVersion}"})();jsonp_dco(${JSON.stringify(event)});`;
        },
        onCreativeReady() {
            this.isPreviewReady = true;

            this.sendMessage("SET_EVENT", this.getMockEvent());
            this.updateDynamicConfig();
            this.updatePreviewConfig();
            this.setPreviewStyles();

            this.isVisible = true;
        },
        onTransformationChange(transformation) {
            this.$emit("transformation-change", transformation);
        },
        onOpenTransformationModal() {
            this.$emit("transformation-open");
        },
        previewMessageHandler({ data }) {
            switch (data.type) {
                case "CREATIVE_READY":
                    this.onCreativeReady();
                    break;
                case "SET_TRANSFORMATION_MATRIX":
                    this.onTransformationChange(data.data);
                    break;
                case "OPEN_TRANSFORMATION_MODAL":
                    this.onOpenTransformationModal();
                    break;
                default:
                    break;
            }
        },
        getStyles(config) {
            const stylesConfig = new StylesConfiguration(config.variables, config.images, config.font);

            return stylesConfig.renderStyleTagContent();
        },
        setPreviewStyles() {
            const stylesString = this.getStyles({
                variables: this.template.config,
                images: mergeObjects(this.images, this.placeholders),
                font: this.font,
            });

            this.sendMessage("BATCH_STYLE_UPDATE", stylesString);
        },
    },
};
</script>

<style scoped>
.preview {
    height: 100%;
    box-sizing: content-box;
    opacity: 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    user-select: none;
}

.iframe {
    border: none;
    user-select: none;
}

.customhtml-fallback {
    border: 1px dashed #e3e3e3;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #9f9f9f;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    padding: 6px 8px;
}

.visible {
    opacity: 1;
    transition: opacity 0.2s ease-in;
}
</style>
