<template>
    <SrSelect
        v-model="tournament.customerTournament"
        label="Customer tournament"
        :items="displayedTournaments"
        required
        searchable
        hide-details
        @search-keyup.enter="onSearch"
    />
</template>

<script>
import { SrSelect } from "@ads/design-system";

const minSearchLength = 3;

export default {
    name: "TournamentMappingCustomerTournamentSelect",
    components: {
        SrSelect,
    },
    props: {
        tournament: {
            type: Object,
            default: () => {},
        },
        defaultCustomerTournaments: {
            type: Array,
            default: () => {},
        },
        fetchCustomerTournaments: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            customerTournaments: [],
            validationErrors: [],
        };
    },
    computed: {
        displayedTournaments() {
            const selectedTournament = this.tournament.customerTournament;
            const tournaments = this.customerTournaments?.length ? this.customerTournaments : this.defaultCustomerTournaments;

            if (selectedTournament && tournaments.findIndex((t) => t === selectedTournament) === -1) {
                tournaments.unshift(selectedTournament);
            }

            return tournaments;
        },
    },
    methods: {
        emitError(error) {
            this.$emit("error", error);
        },
        onSearch(event) {
            const searchValue = event.target.value;

            if (searchValue.length < minSearchLength) {
                this.customerTournaments = [];
                return;
            }

            this.fetchCustomerTournaments(searchValue)
                .then((tournaments) => {
                    this.customerTournaments = tournaments.map((tournament) => tournament.tournamentName);
                })
                .catch((error) => {
                    this.emitError(error);
                    this.customerTournaments = [];
                });
        },
    },
};
</script>
