import BusinessEntity from "@/types/businessEntity";
import { IamState, LOADING_STATES } from "./state";

export default {
    setBusinessEntities(state: IamState, items: BusinessEntity[]): void {
        state.businessEntities = items;
    },
    setLoading(state: IamState, { key, value }: { key: LOADING_STATES; value: boolean }): void {
        state.loadingStates = {
            ...state.loadingStates,
            [key]: value,
        };
    },
};
