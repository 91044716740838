<template>
    <div class="code">
        <div class="lang">{{ lang }}</div>
        <VueCodeHighlight class="highlight" :lang="lang">
            <pre v-text="code" />
        </VueCodeHighlight>
        <div class="buttons mt-8">
            <SrButton :type="copied ? 'primary' : 'secondary'" elevation="0" @click="copy">
                <VIcon v-if="copied" small dense class="mr-1">mdi-check</VIcon>
                {{ copied ? "Copied" : "Copy" }}
            </SrButton>
        </div>
    </div>
</template>

<script>
import { component as VueCodeHighlight } from "vue-code-highlight";
import { SrButton } from "@ads/design-system";

export default {
    name: "CodeBlock",
    components: {
        VueCodeHighlight,
        SrButton,
    },
    props: {
        code: {
            type: String,
            default: "",
        },
        lang: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            copied: false,
        };
    },
    methods: {
        copy() {
            navigator.clipboard.writeText(this.code);

            this.copied = true;
        },
    },
};
</script>

<style lang="scss">
@import "~vue-code-highlight/themes/prism.css";

.theme--light.v-application code {
    background-color: unset;
    color: unset;
}

.code {
    position: relative;

    .lang {
        text-transform: uppercase;
        background-color: #ffffff;
        font-size: 12px;
        padding: 2px 5px;
        position: absolute;
        top: -10px;
        left: 8px;
    }

    pre {
        border: 1px solid #dedede;
        background-color: unset;
        padding: 0.5em 1em;
        border-radius: 3px;
    }

    .buttons {
        text-align: right;
    }
}
</style>
