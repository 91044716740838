export default function mergeObjects(first, second) {
    if (!first || !second) {
        return {};
    }

    return Object.entries(first).reduce((acc, [key, value]) => {
        acc[key] = value || second[key];

        return acc;
    }, {});
}
