import { LOADING_STATES } from "@/modules/filters/types/filtersState";
import { filtersService } from "@/modules/filters/services";
import Filter from "@/modules/filters/types/filter";
import transformFilterBeforeSave from "@/modules/filters/utils/transformFilterBeforeSave";

export default {
    async fetchFilters({ commit }, advertiserId: number): Promise<void> {
        commit("setLoading", {
            key: LOADING_STATES.ITEMS,
            value: true,
        });

        const items = await filtersService.getAllForAdvertiserId(advertiserId);

        commit("setFilters", items);
        commit("setLoading", {
            key: LOADING_STATES.ITEMS,
            value: false,
        });
    },

    async activate(_, filterToActivate: Filter): Promise<Filter> {
        const filter = await filtersService.activate(filterToActivate);

        return filter;
    },

    async create(_, filter: Filter): Promise<Filter> {
        const transformedFilter = transformFilterBeforeSave(filter);

        return filtersService.create(transformedFilter);
    },

    async edit(_, filter: Filter): Promise<Filter> {
        const transformedFilter = transformFilterBeforeSave(filter);

        return filtersService.edit(transformedFilter);
    },

    async fetchFilter(_, { id }: { id: number }): Promise<Filter> {
        return filtersService.getById(id);
    },
};
