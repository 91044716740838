export const formSteps = [
    {
        name: "Basics",
        isValid: true,
        component: "FilterFormStepBasics",
    },
    {
        name: "Run time",
        isValid: true,
        component: "FilterFormStepRuntime",
    },
];

export const weekdays = [
    { id: 0, label: "Mon" },
    { id: 1, label: "Tue" },
    { id: 2, label: "Wed" },
    { id: 3, label: "Thu" },
    { id: 4, label: "Fri" },
    { id: 5, label: "Sat" },
    { id: 6, label: "Sun" },
];

export enum AHEAD_INTERVAL {
    DAY = "day",
    WEEK = "week",
}

export const aheadItems = [
    { type: AHEAD_INTERVAL.DAY, label: "Day" },
    { type: AHEAD_INTERVAL.WEEK, label: "Week" },
];
