import { CREATIVE_TYPE } from "@/modules/templates/types";
import IPollingService from "@/modules/templates/services/PollingService";

export default class ExponentialRetriesPollingService implements IPollingService {
    private readonly RETRY_TIMEOUT_START = 3000;

    private readonly TIMER_VALUES_LENGTH = 8;

    private pollList: number[] = [];

    private pollItemTypes: Record<number, CREATIVE_TYPE> = {};

    private callback: Function = null;

    constructor(
        private pollFunction: Function,
        private onPolledItem: Function,
    ) {}

    public getPollList(): number[] {
        return this.pollList;
    }

    public setActionToTakeIfPollListModified(callback: Function): void {
        this.callback = callback;
    }

    public removeAll(): void {}

    public add(id: number, type: CREATIVE_TYPE): void {
        if (this.findIdInPollList(id)) {
            return;
        }
        this.pollItemTypes[id] = type;
        this.getItem(id, type, 0).then(() => null);
    }

    private findIdInPollList(id: number): number {
        return this.pollList.find((idCandidate) => idCandidate === id);
    }

    private async getItem(id: number, type: CREATIVE_TYPE, tryCount: number): Promise<void> {
        if (tryCount >= this.TIMER_VALUES_LENGTH) {
            this.pollList.push(id);
            this.callback();
            return;
        }

        try {
            const item = await this.pollFunction(id, type);
            const done = this.onPolledItem(item);

            if (done) {
            } else {
                // eslint-disable-next-line consistent-return
                return await this.waitCountAndTryAgain(id, type, tryCount);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            // eslint-disable-next-line consistent-return
            return this.waitCountAndTryAgain(id, type, tryCount);
        }
    }

    private async waitCountAndTryAgain(id: number, type: CREATIVE_TYPE, tryCount: number): Promise<void> {
        await this.sleep(this.RETRY_TIMEOUT_START + tryCount * (this.RETRY_TIMEOUT_START / 2));
        return this.getItem(id, type, tryCount + 1);
    }

    private async sleep(timeout: number): Promise<void> {
        await new Promise((resolve) => {
            setTimeout(resolve, timeout);
        });
    }
}
