<template>
    <div class="card" :class="{ active, disabled }" v-bind="$attrs" v-on="$listeners">
        <slot />
    </div>
</template>

<script>
export default {
    name: "Card",
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.card {
    padding: 31px 16px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.1s ease-in-out;
}

.card.disabled {
    cursor: not-allowed;
    filter: grayscale(100);
    opacity: 0.7;
}

.card:hover:not(.disabled),
.active {
    border-color: #3367d6;
    transition: border-color 0.1s ease-in-out;
}
</style>
