<template>
    <VRow no-gutters>
        <ListHeader headline-name="Videos" button-name="New video" route="videos-create">
            <template #search>
                <SrInput v-model="quickSearchQuery" prepend-icon="search" class="search-input" label="Quick search" />
            </template>
        </ListHeader>

        <VCol cols="12">
            <VDivider class="mb-6" />

            <ErrorBox class="mb-2" :show="error" :message="error" />

            <SrNotification v-if="warning" type="warning" :title="warning" @close="onNotificationClose" />

            <SrNotification
                v-if="showLastNotification"
                type="success"
                :title="showLastNotification"
                :timeout="10"
                @close="onNotificationClose"
            />
        </VCol>

        <DataTable
            :key="componentKey"
            :page.sync="page"
            :headers="headers"
            :items="displayedItems"
            :is-loading="isItemsLoading"
            :loading-text="loadingText"
            entity-name="Video"
        >
            <template #id="item"> {{ item.id }}</template>
            <template #name="item"> {{ item.name }}</template>
            <template #originalVideoResolution="item"> {{ item.originalVideoResolution }}</template>
            <template #originalVideoBitrate="item"> {{ item.originalVideoBitrate }}</template>
            <template #rebuiltInterval="item"> {{ formatRebuiltInterval(item.rebuiltInterval) }}</template>
            <template #businessEntity="item"> {{ item.businessEntity.name }}</template>
            <template #type="item"> {{ formatType(item.type) }}</template>
            <template #status="item"> {{ item.status }}</template>
            <template #created="item">
                <FormattedDate :date="item.created" :hour="null" :minute="null" />
            </template>
            <template #lastGenerated="item">
                <FormattedDate :date="item.lastGenerated" :hour="null" :minute="null" />
            </template>
            <template #actions="item">
                <VideoTableActions :action-routes="actionRoutes" :item="item" />
            </template>
        </DataTable>
    </VRow>
</template>

<script>
import ListHeader from "@/components/ListHeader";
import DataTable from "@/components/DataTable";
import ErrorBox from "@/components/ErrorBox";
import { SrInput, SrNotification } from "@ads/design-system";
import { mapGetters, mapActions } from "vuex";
import FormattedDate from "@/components/FormattedDate/FormattedDate";
import { defaultLoadingText, longLoadingText, longLoadingTimeout, typeErrorWarningText } from "@/utils/uiConfigs";
import { getQuickSearchResults } from "@/utils/quicksearch";
import { CreativeTypeLabels } from "@/modules/templates/strings";
import { listHeaders, listActionRoutes, videoIntervalsLabels } from "./config";
import VideoTableActions from "./VideosTableActions/VideoTableActions.vue";

const quickSearchFields = ["name", "id", "originalVideoResolution", "originalVideoBitrate", "rebuiltInterval", "status"];

const composedFieldSearchParam = [
    {
        composedField: "businessEntity",
        composedFieldName: "name",
    },
];

export default {
    name: "VideosList",
    components: {
        FormattedDate,
        ListHeader,
        DataTable,
        ErrorBox,
        SrInput,
        SrNotification,
        VideoTableActions,
    },
    data() {
        return {
            headers: listHeaders,
            quickSearchQuery: null,
            page: 1,
            error: null,
            warning: null,
            showLastNotification: null,
            loadingText: defaultLoadingText,
            actionRoutes: listActionRoutes,
            isLoading: false,
            isItemsLoading: false,
            showDeleteModal: true,
            videoIntervalsLabels,
        };
    },
    computed: {
        ...mapGetters({
            loadingStates: "videos/loadingStates",
            items: "videos/items",
        }),
        displayedItems() {
            if (this.quickSearchQuery) {
                this.resetPage();
                return this.getQuickSearchResults(this.quickSearchQuery);
            }

            return this.items;
        },
    },
    created() {
        this.fetchItems();
    },
    methods: {
        ...mapActions({
            fetchVideos: "videos/fetchVideos",
            editVideo: "videos/edit",
        }),
        onNotificationClose() {
            this.showLastNotification = null;
        },
        resetPage() {
            this.page = 1;
        },
        fetchItems() {
            const timeoutId = setTimeout(() => {
                if (this.isItemsLoading) {
                    this.loadingText = longLoadingText;
                }
            }, longLoadingTimeout);

            this.isItemsLoading = true;
            return this.fetchVideos()
                .catch((error) => {
                    if (error instanceof TypeError) {
                        this.warning = typeErrorWarningText;
                    } else {
                        this.error = error.response?.message || error.message;
                    }

                    this.isItemsLoading = false;
                })
                .finally(() => {
                    clearTimeout(timeoutId);
                    this.resetPage();
                    this.isItemsLoading = false;
                });
        },
        getQuickSearchResults(query) {
            return getQuickSearchResults(this.items, quickSearchFields, query.toLowerCase(), composedFieldSearchParam);
        },
        formatType(type) {
            return CreativeTypeLabels[type];
        },
        formatRebuiltInterval(rebuiltInterval) {
            return this.videoIntervalsLabels[rebuiltInterval];
        },
    },
};
</script>

<style>
.datatable-list__cell--slim,
.datatable-list__header--slim {
    padding: 0 6px !important;
}
</style>
