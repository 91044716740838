import { RouteConfig } from "vue-router";
import Videos from "./views";
import VideosList from "./views/list";
import VideosCreate from "./views/create";
import VideosEdit from "./views/edit";
import VideosDelete from "./views/delete";

const routes: RouteConfig[] = [
    {
        name: "videos-root",
        path: "/videos",
        component: Videos,
        redirect: { name: "videos-list" },
        children: [
            {
                name: "videos-list",
                path: "/",
                component: VideosList,
                children: [],
            },
            {
                name: "videos-create",
                path: "create",
                component: VideosCreate,
            },
            {
                name: "videos-edit",
                path: "edit/:id",
                component: VideosEdit,
            },
            {
                name: "videos-delete",
                path: "delete/:id",
                component: VideosDelete,
                props: true,
            },
        ],
    },
];

export default routes;
