import { LOADING_STATES, VideosState } from "@/modules/videos/types";

const state: VideosState = {
    items: [],
    layouts: [],
    loadingStates: {
        [LOADING_STATES.ITEMS]: false,
        [LOADING_STATES.CREATE]: false,
        [LOADING_STATES.EDIT]: false,
        [LOADING_STATES.AD_BUILDER_TEMPLATES]: false,
    },
    adBuilderTemplates: [],
};

export default state;
