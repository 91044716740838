class S3PresignedPostUploadService {
    async upload(file: File, url: string, fields: Record<string, unknown>): Promise<Response> {
        const formData = new FormData();

        formData.append("Content-Type", file.type);
        Object.entries({ ...fields, file }).forEach(([key, value]) => {
            formData.append(key, value);
        });

        return fetch(url, {
            method: "POST",
            body: formData,
        });
    }
}

export default S3PresignedPostUploadService;
export const s3PresignedPostUploadService = new S3PresignedPostUploadService();
