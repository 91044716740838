export default function emptyFieldsToNull<T>(object: Record<keyof T, T[keyof T]>): T {
    return Object.entries(object).reduce((acc, [key, value]) => {
        if (typeof value === "string" && !value.length) {
            acc[key] = null;
        } else {
            acc[key] = value;
        }

        return acc;
    }, {}) as T;
}
