export default function loadImageAsBase64(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = (e) => {
            resolve(e.target.result);
        };

        fileReader.onerror = (e) => reject(e);
    });
}
