import { render, staticRenderFns } from "./TournamentMappingRow.vue?vue&type=template&id=38d0a6d3&scoped=true"
import script from "./TournamentMappingRow.vue?vue&type=script&lang=js"
export * from "./TournamentMappingRow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d0a6d3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
