import { CREATIVE_TYPE, EVENT_VERSION_KEYS } from "@/modules/templates/types";
import { templateSets } from "@/modules/templates/components/TemplatePreview/templateSets";
import { templateSetError } from "@/errors";

const { VUE_APP_TEMPLATE_BASE_URL } = process.env;

export const TEMPLATE_URL_ORIGIN = VUE_APP_TEMPLATE_BASE_URL && new URL(VUE_APP_TEMPLATE_BASE_URL).origin;

export const getTemplateUrl = ({
    creativeType,
    templateVersion,
    clientId,
    templateId,
    height,
    width,
}: {
    creativeType: CREATIVE_TYPE;
    templateVersion: EVENT_VERSION_KEYS;
    clientId: number;
    templateId: number;
    height: number;
    width: number;
}): string => {
    const targetSet = templateSets[creativeType][templateVersion][clientId];

    if (!targetSet.includes(templateId)) {
        throw templateSetError;
    }

    return `${VUE_APP_TEMPLATE_BASE_URL}/${clientId}/${templateId}/${width}x${height}/index.html`;
};
