import { USER_LOCALE } from "@/config";
import { parseISO } from "date-fns";

const datePickerDefaultFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
} as const;

export const datePickerFormatDate = (
    isoDateString: string,
    locale = USER_LOCALE,
    formatOptions: Intl.DateTimeFormatOptions = datePickerDefaultFormatOptions,
): string => {
    if (!isoDateString) {
        return "";
    }

    const date = parseISO(isoDateString);

    return new Intl.DateTimeFormat(locale, formatOptions).format(date);
};
