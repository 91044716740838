<template>
    <div class="layout-card">
        <VRow class="sr-modal-card-title">
            <VCol sd="6" md="6" class="name-text">
                <div class="library-name">{{ layout.name }}</div>
            </VCol>
            <VCol sd="6" md="6" class="category-col">
                <div class="d-flex justify-end category-text">
                    {{ layout.category }}
                </div>
            </VCol>
        </VRow>

        <VDivider></VDivider>

        <VRow class="pa-1">
            <VCol>
                <img class="image" :src="layout.image" alt="layout image" />
            </VCol>

            <VCol class="sizes">
                <div class="size-list mb-2 mt-1">Available Sizes</div>
                <div v-for="size in layout.sizes" :key="size.id" class="size-list d-flex justify-end">
                    <span class="badge mb-1"> {{ size.label }} </span>
                </div>
            </VCol>
        </VRow>
    </div>
</template>

<script>
export default {
    name: "LayoutCard",
    props: {
        selected: {
            type: Boolean,
            required: true,
            default: false,
        },
        layout: {
            type: Object,
            required: true,
        },
    },
};
</script>
<style lang="scss">
.layout-card {
    user-select: none;

    .sizes {
        justify-content: end;
        margin: 5px;
        padding: 14px;
    }

    .name-text {
        padding-left: 14px;
    }

    .category-col {
        padding-right: 12px;
    }

    .category-text {
        margin-top: 5px;
        font-size: 0.75em;
    }

    .size-list {
        font-size: 0.75em;
    }

    .size-list {
        font-size: 0.75em;
        padding-right: 8px !important;
    }

    .sr-modal-card-title {
        margin: 0 6px;
    }

    .image {
        justify-content: start;
        margin: 16px;
        max-width: 330px;
        max-height: 330px;
    }

    .library-name {
        font-size: 1em;
        font-weight: bold;
    }

    .badge {
        display: inline-block;
        padding: 0.4em 0.5em;
        margin: 2px;
        background-color: #dedede;
        font-size: 0.85em;
        line-height: 1;
        white-space: nowrap;
        text-decoration: #9c9c9c;
        text-align: center;
        border-radius: 2px;
        min-width: 50px;
    }
}
</style>
