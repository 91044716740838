<template>
    <VRow no-gutters>
        <ListHeader headline-name="Templates" button-name="New template" route="templates-create">
            <template #search>
                <SrInput
                    v-model="quickSearchQuery"
                    label="Quick search (by ID or Name)"
                    prepend-icon="search"
                    class="search-input"
                />
            </template>
        </ListHeader>

        <VCol cols="12">
            <VDivider class="mb-6" />

            <ErrorBox class="mb-2" :show="error" :message="error" />

            <SrNotification
                v-if="isThereAFailingBuild"
                type="warning"
                :title="oneOrMoreBuildsFailed"
                @close="onNotificationClose"
            />

            <SrNotification v-if="warning" type="warning" :title="warning" @close="onNotificationClose" />

            <SrNotification
                v-if="showLastNotification"
                type="success"
                :title="showLastNotification"
                :timeout="10"
                @close="onNotificationClose"
            />
        </VCol>

        <DataTable
            :page.sync="page"
            :headers="headers"
            :items="displayedItems"
            :is-loading="isItemsLoading"
            :loading-text="loadingText"
            entity-name="Template"
            @current-items="onCurrentItems"
        >
            <template #id="item">{{ item.id }}</template>
            <template #modifiedAt="item">
                <FormattedDate :date="item.modifiedAt" />
            </template>
            <template #name="item">
                <RouterLink
                    v-if="item.build.status === buildStatuses.DONE"
                    :to="{
                        name: 'templates-view',
                        params: { id: item.id, typePath: creativeTypePaths[item.type] },
                    }"
                >
                    <span class="font-weight-bold">{{ item.name }}</span>
                </RouterLink>
                <span v-else class="font-weight-bold">{{ item.name }}</span>
            </template>
            <template #size="item"> {{ item.size.width }}x{{ item.size.height }}</template>
            <template #businessEntity="item"> {{ item.businessEntity.name }}</template>
            <template #type="item"> {{ formatType(item.type) }}</template>
            <template #build="item">
                <span :class="getStatusColorClass(item.build.status)">
                    {{ formatStatus(item.build.status) }}
                </span>
            </template>
            <template #status="item">
                <span :class="getTemplateStatusColorClass(item.status)">
                    {{ formatTemplateStatus(item.status) }}
                </span>
            </template>
            <template #actions="item">
                <a v-if="item.build.status === buildStatuses.DONE" :href="addVersionParameter(item.build.link)" target="_blank">
                    <SrButton icon>
                        <SrIcon class="mb" icon="observe" size="xs" />
                    </SrButton>
                </a>
                <TemplateTableActions :action-routes="actionRoutes" :template="item" :polling-service="pollingService" />
            </template>
        </DataTable>
    </VRow>
</template>

<script>
import ListHeader from "@/components/ListHeader";
import DataTable from "@/components/DataTable";
import ErrorBox from "@/components/ErrorBox";
import { SrIcon, SrInput, SrButton, SrNotification } from "@ads/design-system";
import { mapGetters, mapActions } from "vuex";
import FormattedDate from "@/components/FormattedDate/FormattedDate";
import { CREATIVE_TYPE } from "@/modules/templates/types";
import { pollingService } from "@/modules/templates/services";
import { CreativeTypeLabels } from "@/modules/templates/strings";
import { creativeTypePaths } from "@/modules/templates/creativeTypePaths";
import BUILD_STATUS from "@/types/buildStatus";
import { defaultLoadingText, longLoadingText, longLoadingTimeout, typeErrorWarningText } from "@/utils/uiConfigs";
import { formatBuildStatus, getBuildStatusColorClass } from "@/utils/buildStatusUi";
import { formatStatus, getStatusColorClass } from "@/utils/templateStatusUi";
import { getQuickSearchResults } from "@/utils/quicksearch";
import TemplateTableActions from "@/modules/templates/views/list/TemplatesTableActions";
import { LOADING_STATES } from "../../store/state";
import { listHeaders, listActionRoutes } from "./config";

const quickSearchFields = ["name", "id"];
const BUILD_FAIL_ERROR_MESSAGE = "There were failures with building one or more templates, please try again later";
const NO_PIXELS_WARNING_MESSAGE =
    "There are no Pixels defined for this advertiser, please consider using Pixel Manager to create them";

export default {
    name: "TemplatesList",
    components: {
        FormattedDate,
        ListHeader,
        DataTable,
        ErrorBox,
        SrInput,
        SrIcon,
        SrButton,
        SrNotification,
        TemplateTableActions,
    },
    data() {
        return {
            headers: listHeaders,
            quickSearchQuery: null,
            page: 1,
            error: null,
            warning: null,
            isThereAFailingBuild: false,
            oneOrMoreBuildsFailed: BUILD_FAIL_ERROR_MESSAGE,
            showLastNotification: null,
            buildStatuses: BUILD_STATUS,
            loadingText: defaultLoadingText,
            actionRoutes: listActionRoutes,
            creativeTypePaths,
            creativeType: CREATIVE_TYPE,
        };
    },
    computed: {
        ...mapGetters({
            items: "templates/items",
            loadingStates: "templates/loadingStates",
        }),
        isItemsLoading() {
            return this.loadingStates(LOADING_STATES.ITEMS);
        },
        displayedItems() {
            if (this.quickSearchQuery) {
                this.resetPage();
                return this.getQuickSearchResults(this.quickSearchQuery);
            }

            return this.items;
        },
        areThereFailingBuilds() {
            return pollingService.pollList.length > 0;
        },
    },
    created() {
        this.fetchItems();
        this.checkCreatedCreative();

        pollingService.setActionToTakeIfPollListModified(this.setIsThereAFailingBuild.bind(this));
    },
    methods: {
        ...mapActions({
            fetchTemplates: "templates/fetchTemplates",
            editTemplate: "templates/edit",
        }),
        checkCreatedCreative() {
            const { createSuccess, updateSuccess } = this.$route.params;

            if (createSuccess) {
                this.showLastNotification = `Creative "${createSuccess.name}" successfully created.`;

                if (!createSuccess.hasPixels) {
                    this.showLastNotification += NO_PIXELS_WARNING_MESSAGE;
                }
            } else if (updateSuccess) {
                this.showLastNotification = `Creative "${updateSuccess.name}" successfully updated.`;
            }
        },
        setIsThereAFailingBuild() {
            this.isThereAFailingBuild = pollingService.getPollList().length > 0;
        },
        onNotificationClose() {
            this.showLastNotification = null;
        },
        resetPage() {
            this.page = 1;
        },
        setItemPolling({ build, id, type }) {
            const { status } = build;
            if (status === BUILD_STATUS.PENDING) {
                const typePath = creativeTypePaths[type];
                pollingService.add(id, typePath);
            }
        },
        fetchItems() {
            const timeoutId = setTimeout(() => {
                if (this.isItemsLoading) {
                    this.loadingText = longLoadingText;
                }
            }, longLoadingTimeout);

            return this.fetchTemplates()
                .catch((error) => {
                    if (error instanceof TypeError) {
                        this.warning = typeErrorWarningText;
                    } else {
                        this.error = error.response?.message || error.message;
                    }
                })
                .finally(() => {
                    clearTimeout(timeoutId);
                    this.resetPage();
                });
        },
        getQuickSearchResults(query) {
            return getQuickSearchResults(this.items, quickSearchFields, query.toLowerCase(), []);
        },
        onCurrentItems(items) {
            pollingService.removeAll();
            items.forEach(this.setItemPolling);
        },
        formatStatus(status) {
            return formatBuildStatus(status);
        },
        formatTemplateStatus(status) {
            return formatStatus(status);
        },
        formatType(type) {
            return CreativeTypeLabels[type];
        },
        getStatusColorClass(status) {
            return getBuildStatusColorClass(status);
        },
        getTemplateStatusColorClass(status) {
            return getStatusColorClass(status);
        },
        addVersionParameter(link) {
            return link ? `${link}?version=${new Date().getTime()}` : link;
        },
    },
};
</script>

<style lang="scss">
@import "~@ads/design-system/src/scss/variables";

.custom-table {
    .topbar-actions-wrapper {
        justify-content: flex-end;
        display: flex;
    }

    .topbar-selected-count {
        display: flex;
        align-items: center;
    }

    .selected-count {
        color: $brand-blue;
        font-size: 14px;
    }
}

.text-gray {
    color: gray;
}
</style>
