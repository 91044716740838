<template>
    <div class="modal-form tournament-mapping">
        <div class="header">
            <VRow>
                <VCol>
                    <SrHeadline level="1" class="mb-0" weight="bold">Tournaments Mapping</SrHeadline>
                </VCol>
            </VRow>
        </div>
        <div class="content">
            <TournamentsMappingForm
                v-if="tournamentMappings"
                v-model="tournamentMappings"
                :is-valid.sync="isValid"
                :is-loading="loading"
            />
        </div>
    </div>
</template>

<script>
import { SrHeadline } from "@ads/design-system";
import TournamentsMappingForm from "../../components/TournamentsMappingForm";

export default {
    name: "TournamentsMapping",
    components: {
        TournamentsMappingForm,
        SrHeadline,
    },
    data() {
        return {
            show: true,
            tournamentMappings: [],
            error: null,
            isValid: false,
            loading: false,
        };
    },
    computed: {
        showErrorMessage() {
            return Boolean(this.error);
        },
    },
    watch: {
        tournamentMappings(tournamentMappings) {
            this.$emit("input", tournamentMappings);
        },
        value(tournamentMappings) {
            this.tournamentMappings = tournamentMappings;
        },
    },
};
</script>

<style lang="scss">
#microfrontend-application-container .dynamic-display-fm {
    .tournament-mapping {
        height: calc(100% - 66px); // fix for floating footer on marketing cloud
    }
}

.sr-app-container__content:has(> .tournament-mapping) {
    padding: 0 !important;
    min-width: 0 !important;
}

.tournament-mapping {
    display: flex;
    flex-direction: column;
    height: 100%;

    .header {
        padding: 50px 50px 18px;
    }

    .content {
        flex: 1 0 0;
        max-height: 100%;
    }
}
</style>
