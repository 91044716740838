<template>
    <SrModal
        :value="show"
        content-class="v-application v-application--is-ltr theme--light dynamic-display__transformation-modal"
        @input="close"
    >
        <template #header>
            <SrHeadline>Transformation Matrix</SrHeadline>
        </template>
        <template #default>
            <div class="mr-8 text-default">
                <div class="sr-description mb-10">
                    Here you can position, scale and rotate your casino ad to fit your background
                </div>
                <VRow no-gutters>
                    <VCol sm="12" md="5">
                        <VForm ref="form" v-model="isFormValid">
                            <SrHeadline level="3" class="mb-6">Ad Position</SrHeadline>
                            <SrInput
                                v-model="height"
                                label="Height %"
                                required
                                :rules="dimensionsRules"
                                type="number"
                                :max="100"
                                :min="0"
                                step="0.1"
                                @input="onChangeDebounced"
                            />
                            <SrInput
                                v-model="width"
                                label="Width %"
                                required
                                :rules="dimensionsRules"
                                type="number"
                                :max="100"
                                :min="0"
                                step="0.1"
                                @input="onChangeDebounced"
                            />
                            <SrInput
                                v-model="top"
                                label="Position top %"
                                required
                                type="number"
                                :max="100"
                                :min="0"
                                step="0.1"
                                :rules="dimensionsRules"
                                @input="onChangeDebounced"
                            />
                            <SrInput
                                v-model="left"
                                label="Position left %"
                                required
                                type="number"
                                :max="100"
                                :min="0"
                                step="0.1"
                                :rules="dimensionsRules"
                                @input="onChangeDebounced"
                            />
                            <SrSlider
                                v-model="rotate"
                                label="Rotate °"
                                :max="360"
                                :rules="rotationRules"
                                step="0.1"
                                @input="onChangeDebounced"
                            />
                        </VForm>
                    </VCol>
                    <VCol sm="12" md="6" offset-md="1">
                        <TemplatePreview
                            :template="previewTemplate"
                            :size="size"
                            :font="font"
                            :flags="flags"
                            @transformation-change="onTransformationChange"
                        />
                    </VCol>
                </VRow>
            </div>
        </template>
        <template #footer>
            <VRow>
                <VCol class="d-flex justify-end">
                    <SrButton @click="close">Cancel</SrButton>
                    <SrButton :disabled="!isFormValid" class="ml-4" type="secondary" @click="save"> Save </SrButton>
                </VCol>
            </VRow>
        </template>
    </SrModal>
</template>

<script>
import { SrModal, SrHeadline, SrInput, SrSlider, SrButton } from "@ads/design-system";
import TemplatePreview from "@/modules/templates/components/TemplatePreview";
import debounce from "debounce";
import { getMinMaxValidation } from "@/validation/rules";

export default {
    name: "TransformationMatrixModal",
    components: {
        TemplatePreview,
        SrModal,
        SrHeadline,
        SrInput,
        SrSlider,
        SrButton,
    },
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false,
        },
        template: {
            type: Object,
            default: () => ({}),
        },
        size: {
            type: Object,
            default: () => ({}),
        },
        font: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            width: 0,
            height: 0,
            top: 0,
            left: 0,
            rotate: 0,
            dimensionsRules: getMinMaxValidation(0, 100),
            rotationRules: getMinMaxValidation(0, 360),
            previewTemplate: {},
            isFormValid: false,
            flags: {
                isTransformationModal: true,
            },
        };
    },
    computed: {
        transformation() {
            return {
                width: this.width,
                height: this.height,
                top: this.top,
                left: this.left,
                rotate: this.rotate,
            };
        },
    },
    watch: {
        template: {
            deep: true,
            handler() {
                this.updatePreviewTemplate();
            },
        },
    },
    created() {
        this.onChangeDebounced = debounce(() => {
            this.onChange();
        }, 250);

        this.updatePreviewTemplate();
    },
    methods: {
        close() {
            this.$emit("close");
        },
        save() {
            this.$emit("save", this.transformation);
        },
        onChange() {
            this.$set(this.previewTemplate.config, "transformation", this.transformation);
        },
        updatePreviewTemplate() {
            this.previewTemplate = {
                ...this.template,
                config: {
                    ...this.template.config,
                    transformation: this.transformation,
                },
            };
        },
        onTransformationChange(transformation) {
            for (const [key, value] of Object.entries(transformation)) {
                if (key in this) {
                    this.$set(this, key, Math.round(Number(value) * 10) / 10);
                }
            }

            this.onChange();
            this.$refs.form.validate();
        },
    },
};
</script>

<style lang="scss">
.dynamic-display__transformation-modal {
    max-width: 50%;

    .sr-modal-card {
        padding: 18px 34px;
    }

    .sr-modal-card-text {
        padding: unset !important;
        color: var(--v-brand-blue-base);
    }

    .sr-slider .input.v-text-field {
        max-width: 60px;
    }
}
</style>
