import { LOADING_STATES } from "@/modules/templates/store/state";
import { Template, Size, Font, Layout } from "@/modules/templates/types";

export default {
    items: (state): Template[] => state.items,
    fonts: (state): Font[] => state.fonts,
    layouts: (state): Layout[] => state.layouts,
    fontById:
        (state) =>
        (id: number): Font =>
            state.fonts.find((t) => t.id === id),
    sizeById:
        (state) =>
        (layoutId: number, id: number): Size =>
            state.layouts.find((l) => l.id === layoutId).sizes.find((t) => t.id === id),
    layoutById:
        (state) =>
        (id: number): Size =>
            state.layouts.find((t) => t.id === id),
    loadingStates:
        (state) =>
        (key: LOADING_STATES): boolean =>
            Boolean(state.loadingStates[key]),
};
