import { VIDEO_TYPE } from "@/modules/videos/types";
import { IVideoCreateRequest } from "@/modules/videos/services/VideosService";

export const formSteps = [
    {
        name: "Basics",
        isValid: true,
        component: "VideoFormStepBasics",
    },
    {
        name: "Video Upload",
        isValid: true,
        component: "VideoFormStepUpload",
    },
];

const videoDefaults = {
    id: null,
    name: "",
    businessEntity: null,
    file: null,
    layout: null,
    rebuiltInterval: null,
};

export const commonKeys = Object.keys(videoDefaults);

const sportTypeDefaults = {
    ...videoDefaults,
    productSetId: null,
    templateId: null,
    filter: null,
    type: VIDEO_TYPE.SPORT,
};

const casinoTypeDefaults = {
    ...videoDefaults,
    type: VIDEO_TYPE.CASINO,
};

export const getDefaultConfig = (type: VIDEO_TYPE): IVideoCreateRequest => {
    if (type === VIDEO_TYPE.SPORT) {
        return sportTypeDefaults;
    }

    return casinoTypeDefaults;
};

export const MAX_VIDEO_SIZE = 31457280; // 30mb
export const MAX_BACKGROUND_IMAGE_SIZE = 10485760; // 10mb
