<template>
    <SrMenu v-model="optionsAreOpened" offset-y left min-width="100" :disabled="disabled">
        <template #activator="{ on, attrs }">
            <SrButton icon v-bind="attrs" :disabled="disabled" v-on="on">
                <SrIcon :active="optionsAreOpened" icon="more" size="xs" />
            </SrButton>
        </template>
        <SrList dense>
            <SrListItem
                v-for="route in actionRoutesAsArray"
                :key="route.routeName"
                :to="getRoute(route.routeName)"
                :disabled="route.disabled"
            >
                <SrListItemIcon>
                    <SrIcon :icon="route.icon" size="xs" />
                </SrListItemIcon>
                <SrListItemTitle>
                    {{ route.name }}
                </SrListItemTitle>
            </SrListItem>
            <!-- Archive -->
            <SrListItem v-if="canArchiveTemplate" @click="archive">
                <SrListItemIcon>
                    <SrIcon icon="Inventory" size="xs" />
                </SrListItemIcon>
                <SrListItemTitle> Archive </SrListItemTitle>
            </SrListItem>
            <SrListItem v-if="canActivateTemplate" @click="activate">
                <SrListItemIcon>
                    <SrIcon icon="Access" size="xs" />
                </SrListItemIcon>
                <SrListItemTitle> Activate </SrListItemTitle>
            </SrListItem>
            <SrListItem v-if="canDownloadTemplate" @click="download">
                <SrListItemIcon>
                    <SrIcon icon="Download" size="xs" />
                </SrListItemIcon>
                <SrListItemTitle> Download .zip </SrListItemTitle>
            </SrListItem>
        </SrList>
    </SrMenu>
</template>

<script>
import { SrButton, SrIcon, SrMenu, SrList, SrListItem, SrListItemTitle, SrListItemIcon } from "@ads/design-system";
import { creativeTypePaths } from "@/modules/templates/creativeTypePaths";
import { mapActions, mapMutations } from "vuex";
import TEMPLATE_STATUS from "@/types/templateStatus";
import BUILD_STATUS from "@/types/buildStatus";

export default {
    name: "TemplateTableActions",
    components: {
        SrButton,
        SrIcon,
        SrMenu,
        SrList,
        SrListItem,
        SrListItemTitle,
        SrListItemIcon,
    },
    props: {
        template: {
            type: Object,
            required: true,
        },
        actionRoutes: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            optionsAreOpened: false,
            buildStatuses: BUILD_STATUS,
        };
    },
    computed: {
        actionRoutesAsArray() {
            return Object.values(this.actionRoutes).filter((route) => {
                if (route.visibilityCondition) {
                    return route.visibilityCondition(this.lineItem);
                }

                return true;
            });
        },
        canArchiveTemplate() {
            return this.template.status === TEMPLATE_STATUS.ACTIVE;
        },
        canActivateTemplate() {
            return this.template.status === TEMPLATE_STATUS.ARCHIVED;
        },
        canDownloadTemplate() {
            const isActive = this.template.status === TEMPLATE_STATUS.ACTIVE;
            const isBuilt = this.template.build.status === BUILD_STATUS.DONE;
            const isDOOH = this.template.layout.id === 8;
            const zipIsBuilt = this.template.build.linkToCompressed !== null;
            return isDOOH && isActive && isBuilt && zipIsBuilt;
        },
    },
    methods: {
        ...mapActions({
            archiveTemplate: "templates/archive",
            activateTemplate: "templates/activate",
        }),
        ...mapMutations({
            replaceTemplate: "templates/replaceTemplate",
        }),
        getRoute(path) {
            const { id, type, filterId } = this.template;
            return {
                name: path,
                params: { id, typePath: creativeTypePaths[type], filterId },
            };
        },
        async archive() {
            const updatedTemplate = await this.archiveTemplate(this.template);
            this.replaceTemplate(updatedTemplate);
        },
        async activate() {
            const updatedTemplate = await this.activateTemplate(this.template);
            this.replaceTemplate(updatedTemplate);
        },
        async download() {
            document.location.href = this.template.build.linkToCompressed;
        },
    },
};
</script>
