export enum LAYOUT_POSITION {
    TOP = "top",
    BOTTOM = "bottom",
}

export enum TEAM_IMAGE_TYPE {
    LOGO = "TEAM_LOGOS",
    BADGE = "TEAM_CUSTOM_BADGES",
    JERSEY = "TEAM_JERSEYS",
    NONE = "NONE",
}

export enum ODD_TYPE {
    THREE_WAY_ODDS = 1,
    SPREADS_TOTALS = 2,
}

export enum ODD_FORMAT {
    DECIMAL = 1,
    AMERICAN = 2,
}