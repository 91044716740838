import Filter from "@/modules/filters/types/filter";

export enum LOADING_STATES {
    ITEMS = "filters",
    CREATE = "createFilter",
}

export default interface FiltersState {
    items: Filter[];
    loadingStates: {
        [key in LOADING_STATES]: boolean;
    };
}
