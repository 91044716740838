<template>
    <VForm ref="form" v-model="isFormValid" class="preview-horizontal-container">
        <div class="dragging-container" @mousedown.left.prevent="onMouseDown" @mouseup.left.prevent="onMouseUp">
            <vue-horizontal ref="horizontal" responsive class="preview-horizontal" snap="none" :button="false" @scroll="onScroll">
                <div class="preview-horizontal-item" :style="dropzoneSizeWrapperStyle">
                    <span class="horizontal-slide-title">Pre-Roll</span>
                    <CreativeImageUpload
                        name="preRollImage"
                        :size="size"
                        :image="template.config?.preRollImage?.value"
                        @change="onImageChange"
                    />
                </div>
                <div class="preview-horizontal-item" :style="dropzoneSizeWrapperStyle">
                    <span class="horizontal-slide-title">Creative</span>
                    <TemplatePreviewTabs :template="template" :size="size" :font="font" v-on="$listeners" />
                </div>
                <div class="preview-horizontal-item" :style="dropzoneSizeWrapperStyle">
                    <span class="horizontal-slide-title">Post-Roll</span>
                    <CreativeImageUpload
                        name="postRollImage"
                        :size="size"
                        :image="template.config?.postRollImage?.value"
                        @change="onImageChange"
                    />
                </div>
                <div class="preview-horizontal-item" :style="dropzoneSizeWrapperStyle">
                    <span class="horizontal-slide-title"> Fallback <span class="required-indicator">*</span> </span>
                    <CreativeImageUpload
                        name="fallbackImage"
                        :size="size"
                        :image="template.config?.fallbackImage?.value"
                        is-required
                        @change="onImageChange"
                    />
                </div>
            </vue-horizontal>
            <div class="hint">
                <span class="hint-text">{{ hint }}</span>
            </div>
        </div>
    </VForm>
</template>

<script>
import VueHorizontal from "vue-horizontal";
import TemplatePreviewTabs from "@/modules/templates/components/TemplatePreviewTabs";
import { mapGetters } from "vuex";
import CreativeImageUpload from "@/modules/templates/components/TemplateForm/TemplateFormStepCustomization/CreativeImageUpload.vue";

export default {
    name: "HorizontalDraggableList",
    components: {
        CreativeImageUpload,
        VueHorizontal,
        TemplatePreviewTabs,
    },
    props: {
        template: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hint: "this is a preview and the real template is not affected before saving it",
            left: 0,
            originX: 0,
            originLeft: 0,
            isFormValid: this.isValid,
        };
    },
    computed: {
        ...mapGetters({
            fontById: "templates/fontById",
        }),
        size() {
            const { layout, size } = this.template;

            return Number.isInteger(size) ? layout?.sizes.find((s) => s.id === size) : size;
        },
        font() {
            const font = this.template.config.font;

            return Number.isInteger(font) ? this.fontById(font) : font;
        },
        dropzoneSizeWrapperStyle() {
            return {
                width: `${this.size?.width || 300}px`,
                height: `${this.size?.height || 600}px`,
            };
        },
        isDynamicLayout() {
            return this.template.layout?.id === 3;
        },
        isFrenchRivieraLayout() {
            return this.template.layout?.id === 4;
        },
    },
    watch: {
        size() {
            this.$nextTick(() => {
                this.scrollToCreative();
            });
        },
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
    },
    unmounted() {
        this.onMouseUp();
    },
    mounted() {
        this.$nextTick(() => {
            this.scrollToCreative();
        });
    },
    methods: {
        onScroll({ left }) {
            this.left = left;
        },
        onMouseDown(e) {
            this.originX = e.pageX;
            this.originLeft = this.left;

            window.addEventListener("mouseup", this.onMouseUp);
            window.addEventListener("mousemove", this.onMouseMove);
        },
        onMouseUp() {
            window.removeEventListener("mouseup", this.onMouseUp);
            window.removeEventListener("mousemove", this.onMouseMove);
        },
        onMouseMove(e) {
            const offset = e.pageX - this.originX;
            const left = this.originLeft - offset;
            this.$refs.horizontal.scrollToLeft(left, "auto");
        },
        onOpenTransformationModal() {
            this.showTransformationModal = true;
        },
        onCloseTransformationModal() {
            this.showTransformationModal = false;
        },
        onTransformationSave(transformation) {
            this.$set(this.template.config, "transformation", transformation);
            this.onCloseTransformationModal();
        },
        onImageChange({ file, name }) {
            this.$emit("image-change", { file, name });
        },
        scrollToCreative() {
            if (!this.$refs?.horizontal?.$el) {
                return;
            }

            const horizontalInstance = this.$refs.horizontal;
            const { clientWidth: width } = horizontalInstance.$el;
            const { scrollWidth } = horizontalInstance;

            if (width < scrollWidth) {
                horizontalInstance.scrollToLeft(this.size?.width ?? 0 / 2);
            }
        },
    },
};
</script>

<style lang="scss">
@import "~@ads/design-system/src/scss/variables";

.dragging-container {
    cursor: grab;
    padding: 20px 16px 12px;
    background: #777;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25) inset;
    width: fit-content;
    max-width: 100%;
    border-radius: 3px;
}

.preview-horizontal-container {
    .sr-dropzone-upload,
    .dropzone-container,
    .sr-dropzone-upload .vue-dropzone.dropzone {
        width: 100%;
        height: 100%;
        align-content: center;
    }

    .sr-dropzone-upload {
        .dropzone {
            min-height: unset;
            padding: unset;

            .dz-message {
                margin: unset;
            }
        }
    }

    .preview-horizontal-item {
        user-select: none;
        position: relative;
        margin-top: 28px;
        margin-bottom: 10px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
    }

    .horizontal-slide-title {
        font-size: 16px;
        color: #c4c4c4;
        justify-content: center;
        display: flex;
        width: 100%;
        position: absolute;
        top: -32px;
    }

    .hint {
        display: flex;
        justify-content: center;
        margin: 6px 0 0;
        user-select: none;

        .hint-text {
            background: #e4f3ff;
            max-width: 380px;
            font-size: 12px;
            color: #3046b6;
            padding: 6px 16px;
            text-align: center;
            border-radius: 3px;
        }
    }

    .required-indicator {
        color: $brand-red;
        margin-left: 4px;
    }
}
</style>
