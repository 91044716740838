import Vuex from "vuex";
import Vue from "vue";
import templates from "@/modules/templates/store";
import filters from "@/modules/filters/store";
import iam from "@/modules/iam/store";
import tournaments from "@/modules/tournaments/store";
import videos from "@/modules/videos/store";

Vue.use(Vuex);

const modules = {
    templates,
    filters,
    iam,
    tournaments,
    videos,
};

const store = new Vuex.Store({
    modules,
});

for (const moduleName of Object.keys(modules)) {
    if (modules[moduleName].actions.initialize) {
        store
            .dispatch(`${moduleName}/initialize`)
            .then(() => {
                console.info(`Module ${moduleName} initialized`);
            })
            .catch((error) => {
                console.error(`Failed to initialize module ${moduleName}`, error);
            });
    }
}

export default store;
