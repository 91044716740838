<template>
    <VForm ref="form" v-model="isFormValid" class="tournament-mapping-form" :disabled="isLoading">
        <div class="content">
            <VRow>
                <SrNotification v-if="isSubmitSuccess" type="success" title="Success" :timeout="5" @close="onNotificationClose" />
            </VRow>
            <VRow>
                <VCol class="mt-0" sm="12" md="10">
                    <VRow>
                        <VCol sm="12" md="6" lg="4" xl="3" class="pb-2">
                            <SrSelect
                                v-model="selectedBusinessEntity"
                                label="Advertiser"
                                :items="advertisers"
                                item-value="id"
                                item-text="name"
                                required
                                searchable
                                searchable-property="name"
                                return-object
                                :loading="iamLoadingStates(LOADING_STATES.BUSINESS_ENTITIES)"
                            />
                        </VCol>
                    </VRow>
                    <VRow class="mt-0">
                        <VCol sm="12" md="6" lg="4" xl="3">
                            <SrSelect
                                v-model="selectedSport"
                                label="Sport"
                                :items="sports"
                                item-value="id"
                                item-text="label"
                                searchable
                                searchable-property="label"
                                :disabled="!selectedBusinessEntity"
                                :messages="noSelectedAdvertiserWarning"
                                required
                            />
                        </VCol>
                        <VCol sm="12" md="6" lg="4" xl="3">
                            <LanguageSelect
                                v-model="selectedLanguage"
                                :affiliate-id="affiliateId"
                                :disabled="!selectedBusinessEntity"
                                :messages="noSelectedAdvertiserWarning"
                                auto-select-first
                                required
                            />
                        </VCol>
                    </VRow>
                </VCol>
            </VRow>
            <VRow v-show="displayMapping">
                <VCol>
                    <SrHeadline level="3" class="mb-4">
                        Map Tournament
                        <SrInfoBox>
                            Tournaments mapping helps to guarantee that every tournament’s name that we receive from the website
                            will be recognized and connected to Sportradar’s data. For Sportradar tournaments: Put in a keyword
                            and hit enter to search in the entire database otherwise default set of tournaments will be used. To
                            return to the default set after a search, hit enter with an empty searchbox.
                        </SrInfoBox>
                    </SrHeadline>
                    <VRow v-show="mappingsLoading">
                        <VCol sm="12" lg="8" xl="6" class="mt-2">
                            <VProgressLinear indeterminate />
                        </VCol>
                    </VRow>
                    <div v-show="tournamentMappings.length" class="mb-6">
                        <VRow v-for="tournament in tournamentMappings" :key="tournament.key">
                            <VCol sm="12" lg="8" xl="6" class="mt-2">
                                <TournamentMappingRow
                                    :tournament="tournament"
                                    :selected-sport="sportSrId"
                                    :default-sr-tournaments="defaultSrTournaments"
                                    :default-customer-tournaments="defaultCustomerTournaments"
                                    :fetch-customer-tournaments="fetchCustomerTournaments"
                                    @error="setErrorMessage"
                                >
                                    <template #action>
                                        <SrButton icon class="icon mt-2" @click="removeMapping(tournament)">
                                            <SrIcon icon="Trash" size="xs" />
                                        </SrButton>
                                    </template>
                                </TournamentMappingRow>
                            </VCol>
                        </VRow>
                    </div>
                    <div class="buttons-wrapper">
                        <SrButton
                            v-show="!mappingsLoading"
                            color="transparent"
                            elevation="0"
                            type="secondary"
                            class="light-blue mr-8"
                            @click="addMapping"
                        >
                            Add Mapping
                        </SrButton>
                        <SrButton
                            v-show="!mappingsLoading"
                            color="transparent"
                            elevation="0"
                            type="secondary"
                            class="light-blue"
                            @click="addMappingWithManualCustomerTournament"
                        >
                            Add Manual Mapping
                        </SrButton>
                    </div>
                </VCol>
            </VRow>
            <VRow v-show="displayMapping && suggestedMappings.length">
                <VCol>
                    <SrHeadline level="3" class="mt-5 mb-4" weight="bold"> Automated Suggestions </SrHeadline>
                    <div class="mb-5">
                        <TournamentsMappingAutomatedSuggestions
                            :customer-tournaments="defaultCustomerTournaments"
                            :sr-tournaments="defaultSrTournaments"
                            :suggested-mappings="suggestedMappings"
                            @confirm="confirmSuggestion"
                        />
                    </div>
                </VCol>
            </VRow>
            <VRow v-if="error">
                <VCol>
                    <ErrorBox class="mb-2" show :message="error" />
                </VCol>
            </VRow>
        </div>
        <div class="footer mt-2">
            <div class="d-flex justify-end">
                <SrButton
                    type="secondary"
                    elevation="0"
                    :v-if="userPermittedToCreateFilters || userPermittedToEditFilters"
                    :disabled="!isValid || loading"
                    :loading="loading"
                    @click="submit"
                    >Save
                </SrButton>
            </div>
        </div>
    </VForm>
</template>

<script>
import { createActionValidator, SrHeadline, SrSelect, SrButton, SrIcon, SrInfoBox, SrNotification } from "@ads/design-system";
import { mapGetters, mapActions } from "vuex";
import ErrorBox from "@/components/ErrorBox";
import LanguageSelect from "@/components/LanguageSelect";
import { sdsTournamentsService } from "@/modules/tournaments/services/SDSTournamentsService";
import { customerTournamentsService } from "@/modules/tournaments/services/CustomerTournamentsService";
import debounce from "debounce";
import { LOADING_STATES } from "@/modules/iam/store/state";
import sports from "@/lib/whiteListedSports.json";
import { ACTION_NAME } from "@ads/iam-library";
import TournamentsMappingAutomatedSuggestions from "./TournamentsMappingAutomatedSuggestions.vue";
import TournamentMappingRow from "./TournamentMappingRow.vue";

const advertiserNotSelectedMessage = "Choose advertiser first";

export default {
    name: "TournamentsMappingForm",
    components: {
        TournamentsMappingAutomatedSuggestions,
        LanguageSelect,
        TournamentMappingRow,
        SrHeadline,
        SrSelect,
        SrButton,
        SrIcon,
        SrInfoBox,
        ErrorBox,
        SrNotification,
    },
    inject: {
        isEditing: {
            default: false,
        },
    },
    provide() {
        return {
            affiliateId: this.affiliateId,
            defaultCustomerTournaments: this.defaultCustomerTournaments,
        };
    },
    model: {
        prop: "tournamentMappings",
        event: "input",
    },
    props: {
        tournamentMappings: {
            type: Array,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isFormValid: this.isValid,
            sports: [{ id: "soccer", label: "Soccer", srId: "sr:sport:1" }],
            selectedSport: null,
            selectedLanguage: null,
            selectedBusinessEntity: null,
            customerTournaments: [],
            srTournaments: [],
            suggestedMappings: [],
            defaultSrTournaments: [],
            defaultCustomerTournaments: [],
            uiMappings: [],
            error: null,
            loading: false,
            mappingsLoading: false,
            isSubmitSuccess: false,
            userPermittedToCreateFilters: true,
            userPermittedToEditFilters: true,
        };
    },
    computed: {
        LOADING_STATES() {
            return LOADING_STATES;
        },
        ...mapGetters({
            advertisers: "iam/businessEntities",
            iamLoadingStates: "iam/loadingStates",
        }),
        noSelectedAdvertiserWarning() {
            if (!this.selectedBusinessEntity) {
                return [advertiserNotSelectedMessage];
            }

            return null;
        },
        affiliateId() {
            return this.selectedBusinessEntity?.dspId.toString();
        },
        displayMapping() {
            return this.selectedBusinessEntity && this.selectedSport && this.selectedLanguage;
        },
        sportSrId() {
            const sport = sports.find((sportCandidate) => sportCandidate.id === this.selectedSport);

            return sport?.srId;
        },
    },
    watch: {
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
        selectedBusinessEntity(value) {
            if (value) {
                this.setDefaultSettings();
                this.getDefaultTournaments();
                this.fetchMappings();
            }
        },
        selectedSport() {
            this.getDefaultTournaments();
            this.fetchMappings();
        },
        selectedLanguage() {
            this.getDefaultTournaments();
            this.fetchMappings();
        },
    },
    created() {
        this.canCreateOrEdit();
        this.getDefaultTournaments = debounce(() => this._getDefaultTournaments(), 50);
        this.fetchMappings = debounce(() => this._fetchMappings(), 50);
        this.sports = sports;
    },
    methods: {
        ...mapActions({
            saveTournamentMappings: "tournaments/save",
            fetchTournamentMappings: "tournaments/fetch",
        }),
        _getDefaultTournaments() {
            if (!this.selectedSport || !this.selectedLanguage) {
                return;
            }

            const tournamentsIds = this.uiMappings
                .filter((mapping) => mapping.srTournament.id)
                .map((mapping) => mapping.srTournament.id);

            sdsTournamentsService
                .searchSDSTournaments("", this.sportSrId, tournamentsIds)
                .then((result) => {
                    this.defaultSrTournaments = result;
                })
                .catch((error) => {
                    this.setErrorMessage(error);
                    this.defaultSrTournaments = [];
                });

            this.fetchCustomerTournaments()
                .then((tournaments) => {
                    this.defaultCustomerTournaments = tournaments.map((tournament) => tournament.tournamentName);
                })
                .catch((error) => {
                    this.setErrorMessage(error);
                    this.defaultCustomerTournaments = [];
                });
        },
        async canCreateOrEdit() {
            const actionValidator = await createActionValidator(this.$root.user);

            this.userPermittedToCreateFilters = await actionValidator.validateAction({
                actionName: ACTION_NAME.CREATE,
                domainName: "dynamic-display",
                resourceType: "filter",
                userId: this.$root.user.getCognitoId(),
            });

            this.userPermittedToEditFilters = await actionValidator.validateAction({
                actionName: ACTION_NAME.EDIT,
                domainName: "dynamic-display",
                resourceType: "filter",
                userId: this.$root.user.getCognitoId(),
            });
        },
        fetchCustomerTournaments(search) {
            return customerTournamentsService.getByAffiliateId(this.affiliateId, search, {
                sport: this.selectedSport,
                language: this.selectedLanguage,
            });
        },
        _fetchMappings() {
            if (!this.selectedSport || !this.selectedLanguage) {
                return;
            }

            this.mappingsLoading = true;

            this.fetchTournamentMappings({
                sport: this.selectedSport,
                language: this.selectedLanguage,
                advertiserId: this.affiliateId,
            })
                .then((mappings) => {
                    const uiMappings = mappings.map((mappingObject) => this.mapMappingObjectToUiMapping(mappingObject));
                    this.$emit("input", uiMappings);
                    this.fetchTournaments(uiMappings);
                })
                .catch((error) => {
                    this.setErrorMessage(error);
                    this.$emit("input", []);
                })
                .then(() => {
                    this.mappingsLoading = false;
                });
        },
        fetchTournaments(mappings) {
            const tournamentsIds = mappings.map((mapping) => mapping.srTournament.id);

            sdsTournamentsService
                .searchSDSTournaments("", this.sportSrId, tournamentsIds)
                .then((result) => {
                    this.defaultSrTournaments = result;
                })
                .catch((error) => {
                    this.setErrorMessage(error);
                    this.defaultSrTournaments = [];
                });
        },
        setDefaultSettings() {
            const soccer = this.sports.find((sport) => sport.id === "soccer");

            let newSportValue = this.sports[0]?.id;
            if (soccer) {
                newSportValue = soccer.id;
            }

            if (newSportValue !== this.selectedSport) {
                this.selectedSport = newSportValue;
            }
        },
        addMapping() {
            this.$emit("input", [
                ...this.tournamentMappings,
                {
                    key: Date.now(),
                    customerTournament: null,
                    srTournament: null,
                    isCustomerTournamenInputManual: false,
                },
            ]);
            this.error = null;
        },
        addMappingWithManualCustomerTournament() {
            this.$emit("input", [
                ...this.tournamentMappings,
                {
                    key: Date.now(),
                    customerTournament: "",
                    srTournament: null,
                    isCustomerTournamenInputManual: true,
                },
            ]);
            this.error = null;
        },
        removeMapping(mapping) {
            this.$emit(
                "input",
                this.tournamentMappings.filter((t) => t !== mapping),
            );
            this.error = null;
        },
        confirmSuggestion(mapping) {
            this.suggestedMappings = this.suggestedMappings.filter((t) => t !== mapping);
            this.$emit("input", [...this.tournamentMappings, mapping]);
        },
        setErrorMessage(error) {
            this.error = error?.response?.message || error.message;
        },
        mapMappingObjectToUiMapping(mapping) {
            return {
                mappingId: mapping.mappingId,
                affiliateId: mapping.affiliateId,
                customerTournament: mapping.tournamentName,
                srTournament: {
                    id: mapping.srTournamentId,
                    name: mapping.srTournamentName,
                },
                sport: mapping.sport,
                language: mapping.language,
            };
        },
        mapUiMappingToMappingObject(mapping) {
            return {
                mappingId: mapping.mappingId,
                affiliateId: this.affiliateId,
                tournamentName: mapping.customerTournament,
                srTournamentId: mapping.srTournament.id,
                srTournamentName: mapping.srTournament.name,
                sport: this.selectedSport,
                language: this.selectedLanguage,
            };
        },
        async submit() {
            try {
                this.loading = true;
                if (!this.areAllMappingsAreDifferent()) {
                    this.loading = false;
                    return;
                }

                const convertedMappings = this.tournamentMappings.map((mapping) => this.mapUiMappingToMappingObject(mapping));

                const saveMappingsRequest = {
                    tournamentMappings: convertedMappings,
                    sport: this.selectedSport,
                    language: this.selectedLanguage,
                    advertiserId: this.affiliateId,
                };

                const tournamentMappings = await this.saveTournamentMappings(saveMappingsRequest);
                const uiTournamentMappings = tournamentMappings.map((mappingObject) =>
                    this.mapMappingObjectToUiMapping(mappingObject),
                );
                this.$emit("input", uiTournamentMappings);

                this.isSubmitSuccess = true;
                this.loading = false;
            } catch (error) {
                this.error = error.message || error.response.message;
            }
        },
        areAllMappingsAreDifferent() {
            const keyOccurrence = new Set();

            this.tournamentMappings.forEach((mapping) => {
                const key = mapping.customerTournament;
                if (keyOccurrence.has(key)) {
                    this.error = "There is a duplicate mapping!";
                } else {
                    keyOccurrence.add(key);
                }
            });

            return !this.error;
        },
    },
};
</script>
<style lang="scss">
.tournament-mapping-form {
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
        flex: 1 0 0;
        padding: 0 50px 50px;
        max-height: 100%;
        overflow: auto;
    }

    .footer {
        padding: 26px 50px 24px;
    }
}

.buttons-wrapper {
    display: flex;
}
</style>
