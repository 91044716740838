import { API_URL } from "@/config";
import { ACTION_NAME, IRequestOptions, RequestService } from "@ads/iam-library";
import BusinessEntity from "@/types/businessEntity";

export default class IamService {
    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: "iam",
        resource: "business-entities",
    };

    constructor(private readonly requestService: RequestService) {}

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return {
            ...this.defaultRequestOptions,
            ...options,
        } as IRequestOptions;
    }

    async getBusinessEntities(): Promise<BusinessEntity[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get("/business-entities", options);
    }
}

export const iamService = new IamService(new RequestService({ baseUrl: API_URL }));
