<template>
    <VForm ref="form" v-model="isFormValid">
        <VRow no-gutters>
            <VCol sm="12" md="12" lg="9" xl="7">
                <SrSelect
                    v-model="template.size"
                    class="mt-6"
                    label="Template Size"
                    :items="sizes"
                    item-value="id"
                    item-text="label"
                    required
                    dense
                    :disabled="isEditing"
                    @change="onSizeChange"
                />
            </VCol>
        </VRow>
        <VRow no-gutters>
            <VCol sm="12" md="12" lg="11" xl="10">
                <SrHeadline level="3" class="mt-10">HTML/CSS Code</SrHeadline>
                <CodeMirror v-model="template.config.customHtml" :is-active="isStepActive" />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import loadImageAsBase64 from "@/utils/loadImageAsBase64";
import { formatSizes } from "@/modules/templates/strings";
import { SrHeadline, SrSelect } from "@ads/design-system";
import CodeMirror from "@/components/CodeMirror/CodeMirror.vue";

export default {
    name: "CustomHTMLCSSConfiguration",
    components: {
        CodeMirror,
        SrHeadline,
        SrSelect,
    },
    inject: {
        isDuplication: {
            default: false,
        },
        isEditing: {
            default: false,
        },
    },
    props: {
        template: {
            type: Object,
            default: () => ({}),
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            images: {
                fallbackImage: null,
            },
            isFormValid: this.isValid,
            acceptImageFormats: "image/webp,image/png,image/svg+xml,image/gif,image/jpeg",
        };
    },
    computed: {
        sizes() {
            if (!this.template.layout) {
                return [];
            }

            return formatSizes(this.template.layout.sizes) || [];
        },
        isStepActive() {
            return this.isActive && this.template.layout?.id === 5;
        },
    },
    watch: {
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
    },
    created() {
        this.setMockImageFiles();
    },
    methods: {
        setMockImageFiles() {
            if (this.isDuplication || this.isEditing) {
                const config = this.template.config;

                Object.keys(this.images).forEach((key) => {
                    if (config[key]?.value) {
                        this.$set(this.images, key, this.createMockImage(`${key}.jpg`));
                    }
                });
            }
        },
        createMockImage(name) {
            return new File([], name, { type: "mock" });
        },
        onImageChange(file, name) {
            this.imageToBase64(file, name);
        },
        imageToBase64(image, name) {
            if (!image) {
                return this.emitImageChange(null, name);
            }

            if (image.type === "mock") {
                return this.emitImageChange(undefined, name);
            }

            return loadImageAsBase64(image).then((value) => this.emitImageChange(value, name));
        },
        onSizeChange() {
            if (this.isDuplication) {
                this.$emit("size-change");
            }
        },
        emitImageChange(value, name) {
            let image = { value };

            if (value === undefined) {
                image = null;
            }

            return this.$emit("image-change", { image, name });
        },
    },
};
</script>
