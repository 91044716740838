import BusinessEntity from "@/types/businessEntity";

export enum LOADING_STATES {
    BUSINESS_ENTITIES = "businessEntities",
}

export interface IamState {
    businessEntities: BusinessEntity[];
    loadingStates: {
        [key in LOADING_STATES]: boolean;
    };
}

const state: IamState = {
    businessEntities: [],
    loadingStates: {
        [LOADING_STATES.BUSINESS_ENTITIES]: false,
    },
};

export default state;
