<template>
    <section>
        <TemplateForm
            v-model="template"
            submit-action="templates/create"
            after-close-route="templates-root"
            after-submit-route="templates-root"
            :after-submit-route-params="{
                createSuccess: {
                    name: templateName,
                    hasPixels: template?.businessEntity?.pixels.length > 0,
                },
            }"
            :loading="isLoading"
        />
        <RouterView :template="template" />
    </section>
</template>

<script>
import TemplateForm from "@/modules/templates/components/TemplateForm";
import { CREATIVE_TYPE } from "@/modules/templates/types";
import { getDefaultConfig } from "@/modules/templates/components/TemplateForm/config";

export default {
    name: "TemplatesDuplicate",
    components: { TemplateForm },
    provide: {
        isDuplication: true,
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            template: {
                config: {
                    ...getDefaultConfig(),
                },
                type: CREATIVE_TYPE.SPORT,
            },
            isLoading: false,
        };
    },
    computed: {
        templateName() {
            return this.template?.name;
        },
    },
    mounted() {
        this.fetchTemplate();
    },
    methods: {
        copyTemplate(template) {
            const { config } = template;
            const copiedConfig = {
                ...config,
                font: config.font.id,
                fallbackImage: config.fallbackImage,
                logoImage: config.logoImage,
                preRollImage: config.preRollImage,
                postRollImage: config.postRollImage,
            };

            if (template.type === CREATIVE_TYPE.SPORT) {
                copiedConfig.backgroundImage = config.backgroundImage;
            } else if (template.type === CREATIVE_TYPE.CASINO) {
                copiedConfig.brandedImage = config.brandedImage;
                copiedConfig.transformation =
                    typeof config.transformation === "string" ? JSON.parse(config.transformation) : null;
            }

            delete copiedConfig.id;

            const copiedTemplate = {
                ...template,
                name: `${template.name}_copy`,
                size: template.size.id,
                layout: template.layout,
                config: copiedConfig,
            };

            delete copiedTemplate.id;
            delete copiedTemplate.build;

            return copiedTemplate;
        },
        async fetchTemplate() {
            this.isLoading = true;
            const id = Number(this.$route.params.id);
            const typePath = String(this.$route.params.typePath);

            try {
                const template = await this.$store.dispatch("templates/fetchTemplate", {
                    id,
                    typePath,
                });

                this.template = this.copyTemplate(template);
            } catch (error) {
                this.error = error.message || error.response.message;
            }

            this.isLoading = false;
        },
    },
};
</script>
