<template>
    <VForm ref="form" v-model="isFormValid" :disabled="isLoading">
        <VRow>
            <VCol class="ml-1">
                <SrHeadline level="1" class="mb-6" weight="bold">New Filter</SrHeadline>
                <SrHeadline level="3" class="mb-6" weight="bold">Details</SrHeadline>
                <VRow>
                    <VCol sm="12" lg="4">
                        <DatePicker
                            v-model="filter.filterDate.startDate"
                            label="Start Date"
                            required
                            :min="minStartDate"
                            :max="filter.filterDate.endDate"
                        />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol sm="12" lg="4">
                        <DatePicker
                            v-model="filter.filterDate.endDate"
                            label="End Date"
                            required
                            :min="filter.filterDate.startDate"
                            :show-current="showCurrentEndDate"
                        />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol sm="12" md="6" lg="4">
                        <SrSelect
                            :items="weekdays"
                            label="Days of the week"
                            item-value="id"
                            item-text="label"
                            multiple
                            :value="filter.filterDate.dayOfWeek"
                            @input="onDayOfWeekChange"
                        />
                    </VCol>
                    <VCol sm="12" md="6" lg="3" xl="2" class="ahead-inputs">
                        <SrSelect
                            class="ahead-inputs__interval"
                            :items="aheadItems"
                            label=""
                            item-value="type"
                            item-text="label"
                            :value="aheadInterval"
                            @change="onAheadIntervalChange"
                        />
                        <SrInput
                            class="ahead-inputs__number"
                            label="ahead to start"
                            type="number"
                            :rules="aheadCountValidationRule"
                            min="0"
                            :max="aheadCountMax"
                            :value="aheadCount"
                            @change="onAheadCountChange"
                        />
                    </VCol>
                </VRow>
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrSelect, SrInput } from "@ads/design-system";
import DatePicker from "@/components/DatePicker";
import { AHEAD_INTERVAL, aheadItems, weekdays } from "@/modules/filters/components/FilterForm/config";
import { format } from "date-fns";
import { getMinMaxValidation } from "@/validation/rules";

export default {
    name: "FilterFormStepRuntime",
    components: {
        DatePicker,
        SrSelect,
        SrHeadline,
        SrInput,
    },
    inject: {
        isEditing: {
            default: false,
        },
    },
    model: {
        prop: "filter",
        event: "input",
    },
    props: {
        filter: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isFormValid: this.isValid,
            weekdays,
            aheadItems,
            minStartDate: format(new Date(), "yyyy-MM-dd"),
        };
    },
    computed: {
        aheadCountValidationRule() {
            if (this.aheadInterval === AHEAD_INTERVAL.DAY) {
                return getMinMaxValidation(0, 5);
            }
            if (this.aheadInterval === AHEAD_INTERVAL.WEEK) {
                return getMinMaxValidation(0, 3);
            }
            return [];
        },
        aheadCountMax() {
            return this.aheadInterval === AHEAD_INTERVAL.DAY ? 5 : 3;
        },
        showCurrentEndDate() {
            return this.filter.filterDate.startDate || true;
        },
        aheadInterval() {
            const { filterDate } = this.filter;

            if (Number.isInteger(filterDate.calendarWeeksAhead)) {
                return AHEAD_INTERVAL.WEEK;
            }

            return AHEAD_INTERVAL.DAY;
        },
        aheadCount() {
            const { filterDate } = this.filter;

            return filterDate.calendarWeeksAhead || filterDate.daysAhead || 0;
        },
    },
    watch: {
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
    },
    methods: {
        onDayOfWeekChange(selected) {
            this.$set(this.filter.filterDate, "dayOfWeek", selected.sort());
        },
        setAheadCountValue(type, value) {
            if (type === AHEAD_INTERVAL.DAY) {
                this.$set(this.filter.filterDate, "calendarWeeksAhead", null);
                this.$set(this.filter.filterDate, "daysAhead", value);
            } else if (type === AHEAD_INTERVAL.WEEK) {
                this.$set(this.filter.filterDate, "calendarWeeksAhead", this.aheadCount);
                this.$set(this.filter.filterDate, "daysAhead", value);
            }
        },
        onAheadIntervalChange(aheadInterval) {
            this.setAheadCountValue(aheadInterval, this.aheadCount);

            this.$nextTick(() => this.$refs.form.validate());
        },
        onAheadCountChange(count) {
            this.setAheadCountValue(this.aheadInterval, Number.parseInt(count, 10));

            this.$nextTick(() => this.$refs.form.validate());
        },
    },
};
</script>

<style scoped lang="scss">
.ahead-inputs {
    display: flex;

    &__interval {
        flex-basis: 35%;
        margin-right: 8px;
    }

    &__number {
        flex-basis: 65%;
    }
}
</style>
