import { ACTION_NAME, IRequestOptions, RequestService } from "@ads/iam-library";
import { API_URL } from "@/config";
import { VideoLayout } from "../types";

export default class VideoLayoutsService {
    private readonly defaultRequestOptions: Pick<IRequestOptions, "domain" | "resource"> = {
        domain: "dynamic-display",
        resource: "video-layout",
    };

    private readonly requestService: RequestService;

    private readonly domainPath: string;

    constructor(requestService: RequestService, domainPath?: string) {
        this.requestService = requestService;
        this.domainPath = domainPath;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return {
            ...this.defaultRequestOptions,
            ...options,
        } as IRequestOptions;
    }

    fetchVideoLayouts(): Promise<VideoLayout[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });
        return this.requestService.get(`/${this.domainPath}`, requestOptions);
    }
}

export const videoLayoutsService = new VideoLayoutsService(new RequestService({ baseUrl: API_URL }), "video-layouts");
