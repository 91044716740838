<template>
    <div class="default-image" :style="style"></div>
</template>

<script>
export default {
    name: "DefaultLibraryImage",
    props: {
        imageValue: {
            type: String,
            required: true,
        },
        size: {
            type: Object,
            required: true,
        },
    },
    computed: {
        style() {
            return {
                height: `${this.size.height}px`,
                width: `${this.size.width}px`,
                backgroundImage: `url('${this.imageValue}')`,
                backgroundColor: "#F9F8FF",
                border: "none",
                content: null,
            };
        },
    },
};
</script>
