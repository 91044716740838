import { CREATIVE_TYPE, EVENT_VERSION_KEYS, MatchType } from "@/modules/templates/types";
import filterNonProductionItem from "@/utils/filterNonProductionItem";
import {
    LAYOUT_POSITION,
    ODD_FORMAT,
    ODD_TYPE,
    TEAM_IMAGE_TYPE,
} from "@/modules/templates/components/TemplateForm/TemplateFormStepCustomization/types";
import dateFormats from "@/modules/templates/components/TemplateForm/dateFormats.json";

export const formSteps = [
    {
        name: "Basics",
        isValid: true,
        component: "TemplateFormStepBasics",
    },
    {
        name: "Layout Library",
        isValid: true,
        component: "TemplateFormStepLayoutLibrary",
    },
    {
        name: "Customization",
        isValid: true,
        component: "TemplateFormStepCustomization",
    },
].filter((item) => filterNonProductionItem(item));

export const defaultStyles = {
    backgroundColor: "#3C629CFF",
    secondaryColor: "#FFFFFFFF",
    ctaTextColor: "#FFFFFFFF",
    font: null,
};

export const defaultLabels = {
    legalText: "18+ only",
    offerLine1: "First registration bonus",
    offerLine2: "Free bets for new users",
    ctaText: "Place Bet",
};

const getEmptyImage = (): object => ({
    value: null,
});

const commonConfigSettings = (): object => ({
    isDefaultTargetUrl: false,
    utmSource: "",
    utmMedium: "",
    utmCampaign: "",
    utmTerm: "",
    fallbackImage: getEmptyImage(),
    logoImage: getEmptyImage(),
    preRollImage: getEmptyImage(),
    postRollImage: getEmptyImage(),
});

export const getDefaultCasinoSettings = (): object => ({
    ...commonConfigSettings(),
    category: null,
    transformation: null,
    brandedImage: getEmptyImage(),
    quaternaryColor: "#FFFFFFFF",
    tertiaryColor: "#FFFFFFFF",
    primaryColor: "#6D4CC9FF",
    isJackpotEnabled: false,
    currency: null,
    jackpotOfferLine: "{jackpot} The grand jackpot",
    eventVersion: EVENT_VERSION_KEYS.V1,
    amountOfEvents: 1,
});

export const getDefaultSportSettings = (): object => ({
    ...commonConfigSettings(),
    sportId: "soccer",
    hasGeoTargeting: true,
    matchType: MatchType.ANY,
    backgroundImage: getEmptyImage(),
    tertiaryColor: "#3D3D3DFF",
    oddsTextColor: "#FFFFFFFF",
    primaryColor: "#F08D34FF",
    amountOfEvents: 1,
    logoPosition: LAYOUT_POSITION.TOP,
    offerLinesPosition: LAYOUT_POSITION.BOTTOM,
    teamsFallback: TEAM_IMAGE_TYPE.LOGO,
    canUseTeamColors: false,
    customHtml: "",
    oddsType: ODD_TYPE.THREE_WAY_ODDS,
    eventVersion: EVENT_VERSION_KEYS.V2,
    dateFormat: dateFormats[0].value,
    oddsFormat: ODD_FORMAT.DECIMAL,
});

export const commonKeys = [...Object.keys(defaultLabels), ...Object.keys(defaultStyles), "fallbackUrl"];

export const getDefaultConfig = (type?: CREATIVE_TYPE): object => {
    const config = { ...defaultStyles, ...defaultLabels };

    switch (type) {
        case CREATIVE_TYPE.CASINO:
            return {
                ...config,
                ...getDefaultCasinoSettings(),
            };
        case CREATIVE_TYPE.SPORT:
            return {
                ...config,
                ...getDefaultSportSettings(),
            };
        default:
            return {
                ...config,
                ...commonConfigSettings(),
            };
    }
};

export const sizesWithoutTeamsFeatures = [[2, 3, 4, 5, 6], [], [], [], [1, 2, 3, 4, 5, 6]];
export const sizesWithoutSpreadsFeatures = [[1, 2, 3, 4, 5, 6], [], [1, 2, 3, 4, 5, 6, 100, 67, 34], [], [1, 2, 3, 4, 5, 6]];
