<template><div>Basic Form</div></template>

<script>
export default {
    name: "BasicForm",
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        value: {
            type: [Object, Array],
            default: () => ({}),
        },
        submitAction: {
            type: String,
            required: true,
        },
        afterSubmitRoute: {
            type: String,
            required: true,
        },
        afterSubmitRouteParams: {
            type: Object,
            default: () => ({}),
        },
        afterCloseRoute: {
            type: String,
            required: true,
        },
        afterCloseRouteParams: {
            type: Object,
            default: () => ({}),
        },
        nonLinear: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            error: null,
        };
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        async save() {},
        async submit(onError) {
            try {
                await this.save();
                await this.$router.push({
                    name: this.afterSubmitRoute,
                    params: this.afterSubmitRouteParams,
                });
            } catch (error) {
                this.error = error.response?.message || error.message;
                onError();
                return false;
            }

            return true;
        },
        async close() {
            await this.$router.push({
                name: this.afterCloseRoute,
                params: this.afterCloseRouteParams,
            });
        },
    },
};
</script>
