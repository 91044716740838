<template>
    <div class="side-navigation">
        <SrNavigationBar :items="navItems" />
    </div>
</template>

<script>
import { SrNavigationBar } from "@ads/design-system";

export default {
    name: "SideNavigation",
    components: { SrNavigationBar },
    data() {
        return {
            navItems: [
                {
                    title: "Templates",
                    icon: "Code2",
                    domainName: "dynamic-display",
                    routeName: "templates-root",
                },
                {
                    title: "Videos",
                    icon: "VideoIcon",
                    domainName: "dynamic-display",
                    routeName: "videos-root",
                    resourceType: "video-creative",
                },
                {
                    title: "Filters",
                    icon: "Funnel",
                    domainName: "dynamic-display",
                    routeName: "filters-root",
                },
                {
                    title: "Tournaments mapping",
                    icon: "File",
                    domainName: "dynamic-display",
                    routeName: "tournaments-root",
                },
            ],
        };
    },
    methods: {
        async asyncFilter(arr, asyncCallback) {
            const results = await Promise.all(
                arr.map(async (item) => {
                    try {
                        const keep = await asyncCallback(item);
                        return { item, keep };
                    } catch (err) {
                        console.log(err);
                    }

                    return null;
                }),
            );

            return results.filter((result) => result && result.keep).map((result) => result.item);
        },
    },
};
</script>
