import FiltersState, { LOADING_STATES } from "@/modules/filters/types/filtersState";

const state: FiltersState = {
    items: [],
    loadingStates: {
        [LOADING_STATES.ITEMS]: false,
        [LOADING_STATES.CREATE]: false,
    },
};

export default state;
