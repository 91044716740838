<template>
    <VForm ref="form" v-model="isFormValid" :disabled="isLoading">
        <VRow>
            <VCol class="ml-1">
                <SrHeadline level="1" class="mb-6" weight="bold">New Filter</SrHeadline>
                <SrHeadline level="3" class="mb-6" weight="bold">Details</SrHeadline>
                <VRow>
                    <VCol sm="12" lg="4">
                        <SrInput v-model="filter.name" :rules="rules.name" label="Filter Name" required />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol sm="12" lg="4" class="pt-0">
                        <SrSelect
                            v-model="filter.businessEntity"
                            label="Advertiser"
                            :items="advertisers"
                            item-value="id"
                            item-text="name"
                            disabled
                            required
                            searchable
                            searchable-property="name"
                            sort-order="ASC"
                            return-object
                        />
                    </VCol>
                </VRow>
            </VCol>
        </VRow>
        <VRow>
            <VCol class="ml-1">
                <SrHeadline level="3" class="mb-6" weight="bold">Select Sport</SrHeadline>
                <VRow>
                    <VCol sm="12" lg="4">
                        <SrSelect
                            v-model="filter.sportId"
                            label="Sport"
                            :items="sports"
                            item-value="id"
                            item-text="label"
                            :disabled="isEditing"
                            required
                        />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol sm="12" lg="4" class="pt-0">
                        <SrSelect
                            v-model="selectedTournaments"
                            label="Tournaments"
                            :items="tournaments"
                            item-value="id"
                            item-text="name"
                            multiple
                            searchable
                            required
                            searchable-property="name"
                            sort-order="ASC"
                            no-data-text="No Mapped Tournamentss"
                            :loading="loadingTournaments"
                            :error-messages="loadingTournamentsErrors"
                            @change="onTournamentChange"
                        />
                    </VCol>
                </VRow>
            </VCol>
        </VRow>
        <VRow>
            <VCol class="ml-1">
                <SrHeadline level="3" class="mb-6" weight="bold">Filter Status</SrHeadline>
                <VRow>
                    <VCol sm="12" lg="4" class="pt-0 active-switch">
                        <SrSwitch v-model="filter.active" color="red" class="mt-0" />
                        <span class="v-label ml-3 mt-2">
                            <span v-if="filter.active" class="active-switch-label">Active</span>
                            <span v-else class="inactive-switch-label">Inactive</span>
                        </span>
                    </VCol>
                </VRow>
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrInput, SrSelect, SrSwitch } from "@ads/design-system";
import { textValidationRules } from "@/validation/rules";
import { mapGetters } from "vuex";
import { tournamentMappingsService } from "@/modules/tournaments/services/TournamentMappingsService";
import sports from "@/lib/whiteListedSports.json";

export default {
    name: "FilterFormStepBasics",
    components: {
        SrInput,
        SrSelect,
        SrHeadline,
        SrSwitch,
    },
    inject: {
        isEditing: {
            default: false,
        },
    },
    model: {
        prop: "filter",
        event: "input",
    },
    props: {
        filter: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: {
                name: textValidationRules({
                    propertyName: "Filter Name",
                    options: { min: 3, max: 128 },
                }),
            },
            isFormValid: this.isValid,
            sports: [{ id: "soccer", label: "Soccer" }],
            selectedTournaments: [],
            tournaments: [],
            loadingTournaments: false,
            loadingTournamentsErrors: [],
        };
    },
    computed: {
        ...mapGetters({
            advertisers: "iam/businessEntities",
        }),
        affiliateId() {
            return this.filter.businessEntity?.dspId.toString();
        },
    },
    watch: {
        "filter.sportId": function () {
            this.fetchTournamentsIfSportAndAdvertiserIdPresent();
        },
        "filter.businessEntity": function () {
            this.fetchTournamentsIfSportAndAdvertiserIdPresent();
        },
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
    },
    created() {
        this.sports = sports;
        if (!this.filter.sportId) {
            this.filter.sportId = "soccer";
        }
        this.fetchTournamentsIfSportAndAdvertiserIdPresent();
    },
    methods: {
        fetchTournamentsIfSportAndAdvertiserIdPresent() {
            if (!this.filter.sportId || !this.affiliateId) {
                return;
            }

            this.loadingTournaments = true;

            tournamentMappingsService
                .getTournamentMappingsForSportAndAdvertiser(this.filter.sportId, this.affiliateId)
                .then((tournamentResults) => {
                    this.tournaments = tournamentResults.map((tournamentResult) => ({
                        id: tournamentResult.srTournamentId,
                        name: tournamentResult.srTournamentName,
                    }));

                    if (this.filter.filterId && this.tournaments.length > 0) {
                        this.selectedTournaments = this.tournaments
                            .filter((tournament) => this.filter.tournament.includes(tournament.id))
                            .map((tournament) => tournament.id);
                    }

                    this.loadingTournaments = false;
                })
                .catch(() => {
                    this.loadingTournamentsErrors = ["Failed to load tournaments"];
                    this.loadingTournaments = false;
                });
        },
        onTournamentChange(tournaments) {
            this.$set(this.filter, "tournament", tournaments);
        },
    },
};
</script>

<style lang="scss">
.active-switch {
    display: flex;
    align-items: flex-start;
}
</style>
