import { LOADING_STATES } from "@/modules/templates/store/state";
import { Template, TemplatesState, Font, Layout } from "@/modules/templates/types";

export default {
    setFonts(state: TemplatesState, items: Font[]): void {
        state.fonts = items;
    },
    setLayouts(state: TemplatesState, items: Layout[]): void {
        state.layouts = items;
    },
    setTemplates(state: TemplatesState, items: Template[]): void {
        state.items = items;
    },
    removeTemplate(state: TemplatesState, template: Template): void {
        state.items = state.items.filter((t) => t.id !== template.id);
    },
    removeTemplates(state: TemplatesState, ids: number[]): void {
        state.items = state.items.filter((template) => !ids.includes(template.id));
    },
    replaceTemplate(state: TemplatesState, template: Template): void {
        state.items = state.items.map((oldTemplate) => (template.id === oldTemplate.id ? template : oldTemplate));
    },
    replaceTemplates(state: TemplatesState, templates: Template[]): void {
        const itemsById = state.items.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});

        templates.forEach((item) => {
            itemsById[item.id] = item;
        });

        state.items = Object.values(itemsById);
    },
    setLoading(state: TemplatesState, { key, value }: { key: LOADING_STATES; value: boolean }): void {
        state.loadingStates = {
            ...state.loadingStates,
            [key]: value,
        };
    },
};
