<template>
    <section>
        <TemplateForm
            v-model="template"
            submit-action="templates/create"
            after-close-route="templates-root"
            after-submit-route="templates-root"
            :after-submit-route-params="{
                createSuccess: {
                    name: template.name,
                    hasPixels: template?.businessEntity?.pixels.length > 0,
                },
            }"
        />
        <RouterView :template="template" />
    </section>
</template>

<script>
import TemplateForm from "@/modules/templates/components/TemplateForm";
import { getDefaultConfig } from "@/modules/templates/components/TemplateForm/config";
import { mapGetters } from "vuex";
import { CREATIVE_TYPE } from "@/modules/templates/types";

export default {
    name: "TemplatesCreate",
    components: { TemplateForm },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            template: {
                name: "",
                size: null,
                layout: null,
                businessEntity: null,
                type: CREATIVE_TYPE.SPORT,
                config: getDefaultConfig(CREATIVE_TYPE.SPORT),
            },
        };
    },
    computed: {
        ...mapGetters({
            fonts: "templates/fonts",
        }),
    },
    watch: {
        fonts() {
            this.setDefaultFont();
        },
    },
    mounted() {
        this.setDefaultFont();
    },
    methods: {
        setDefaultProperty(object, name, value) {
            if (!Number.isInteger(object[name])) {
                this.$set(object, name, value);
            }
        },
        setDefaultFont() {
            if (this.fonts.length) {
                this.setDefaultProperty(this.template.config, "font", this.fonts[0]?.id);
            }
        },
    },
};
</script>
