<template>
    <VRow no-gutters>
        <ListHeader
            headline-name="Filter Creation"
            button-name="Create Filter"
            route="filters-create"
            :params="selectedAdvertiser"
            :disabled="!selectedAdvertiser"
        >
            <template #search>
                <SrSelect
                    v-model="selectedAdvertiser"
                    label="Advertiser"
                    :items="advertisers"
                    item-value="dspId"
                    item-text="name"
                    required
                    searchable
                    searchable-property="name"
                    return-object
                    :loading="advertisersLoading"
                />
            </template>
        </ListHeader>

        <VCol cols="12">
            <VDivider class="mb-6" />

            <ErrorBox class="mb-2" :show="error" :message="error" />

            <SrNotification v-if="warning" type="warning" :title="warning" @close="onNotificationClose" />

            <SrNotification
                v-if="showLastNotification"
                type="success"
                :title="showLastNotification"
                :timeout="10"
                @close="onNotificationClose"
            />
        </VCol>

        <DataTable
            :page.sync="page"
            :headers="headers"
            :items="displayedItems"
            :is-loading="isItemsLoading"
            :loading-text="loadingText"
            entity-name="Filter"
        >
            <template #name="item">
                <span class="font-weight-bold">{{ item.name }}</span>
            </template>
            <template #businessEntity="item"> {{ item.businessEntity.name }}</template>
            <template #startDate="item">
                <FormattedDate :date="item.filterDate.startDate" :hour="null" :minute="null" />
            </template>
            <template #endDate="item">
                <FormattedDate :date="item.filterDate.endDate" :hour="null" :minute="null" />
            </template>
            <template #created="item">
                <span class="active-switch">
                    <SrSwitch :input-value="item.active" color="red" @change="onActiveStateChange(item, $event)" />
                    <span class="v-label ml-3">
                        <span v-if="item.active" class="active-switch-label">Active</span>
                        <span v-else class="inactive-switch-label">Inactive</span>
                    </span>
                </span>
            </template>
            <template #actions="item">
                <TableActions :action-routes="actionRoutes" :item="item" />
            </template>
        </DataTable>
    </VRow>
</template>

<script>
import ListHeader from "@/components/ListHeader";
import ErrorBox from "@/components/ErrorBox";
import { SrSelect, SrSwitch, SrNotification } from "@ads/design-system";
import FormattedDate from "@/components/FormattedDate/FormattedDate";
import DataTable, { TableActions } from "@/components/DataTable";
import { formatBuildStatus, getBuildStatusColorClass } from "@/utils/buildStatusUi";
import { listHeaders, listActionRoutes } from "@/modules/filters/views/list/config";
import BUILD_STATUS from "@/types/buildStatus";
import { defaultLoadingText, longLoadingText, longLoadingTimeout, typeErrorWarningText } from "@/utils/uiConfigs";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { iamService } from "@/modules/iam/services/IamService";
import { LOADING_STATES as IAM_LOADING_STATES } from "@/modules/iam/store/state";

export default {
    name: "FiltersList",
    components: {
        FormattedDate,
        ListHeader,
        DataTable,
        SrSelect,
        ErrorBox,
        SrSwitch,
        SrNotification,
        TableActions,
    },
    data() {
        return {
            headers: listHeaders,
            quickSearchQuery: null,
            page: 1,
            error: null,
            warning: null,
            showLastNotification: null,
            buildStatuses: BUILD_STATUS,
            loadingText: defaultLoadingText,
            stateChangeLoading: [],
            actionRoutes: listActionRoutes,
            selectedAdvertiser: null,
            advertisers: [],
            isLoading: false,
            isItemsLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            items: "filters/getFilters",
            iamLoadingStates: "iam/loadingStates",
        }),
        displayedItems() {
            return this.items;
        },
        advertisersLoading() {
            return this.iamLoadingStates(IAM_LOADING_STATES.BUSINESS_ENTITIES);
        },
    },
    watch: {
        selectedAdvertiser(value) {
            if (value) {
                this.fetchFilters(value.dspId);
            }
        },
    },
    created() {
        this.fetchEntities();
        this.checkCreatedFilter();
    },
    methods: {
        ...mapActions({
            changeActiveStatus: "filters/activate",
            fetchFilters: "filters/fetchFilters",
        }),
        ...mapMutations({
            replaceFilter: "filters/replaceFilter",
        }),
        checkCreatedFilter() {
            const { createSuccess, updateSuccess, close } = this.$route.params;

            if (createSuccess) {
                this.showLastNotification = `Filter "${createSuccess.name}" successfully created.`;
                this.selectedAdvertiser = createSuccess.advertiser;
            } else if (updateSuccess) {
                this.showLastNotification = `Filter "${updateSuccess.name}" successfully updated.`;
                this.selectedAdvertiser = updateSuccess.advertiser;
            } else if (close) {
                this.selectedAdvertiser = close.advertiser;
            }
        },
        resetPage() {
            this.page = 1;
        },
        onNotificationClose() {
            this.showLastNotification = null;
        },
        async fetchItems() {
            const timeoutId = setTimeout(() => {
                if (this.isItemsLoading) {
                    this.loadingText = longLoadingText;
                }
            }, longLoadingTimeout);

            try {
                this.isItemsLoading = true;
                await this.fetchFilters(this.selectedAdvertiser.dspId);
                this.isItemsLoading = false;
            } catch (error) {
                this.processResponseError(error);
            } finally {
                clearTimeout(timeoutId);
                this.resetPage();
            }
        },
        async fetchEntities() {
            this.isLoading = true;
            await this.fetchAdvertisers();
            this.isLoading = false;
        },
        async fetchAdvertisers() {
            this.advertisers = await iamService.getBusinessEntities();
        },
        formatStatus(status) {
            return formatBuildStatus(status);
        },
        getStatusColorClass(status) {
            return getBuildStatusColorClass(status);
        },
        processResponseError(error) {
            if (error instanceof TypeError) {
                this.warning = typeErrorWarningText;
            } else {
                this.error = error.response?.message || error.message;
            }
        },
        async onActiveStateChange(filter) {
            this.stateChangeLoading.push(filter.filterId);

            try {
                const updatedFilter = await this.changeActiveStatus(filter);
                this.replaceFilter(updatedFilter);
            } catch (error) {
                this.processResponseError(error);
            } finally {
                this.stateChangeLoading = this.stateChangeLoading.filter((loadingId) => loadingId !== filter.filterId);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.active-switch {
    display: flex;
    align-items: center;
}

.active-switch-label {
    color: var(--v-green-base);
}

.inactive-switch-label {
    color: var(--v-gray-border-base);
}
</style>
