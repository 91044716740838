import { ACTION_NAME, IRequestOptions, RequestService } from "@ads/iam-library";
import { API_URL } from "@/config";
import SDSTournament from "@/modules/tournaments/types/SDSTournament";

export default class SDSTournamentsService {
    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: "dynamic-display",
        resource: "tournaments",
    };

    constructor(private readonly requestService: RequestService) {}

    searchSDSTournaments(searchKey: string, sportId: string, idList?: string[]): Promise<SDSTournament[]> {
        if (searchKey.length > 0 && searchKey.length < 3) {
            throw new Error("Search key length must be at least 3");
        }

        const idListParam = idList && idList.length > 0 ? `&idList=${JSON.stringify(idList)}` : "";
        const searchKeyParam = `&searchKey=${searchKey}`;
        const sportIdParam = `sportId=${sportId}`;

        const options: IRequestOptions = {
            domain: "dynamic-display",
            resource: "tournaments",
            action: ACTION_NAME.READ,
        } as IRequestOptions;
        return this.requestService.get(`/tournaments/searchForKeyword?${sportIdParam}${searchKeyParam}${idListParam}`, options);
    }

    getDefaultSDSTournaments(): Promise<SDSTournament[]> {
        return Promise.apply([]);
    }
}

export const sdsTournamentsService = new SDSTournamentsService(new RequestService({ baseUrl: API_URL }));
