<template>
    <div class="creative-image-upload">
        <FileUpload v-model="file" :accept="acceptImageFormats" :display-file="false" v-bind="$attrs" @change="onChange">
            <template #default="{ triggerUpload, removeFile, hasFile }">
                <div v-if="hasFile" class="file-upload-fullscreen-preview" :style="previewStyle">
                    <SrButton icon elevation="1" x-small color="red" class="icon" @click="removeFile">
                        <VIcon small dense color="red">mdi-delete</VIcon>
                    </SrButton>
                </div>
                <div v-else class="file-upload-fullscreen-trigger" :style="triggerStyle" @click="triggerUpload">
                    <SrIcon icon="upload" size="l" />
                    <div class="file-upload-fullscreen-trigger-hint">Drag and Drop file here or click to upload</div>
                </div>
            </template>
        </FileUpload>
    </div>
</template>
<script>
import FileUpload from "@/components/FileUpload/FileUpload.vue";
import { SrButton, SrIcon } from "@ads/design-system";

export default {
    name: "CreativeImageUpload",
    components: { FileUpload, SrButton, SrIcon },
    props: {
        size: {
            type: Object,
            default: () => ({}),
        },
        image: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        acceptImageFormats: {
            type: String,
            default: "image/webp,image/png,image/svg+xml,image/gif,image/jpeg",
        },
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            file: null,
        };
    },
    computed: {
        triggerStyle() {
            return {
                width: `${this.size?.width || 300}px`,
                height: `${this.size?.height || 600}px`,
                // eslint-disable-next-line no-unsafe-optional-chaining
                flexDirection: this.size?.height / this.size?.width > 0.6 ? "column" : "row",
            };
        },
        previewStyle() {
            const defaults = {
                width: `${this.size?.width || 300}px`,
                height: `${this.size?.height || 600}px`,
            };

            if (this.image) {
                defaults.backgroundImage = `url('${this.image}')`;
                defaults.border = "none";
                defaults.content = null;
            }

            return defaults;
        },
    },
    watch: {
        image() {
            this.setMockImageFile();
        },
    },
    created() {
        this.setMockImageFile();
    },
    methods: {
        onChange(file) {
            this.$emit("change", { file, name: this.name });
        },
        setMockImageFile() {
            if (this.image) {
                this.file = this.createMockImage(`${this.name}.png`);
            }
        },
        createMockImage(name) {
            return new File([], name, { type: "mock" });
        },
    },
};
</script>

<style lang="scss">
.creative-image-upload {
    height: 100%;
    width: 100%;
    user-select: none;
    cursor: pointer;

    .file-upload {
        background-color: #fff;
        height: 100%;
        width: 100%;
        border-radius: 3px;
        overflow: hidden;
        border: none;

        .v-messages__message {
            position: absolute;
            bottom: 3px;
            text-align: center;
            display: flex;
            width: 100%;
            justify-content: center;

            span {
                background-color: rgba(224, 171, 171, 0.3);
                padding: 2px 4px 1px;
                border-radius: 3px;
            }
        }
    }

    .file-upload-fullscreen-trigger {
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-hint {
            color: #9f9f9f;
            font-size: 14px;
            font-weight: 300;
        }
    }

    .file-upload-fullscreen-preview {
        position: absolute;
        inset: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .icon {
            position: absolute;
            top: 2px;
            right: 2px;
            padding: 12px !important;
        }
    }
}
</style>
