import Vue from "vue";
import App from "./App.vue";
import DesignSystem, { vuetify } from "@ads/design-system";
import "@/scss/main.scss";
import VueRouter from "vue-router";
import routes from "@/routes";
import store from "@/store";
import singleSpaVue from "single-spa-vue";
import './vuetify-loader.conf.js';

Vue.use(DesignSystem);
Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: "/dynamic-display",
    routes,
});

const vueLifeCycles = singleSpaVue({
    Vue,
    appOptions: {
        vuetify,
        render: (h) => h(App),
        router,
        store,
        el: "#microfrontend-application-container",
    },
});

export const bootstrap = vueLifeCycles.bootstrap;
export const mount = vueLifeCycles.mount;
export const unmount = vueLifeCycles.unmount;
