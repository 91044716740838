<template>
    <VForm ref="form" v-model="isFormValid" class="template-customization">
        <VRow no-gutters>
            <VCol sm="11" lg="10">
                <div>
                    <SrHeadline class="mb-1 section-headline brand-blue--text">Strategy </SrHeadline>
                    <VRadioGroup v-model="templateStrategy" class="mt-4 mb-1">
                        <div class="inline">
                            <SrRadioButton label="Recommended strategy" :value="templateStrategies.recommended" />
                            <SrInfoBox class="ml-2">
                                It is recommended to use automatic selection of the optimum strategy for recommending sports
                                events within an ad. The system will automatically compare the performance of different
                                recommendation strategies and choose the optimal one for the user’s profile for which the ad
                                impression is generated. In the case of manual setup, it is necessary to select which sport and
                                tournament to be used for a match recommendation.
                            </SrInfoBox>
                        </div>
                        <SrRadioButton label="Product Sets selection" class="mt-3" :value="templateStrategies.productSets" />
                    </VRadioGroup>
                </div>
                <div class="divider-b mt-2 mb-8" />
                <div class="disable-area" :class="{ active: !strategySettingsDisabled }">
                    <ProductSetSelect
                        class="pl-8"
                        :f8-operator-strings="template.businessEntity.f8OperatorStrings"
                        :disabled="!strategySettingsDisabled"
                        :default-value="template.config.primaryProductSetId"
                        @change="setProductSet"
                    />
                    <div v-if="!strategySettingsDisabled" class="disable-area-overlay" />
                </div>
                <div class="divider-b mt-2 mb-6" />
                <SrHeadline class="mb-1 section-headline brand-blue--text">Odd's Type </SrHeadline>
                <VRadioGroup v-if="showCompleteFeatures" v-model="template.config.oddsType" hide-details class="mt-0">
                    <SrRadioButton label="Money Line / 3-way odds" :value="oddType.THREE_WAY_ODDS" />
                    <SrRadioButton label="Money Line + Spreads + Totals" :value="oddType.SPREADS_TOTALS" />
                </VRadioGroup>
                <SrHeadline class="mb-1 section-headline brand-blue--text">Odd's Format </SrHeadline>
                <VRadioGroup v-model="template.config.oddsFormat" hide-details class="mt-0">
                    <SrRadioButton label="Decimal Odds" :value="oddFormat.DECIMAL" />
                    <SrRadioButton label="American Odds" :value="oddFormat.AMERICAN" />
                </VRadioGroup>
                <div class="divider-b mt-2 mb-7">
                    <SrSelect
                        v-model="template.config.dateFormat"
                        label="Date Format"
                        :items="dateFormats"
                        item-value="value"
                        item-text="label"
                        class="mt-7"
                    />
                </div>
                <div class="disable-area" :class="{ active: strategySettingsDisabled }">
                    <SrHeadline class="mb-1 section-headline brand-blue--text">Filtering </SrHeadline>
                    <SrSelect
                        v-model="template.config.sportId"
                        label="Select sport"
                        :items="sports"
                        item-value="id"
                        item-text="label"
                        class="mt-4 mb-1"
                        clearable
                    />
                    <SportConfigurationFilterSelect
                        :template-sport-id="template.config.sportId"
                        :template-filter-id="template.config.filterId"
                        :advertiser="template.businessEntity"
                        :disabled="strategySettingsDisabled"
                        @change="setSelectedFilter"
                    />
                    <div class="inline">
                        <SrCheckbox
                            v-model="template.config.hasGeoTargeting"
                            class="brand-blue--text mt-0"
                            label="Geo Filtering"
                            :disabled="strategySettingsDisabled"
                        />
                        <SrInfoBox class="ml-2">
                            When GEO-filtering is enabled, only local popularity trends are taken into account, based on the
                            statistics of website visitors from the same country/region as the user to whom the ad will be shown.
                        </SrInfoBox>
                    </div>
                    <div class="divider-b mt-2 mb-8" />
                    <div>
                        <SrHeadline class="mt-2 mb-1 section-headline brand-blue--text">Match Type </SrHeadline>
                        <VRadioGroup v-model="template.config.matchType" :disabled="strategySettingsDisabled" class="mt-0">
                            <SrRadioButton class="mt-1" label="Live" :value="matchTypes.LIVE" />
                            <SrRadioButton class="mt-1" label="Pre-match" :value="matchTypes.PREMATCH" />
                            <SrRadioButton class="mt-1" label="Any" :value="matchTypes.ANY" />
                        </VRadioGroup>
                    </div>
                    <SrHeadline class="mb-4 section-headline brand-blue--text"> Number of Matches to be displayed </SrHeadline>
                    <SrInput
                        v-model.number="template.config.amountOfEvents"
                        v-bind="$attrs"
                        label="Enter Number"
                        required
                        type="number"
                        min="1"
                        max="5"
                        :rules="amountOfEventsRules"
                        :disabled="strategySettingsDisabled"
                    />
                    <div class="divider-b mt-2 mb-7" />
                    <SrHeadline class="mb-4 section-headline brand-blue--text"> Recommendation language </SrHeadline>
                    <LanguageSelect
                        v-model="template.config.language"
                        :affiliate-id="template.businessEntity.dspId"
                        :disabled="strategySettingsDisabled"
                    />
                    <div v-if="strategySettingsDisabled" class="disable-area-overlay" />
                </div>
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrCheckbox, SrHeadline, SrInfoBox, SrRadioButton, SrInput, SrSelect } from "@ads/design-system";
import { EVENT_VERSION_KEYS, MatchType } from "@/modules/templates/types";
import ProductSetSelect from "@/modules/templates/components/TemplateForm/TemplateFormStepCustomization/sections/CustomizationConfiguration/ProductSetSelect";
import LanguageSelect from "@/components/LanguageSelect";
import SportConfigurationFilterSelect from "@/modules/templates/components/TemplateForm/TemplateFormStepCustomization/sections/CustomizationConfiguration/SportConfigurationFilterSelect";
import { getMinMaxValidation } from "@/validation/rules";
import sports from "@/lib/whiteListedSports.json";
import { ODD_TYPE, ODD_FORMAT } from "@/modules/templates/components/TemplateForm/TemplateFormStepCustomization/types";
import dateFormats from "@/modules/templates/components/TemplateForm/dateFormats.json";
import { sizesWithoutSpreadsFeatures } from "../../../config";
import BaseConfiguration from "./BaseConfiguration";

const templateStrategies = {
    recommended: "recommended",
    productSets: "productSets",
};

export default {
    name: "SportConfiguration",
    components: {
        SportConfigurationFilterSelect,
        LanguageSelect,
        ProductSetSelect,
        SrCheckbox,
        SrRadioButton,
        SrHeadline,
        SrInfoBox,
        SrInput,
        SrSelect,
    },
    extends: BaseConfiguration,
    data() {
        return {
            oddType: ODD_TYPE,
            oddFormat: ODD_FORMAT,
            templateStrategy: templateStrategies.recommended,
            templateStrategies,
            matchTypes: MatchType,
            isFormValid: this.isValid,
            amountOfEventsRules: getMinMaxValidation(1, 5),
            sports: [],
            dateFormats,
            showCompleteFeatures: false,
        };
    },
    computed: {
        strategySettingsDisabled() {
            return this.templateStrategy === templateStrategies.productSets;
        },
    },
    watch: {
        templateStrategy: {
            handler(strategyType) {
                if (strategyType === templateStrategies.recommended) {
                    this.setRecommendedStrategy();
                }
            },
        },
        "template.size": function () {
            this.$emit("update:template", this.template);
            this.updateShowCompleteFeatures();
        },
        "template.layout": function () {
            this.$emit("update:template", this.template);
            this.updateShowCompleteFeatures();
        },
    },
    created() {
        this.sports = sports;
        if (!this.template.config.sportId) {
            this.template.config.sportId = this.sports[0].id;
        }

        this.updateShowCompleteFeatures();
    },
    mounted() {
        if (this.template.config.primaryProductSetId) {
            this.templateStrategy = templateStrategies.productSets;
        }

        if (!this.template.config.oddsFormat) {
            this.template.config.oddsFormat = this.oddFormat.DECIMAL;
        }
    },
    methods: {
        updateShowCompleteFeatures() {
            const sizeId = typeof this.template.size === "number" ? this.template.size : this.template.size?.id;

            if (
                this.template.layout?.id &&
                sizeId &&
                // eslint-disable-next-line no-unsafe-optional-chaining
                !sizesWithoutSpreadsFeatures[this.template.layout?.id - 1]?.includes(sizeId - 1)
            ) {
                this.showCompleteFeatures = true;
            } else {
                this.showCompleteFeatures = false;
            }
        },
        setOddsType() {
            if (!this.template.config.oddsType) {
                this.template.config.oddsType = this.oddType.THREE_WAY_ODDS;
            }
        },
        setRecommendedStrategy() {
            this.$set(this.template.config, "primaryProductSetId", null);
            this.$set(this.template.config, "eventVersion", EVENT_VERSION_KEYS.V1);
        },
        setProductSet(productSet) {
            this.$set(this.template.config, "primaryProductSetId", productSet);
            this.$set(this.template.config, "eventVersion", EVENT_VERSION_KEYS.V2F8);
        },
        setSelectedFilter(selectedFilter) {
            this.$set(this.template.config, "filterId", selectedFilter);
        },
    },
};
</script>

<style lang="scss">
@import "~@ads/design-system/src/scss/variables";

.template-customization {
    .section-headline {
        font-size: 16px;
    }

    .inline {
        display: flex;
        align-items: baseline;
    }

    .disable-area {
        position: relative;

        &.active > * {
            pointer-events: none;
            user-select: none;
        }
    }

    .disable-area-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        filter: grayscale(1);
        z-index: 1000;
        background-color: rgba(255, 255, 255, 0.7);
    }
}

.theme--light.v-label {
    color: $brand-blue;
}
</style>
