<template>
    <VRow>
        <VCol sm="12">
            <SrHeadline level="3" weight="bold" class="mb-2">Configuration</SrHeadline>
        </VCol>
        <VCol sm="12">
            <component :is="componentForType" :template="template" v-bind="$attrs" v-on="$listeners" />
        </VCol>
    </VRow>
</template>

<script>
import { CREATIVE_TYPE } from "@/modules/templates/types";
import { SrHeadline } from "@ads/design-system";
import SportConfiguration from "./SportConfiguration";
import CasinoConfiguration from "./CasinoConfiguration";

const componentMap = {
    [CREATIVE_TYPE.SPORT]: "SportConfiguration",
    [CREATIVE_TYPE.CASINO]: "CasinoConfiguration",
};

export default {
    name: "CustomizationConfiguration",
    components: {
        SportConfiguration,
        CasinoConfiguration,
        SrHeadline,
    },
    props: {
        template: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        componentForType() {
            return componentMap[this.template.type];
        },
    },
};
</script>
