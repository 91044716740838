<template>
    <VForm ref="form" v-model="isFormValid" class="template-basics" :disabled="isLoading">
        <VRow>
            <VCol>
                <SrHeadline level="3" class="mb-6" weight="bold"> Basic Details</SrHeadline>
                <VRow>
                    <VCol sm="12" lg="4">
                        <SrInput v-model="template.name" :rules="rules.name" label="Creative Name" required />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol sm="12" lg="4" class="pb-0">
                        <SrSelect
                            v-model="template.businessEntity"
                            label="Advertiser"
                            :items="advertisers"
                            item-value="id"
                            item-text="name"
                            :disabled="isEditing"
                            required
                            searchable
                            searchable-property="name"
                            sort-order="ASC"
                            return-object
                        />
                    </VCol>
                    <VCol sm="12" lg="4" class="pb-0">
                        <SrInput
                            v-model="template.config.fallbackUrl"
                            :rules="rules.url"
                            label="Default landing page URL"
                            required
                        />
                        <SrCheckbox
                            v-model="template.config.isDefaultTargetUrl"
                            class="brand-blue--text mt-0 pt-0"
                            label="Use this URL for any ad impression by default"
                        />
                    </VCol>
                </VRow>
            </VCol>
        </VRow>
        <VRow class="mt-0">
            <VCol v-if="isUtmTrackingEnabled" class="pt-0">
                <UtmTrackingConfiguration :template="template" @revalidation="handleRevalidation" />
            </VCol>
            <VCol v-else class="pt-0 mb-4">
                <SrButton text class="utm-button" color="primary" @click="handleUtmTracking"> Set up UTM Tracking </SrButton>
            </VCol>
        </VRow>
        <VRow>
            <VCol sm="12" md="6">
                <CreativeTypeSelector
                    title="Select Template Type"
                    description="The process of creating a dynamic template and the available
                    features differ depending on the selected type."
                    :selected-type="template.type"
                    :readonly="isEditing || isDuplication"
                    :creative-types="CreativeTypes"
                    @change="setType"
                />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { mapGetters } from "vuex";
import { SrHeadline, SrInput, SrSelect, SrButton, SrCheckbox } from "@ads/design-system";
import { textValidationRules, urlValidationRules } from "@/validation/rules";
import CreativeTypeSelector from "@/components/CreativeTypeSelector/CreativeTypeSelector.vue";
import { commonKeys, getDefaultConfig } from "@/modules/templates/components/TemplateForm/config";
import { CREATIVE_TYPE } from "@/modules/templates/types";
import UtmTrackingConfiguration from "@/modules/templates/components/TemplateForm/TemplateFormStepBasics/UtmTrackingConfiguration.vue";
import { CreativeTypes, UTM_TRACKING_FIELDS } from "./config";

export default {
    name: "TemplateFormStepBasics",
    components: {
        UtmTrackingConfiguration,
        CreativeTypeSelector,
        SrInput,
        SrSelect,
        SrButton,
        SrHeadline,
        SrCheckbox,
    },
    inject: {
        isEditing: {
            default: false,
        },
        isDuplication: {
            default: false,
        },
    },
    model: {
        prop: "template",
        event: "input",
    },
    props: {
        template: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            CreativeTypes,
            rules: {
                name: textValidationRules({
                    propertyName: "Template name",
                    options: { min: 3, max: 128 },
                }),
                url: urlValidationRules({
                    propertyName: "Default landing page URL",
                    options: { min: 0, max: 10485760 },
                }),
            },
            isFormValid: this.isValid,
            creativeType: CREATIVE_TYPE,
            showUtmTrackingSetup: false,
        };
    },
    computed: {
        ...mapGetters({
            advertisers: "iam/businessEntities",
            layouts: "templates/layouts",
        }),
        isUtmTrackingEnabled() {
            return (
                this.showUtmTrackingSetup ||
                UTM_TRACKING_FIELDS.some((field) => Boolean(this.template.config[field.name]?.length))
            );
        },
    },
    watch: {
        "template.id": function () {
            this.$refs.form.resetValidation();
        },
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
    },
    methods: {
        setType(type) {
            if (CreativeTypes[type].disabled) {
                return;
            }

            this.$set(this.template, "type", type);

            const keptValues = commonKeys.reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: this.template.config[key],
                }),
                {},
            );

            this.$set(this.template, "config", {
                ...getDefaultConfig(type),
                ...keptValues,
            });
        },
        handleUtmTracking() {
            this.showUtmTrackingSetup = true;
        },
        handleRevalidation() {
            this.$refs.form.validate();
        },
    },
};
</script>
<style lang="scss">
.template-basics {
    .utm-button {
        font-size: 14px;
        text-transform: unset;
        font-weight: 800;
        padding: 0 !important;
        min-width: 0 !important;
        height: auto !important;

        &.v-btn:hover::before {
            opacity: 0;
        }
    }
}
</style>
