<template>
    <VRow no-gutters>
        <VCol sm="12">
            <VRow>
                <VCol sm="12">
                    <SrHeadline level="1" class="mb-0" weight="bold">Customization</SrHeadline>
                </VCol>
                <VCol sm="12">
                    <Switcher
                        :sections="sectionsOptions.filter((section) => section.component)"
                        :current="selectedSection"
                        @click="onSwitchClick"
                    />
                </VCol>
                <VCol sm="12">
                    <div class="divider-b mt-0 mb-5" />
                </VCol>
            </VRow>
        </VCol>
        <VCol sm="12">
            <VRow>
                <VCol sm="4" md="3">
                    <keep-alive v-if="selectedSection">
                        <component
                            :is="selectedSection.component"
                            :is-valid.sync="validStates[selectedSection.value]"
                            :template.sync="template"
                            :size="size"
                            :font="font"
                            :is-active="isActive"
                            @image-change="onImageChange"
                            @size-change="onSizeChange"
                        />
                    </keep-alive>
                </VCol>
                <VCol sm="8" md="9">
                    <HorizontalDraggableList
                        :is-valid.sync="validStates.images"
                        :template="template"
                        @transformation-open="onOpenTransformationModal"
                        @image-change="onImageChange"
                    />
                </VCol>
            </VRow>
        </VCol>
        <TransformationMatrixModal
            :template="template"
            :size="size"
            :font="font"
            :show="showTransformationModal"
            @save="onTransformationSave"
            @close="onCloseTransformationModal"
        />
    </VRow>
</template>

<script>
import { SrHeadline } from "@ads/design-system";
import Switcher from "@/components/Switcher";
import TemplatePreviewTabs from "@/modules/templates/components/TemplatePreviewTabs";
import TransformationMatrixModal from "@/modules/templates/components/TransformationMatrixModal";
import { mapGetters } from "vuex";
import loadImageAsBase64 from "@/utils/loadImageAsBase64";
import CustomizationTemplateStyle from "./sections/CustomizationTemplateStyle";
import CustomizationContent from "./sections/CustomizationContent";
import CustomizationConfiguration from "./sections/CustomizationConfiguration/CustomizationConfiguration";
import CustomHTMLCssConfiguration from "./sections/CustomHTMLCSSConfiguration";
import HorizontalDraggableList from "./HorizontalDraggableList";

const sections = [
    {
        label: "Template Style",
        value: "style",
        component: "CustomizationTemplateStyle",
    },
    {
        label: "Content",
        value: "content",
        component: "CustomizationContent",
    },
    {
        label: "Configuration",
        value: "configuration",
        component: "CustomizationConfiguration",
    },
    {
        value: "images",
    },
];

const sectionsForCustomHtml = [
    {
        label: "Template Html",
        value: "customHtml",
        component: "CustomHTMLCssConfiguration",
    },
    {
        label: "Configuration",
        value: "configuration",
        component: "CustomizationConfiguration",
    },
    {
        value: "images",
    },
];

export default {
    name: "TemplateFormStepCustomization",
    components: {
        TransformationMatrixModal,
        TemplatePreviewTabs,
        SrHeadline,
        Switcher,
        CustomizationTemplateStyle,
        CustomizationConfiguration,
        CustomizationContent,
        CustomHTMLCssConfiguration,
        HorizontalDraggableList,
    },
    model: {
        prop: "template",
        event: "input",
    },
    props: {
        template: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showTransformationModal: false,
            selectedSection: null,
            validStates: {
                style: this.isValid,
                content: this.isValid,
                configuration: this.isValid,
                customHtml: this.isValid,
                images: this.isValid,
            },
            previewHint: null,
        };
    },
    computed: {
        ...mapGetters({
            sizeById: "templates/sizeById",
            fontById: "templates/fontById",
        }),
        size() {
            const { layout, size } = this.template;

            return Number.isInteger(size) ? layout?.sizes.find((s) => s.id === size) : size;
        },
        font() {
            const font = this.template.config.font;

            return Number.isInteger(font) ? this.fontById(font) : font;
        },
        isFormValid() {
            return this.isEverySectionValid;
        },
        isEverySectionValid() {
            return Object.keys(this.validStates)
                .filter((stateKey) => this.sectionsOptions.find((option) => option.value === stateKey))
                .every((stateKey) => this.validStates[stateKey]);
        },
        sectionsOptions() {
            return this.isCustomHtml ? sectionsForCustomHtml : sections;
        },
        isCustomHtml() {
            return this.template.layout?.id === 5;
        },
    },
    watch: {
        isFormValid: "emitValidState",
        "template.id": function () {
            this.selectedSection = this.sectionsOptions[0];
        },
        sectionsOptions() {
            this.selectedSection = this.sectionsOptions[0];
        },
    },
    methods: {
        onSwitchClick(value) {
            this.selectedSection = value;
        },
        emitValidState() {
            this.$emit("update:isValid", this.isFormValid);
        },
        setImage(value, name) {
            let image = { value };

            if (value === undefined) {
                image = null;
            }

            this.$set(this.template.config, name, image);
        },
        imageToBase64(image, name) {
            if (!image) {
                return this.setImage(null, name);
            }

            if (image.type === "mock") {
                return this.setImage(undefined, name);
            }

            return loadImageAsBase64(image).then((value) => this.setImage(value, name));
        },
        onImageChange({ file, name }) {
            this.imageToBase64(file, name);
        },
        onOpenTransformationModal() {
            this.showTransformationModal = true;
        },
        onCloseTransformationModal() {
            this.showTransformationModal = false;
        },
        onTransformationSave(transformation) {
            this.$set(this.template.config, "transformation", transformation);
            this.onCloseTransformationModal();
        },
        onSizeChange() {
            this.previewHint = "The fallback image should be updated if the template size is changed.";
        },
    },
};
</script>
