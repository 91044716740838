import { CREATIVE_TYPE, Size } from "@/modules/templates/types";

export const CreativeTypeLabels = {
    [CREATIVE_TYPE.SPORT]: "Sports",
    [CREATIVE_TYPE.CASINO]: "Casino",
};

export const formatSizes = (sizes: Size[]): Size[] =>
    sizes.map((size) => ({
        ...size,
        label: `${size.width}x${size.height}`,
    }));
