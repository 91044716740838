import FiltersState, { LOADING_STATES } from "@/modules/filters/types/filtersState";
import Filter from "@/modules/filters/types/filter";

export default {
    setFilters(state: FiltersState, items: Filter[]): void {
        state.items = items;
    },
    setFilter(state: FiltersState, item: Filter): void {
        state.items = state.items.map((filter) => (filter.filterId === item.filterId ? item : filter));
    },
    replaceFilter(state: FiltersState, filter: Filter): void {
        state.items = state.items.map((oldFilter) => (filter.filterId === oldFilter.filterId ? filter : oldFilter));
    },
    setLoading(state: FiltersState, { key, value }: { key: LOADING_STATES; value: boolean }): void {
        state.loadingStates = {
            ...state.loadingStates,
            [key]: value,
        };
    },
};
