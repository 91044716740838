import BusinessEntity from "@/types/businessEntity";
import { iamService } from "@/modules/iam/services/IamService";
import { LOADING_STATES } from "./state";

export default {
    initialize({ dispatch }): void {
        dispatch("fetchBusinessEntities");
    },

    async fetchBusinessEntities({ commit }): Promise<BusinessEntity[]> {
        commit("setLoading", {
            key: LOADING_STATES.BUSINESS_ENTITIES,
            value: true,
        });

        const items = await iamService.getBusinessEntities();

        commit("setBusinessEntities", items);
        commit("setLoading", {
            key: LOADING_STATES.BUSINESS_ENTITIES,
            value: false,
        });

        return items;
    },
};
