import { ACTION_NAME, IRequestOptions, RequestService } from "@ads/iam-library";
import { API_URL } from "@/config";
import { Font, Layout } from "../types";

export default class TemplateCustomizationElementsService {
    private readonly defaultRequestOptions: Pick<IRequestOptions, "domain" | "resource"> = {
        domain: "dynamic-display",
        resource: "",
    };

    private readonly requestService: RequestService;

    private readonly domainPath: string;

    private readonly fontsPath = "fonts";

    private readonly layoutsPath = "layouts";

    constructor(requestService: RequestService, domainPath?: string) {
        this.requestService = requestService;
        this.domainPath = domainPath;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return {
            ...this.defaultRequestOptions,
            ...options,
        } as IRequestOptions;
    }

    async getAvailableLayouts(): Promise<Layout[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/${this.layoutsPath}`, requestOptions);
    }

    async getAvailableFonts(): Promise<Font[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/${this.fontsPath}`, requestOptions);
    }
}

export const templateCustomizationElementsService = new TemplateCustomizationElementsService(
    new RequestService({ baseUrl: API_URL }),
    "",
);
