export const USER_LOCALE = Intl.DateTimeFormat().resolvedOptions().locale;

const {
    NODE_ENV,
    VUE_APP_API_URL,
    VUE_APP_API_URL_PORT,
    VUE_APP_AD_BUILDER_API_URL_PORT,
    VUE_APP_AD_BUILDER_API_URL,
    VUE_APP_CONTEXT_AUTHORIZER_HEADER,
} = process.env;

console.log(VUE_APP_AD_BUILDER_API_URL);
const apiPort = VUE_APP_API_URL_PORT ?? 3000;
const adBuilderApiPort = VUE_APP_AD_BUILDER_API_URL_PORT ?? 3006;
export const API_URL = NODE_ENV === "development" ? `http://localhost:${apiPort}/dev` : VUE_APP_API_URL;
export const AD_BUILDER_API_URL =
    NODE_ENV === "development" ? `http://localhost:${adBuilderApiPort}/trpc` : VUE_APP_AD_BUILDER_API_URL;
export const CONTEXT_AUTHORIZER_HEADER = NODE_ENV === "development" ? VUE_APP_CONTEXT_AUTHORIZER_HEADER : "";
