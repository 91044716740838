import { ACTION_NAME, IRequestOptions, RequestService } from "@ads/iam-library";
import { API_URL } from "@/config";
import CustomerTournament from "@/modules/tournaments/types/CustomerTournament";

interface TournamentsFindOptions {
    sport?: string;
    language?: string;
}

export default class CustomerTournamentsService {
    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: "dynamic-display",
        resource: "customer-tournament",
    };

    constructor(private readonly requestService: RequestService) {}

    getByAffiliateId(affiliateId: string, search: string, find: TournamentsFindOptions = {}): Promise<CustomerTournament[]> {
        const options: IRequestOptions = {
            ...this.defaultRequestOptions,
            action: ACTION_NAME.READ,
        } as IRequestOptions;

        const urlParams = new URLSearchParams();

        if (search?.length > 3) {
            urlParams.set("search", search);
        }

        Object.entries(find).forEach(([key, value]) => {
            if (value?.length) {
                urlParams.set(key, value);
            }
        });

        const url = `/customer-tournaments/${affiliateId}?${urlParams}`;

        return this.requestService.get(url, options);
    }
}

export const customerTournamentsService = new CustomerTournamentsService(new RequestService({ baseUrl: API_URL }));
