<template>
    <SrSelect
        v-model="selectedProductSet"
        v-bind="$attrs"
        :disabled="disabled"
        label="Product Set"
        :required="!disabled"
        :items="productSets"
        :error-messages="productSetsErrors"
        :loading="productSetsLoading"
        item-text="productSetName"
        item-value="productSetId"
        searchable
        v-on="$listeners"
    />
</template>
<script>
import { SrSelect } from "@ads/design-system";

export default {
    name: "ProductSetSelect",
    components: {
        SrSelect,
    },
    props: {
        f8OperatorStrings: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        defaultValue: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            selectedProductSet: null,
            productSets: [],
            productSetsLoading: true,
            productSetsErrors: [],
        };
    },
    watch: {
        f8OperatorStrings(f8OperatorStrings) {
            this.fetchProductSets(f8OperatorStrings);
        },
        defaultValue() {
            if (this.selectedProductSet && this.defaultValue) {
                this.productSetsErrors = [];
            }
        },
    },
    created() {
        this.fetchProductSets(this.f8OperatorStrings);
    },
    methods: {
        async fetchProductSets(f8OperatorStrings) {
            this.productSetsLoading = true;

            try {
                const productSetsResponse = await this.$store.dispatch("templates/fetchProductSets", f8OperatorStrings);
                this.productSets = productSetsResponse.map((productSet) => ({
                    productSetName: productSet.consoleProductSetName,
                    productSetId: productSet.consoleProductSetId,
                }));
                this.productSetsErrors = [];

                this.setDefaultProductSet();
            } catch (e) {
                this.productSetsErrors = [e.message];
            } finally {
                this.productSetsLoading = false;
            }
        },
        setDefaultProductSet() {
            if (!this.selectedProductSet && this.defaultValue) {
                this.selectedProductSet = this.findProductSet(this.defaultValue);

                if (!this.selectedProductSet) {
                    this.productSetsErrors = ["Product set is missing"];
                }
            }
        },
        findProductSet(setId) {
            return this.productSets.find((set) => set.productSetId === setId);
        },
    },
};
</script>
