<template>
    <SrMenu v-model="isOpen" offset-y min-width="280" :close-on-content-click="false" content-class="dynamic-display-date-picker">
        <template #activator="{ on, attrs }">
            <SrInput
                class="date-input-field"
                readonly
                v-bind="{ ...attrs, ...$attrs }"
                :disabled="disabled"
                :label="label"
                :value="formattedDate"
                :required="required"
                append-icon="calendar"
                v-on="on"
                @click:append="onIconClick"
            />
        </template>
        <VDatePicker
            color="date-picker-menu brand-red"
            :min="min"
            :max="max"
            no-title
            :input-attr="{ required: 'true' }"
            :value="value"
            :show-current="showCurrent"
            @input="emitDateChange"
        />
    </SrMenu>
</template>

<script>
import { SrInput, SrMenu } from "@ads/design-system";
import { datePickerFormatDate } from "@/components/DatePicker/format";

export default {
    name: "DatePicker",
    components: {
        SrInput,
        SrMenu,
    },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
        },
        max: {
            type: String,
            default: undefined,
        },
        min: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            default: "Date",
        },
        value: {
            type: String,
            default: null,
        },
        showCurrent: {
            type: [String, Boolean],
            default: true,
        },
        dateFormat: {
            type: Object,
            default: () => ({
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        formattedDate() {
            return datePickerFormatDate(this.value);
        },
    },
    methods: {
        emitDateChange(value) {
            this.isOpen = false;
            this.$emit("change", value);
        },
        onIconClick() {
            this.isOpen = true;
        },
    },
};
</script>

<style lang="scss">
.dynamic-display-date-picker {
    min-height: 300px;
}

.date-input-field.v-text-field--enclosed .v-input__append-inner {
    margin-top: 14px;
}
</style>
