<template>
    <VRow>
        <VCol sm="12" md="10">
            <VRow>
                <VCol sm="12" md="6">
                    <TournamentMappingCustomerTournamentSelect
                        v-if="!tournament.isCustomerTournamenInputManual"
                        :default-customer-tournaments="defaultCustomerTournaments"
                        :fetch-customer-tournaments="fetchCustomerTournaments"
                        :tournament="tournament"
                        @error="emitError"
                    />
                    <TournamentMappingCustomerTournamentManualInput
                        v-if="tournament.isCustomerTournamenInputManual"
                        :tournament="tournament"
                    />
                </VCol>
                <VCol sm="12" md="6">
                    <SrSelect
                        v-model="tournament.srTournament"
                        label="SR tournament"
                        :items="displayedSrTournaments"
                        item-text="displayName"
                        item-value="id"
                        searchable
                        required
                        sort-order="ASC"
                        hide-details
                        return-object
                        @search-keyup.enter="onSDSTournamentSearch"
                    />
                </VCol>
            </VRow>
        </VCol>
        <VCol sm="12" md="2">
            <slot name="action" />
        </VCol>
    </VRow>
</template>

<script>
import { SrSelect } from "@ads/design-system";
import { sdsTournamentsService } from "@/modules/tournaments/services/SDSTournamentsService";
import TournamentMappingCustomerTournamentSelect from "@/modules/tournaments/components/TournamentsMappingForm/TournamentMappingCustomerTournamentSelect.vue";
import TournamentMappingCustomerTournamentManualInput from "@/modules/tournaments/components/TournamentsMappingForm/TournamentMappingCustomerTournamentManualInput.vue";

export default {
    name: "TournamentMappingRow",
    components: {
        TournamentMappingCustomerTournamentSelect,
        TournamentMappingCustomerTournamentManualInput,
        SrSelect,
    },
    props: {
        tournament: {
            type: Object,
            default: () => {},
        },
        defaultSrTournaments: {
            type: Array,
            default: () => [],
        },
        defaultCustomerTournaments: {
            type: Array,
            default: () => {},
        },
        fetchCustomerTournaments: {
            type: Function,
            default: null,
        },
        selectedSport: {
            type: String,
            default: () => "",
        },
    },
    data() {
        return {
            srTournaments: [],
        };
    },
    computed: {
        displayedSrTournaments() {
            let result;
            if (this.srTournaments?.length) {
                result = this.srTournaments;
            } else {
                result = this.defaultSrTournaments;
            }

            return result;
        },
    },
    watch: {
        "tournament.srTournament": function () {
            this.emitError(null);
        },
    },
    methods: {
        emitError(error) {
            this.$emit("error", error);
        },
        onSDSTournamentSearch(event) {
            const searchValue = event.target.value;

            if (searchValue === "") {
                this.srTournaments = [];
            } else {
                const tournamentId = this.srTournament ? [this.srTournament.id] : [];
                sdsTournamentsService
                    .searchSDSTournaments(searchValue, this.selectedSport, tournamentId)
                    .then((result) => {
                        this.srTournaments = result;
                    })
                    .catch((error) => {
                        this.emitError(error);
                        this.srTournaments = [];
                    });
            }
        },
    },
};
</script>

<style scoped></style>
