<template>
    <ActionModal
        title="Delete Video"
        :value="isOpen"
        :description="description"
        :error="errorMessage"
        :is-loading="isDeleting"
        @cancel="cancelDeletion"
    >
        <template #actions>
            <SrButton type="delete" :loading="isDeleting" :disabled="isDeleting" @click="deleteVideo"> Confirm </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import ActionModal from "@/components/ActionModal";
import { SrButton } from "@ads/design-system";
import { mapActions } from "vuex";

export default {
    name: "VideosDeleteModal",
    components: {
        ActionModal,
        SrButton,
    },
    props: {
        videoId: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            isOpen: true,
            isDeleting: false,
            errorMessage: null,
        };
    },
    computed: {
        description() {
            return (
                "Once you mark a video for delition it will not be possible anymore to undo the operation " +
                "Are you sure you want to delete the video?"
            );
        },
    },
    watch: {
        value(newValue) {
            this.isOpen = newValue;
        },
        isOpen() {
            this.emitIsOpenChange();
        },
    },
    methods: {
        ...mapActions({
            markForDelete: "videos/markForDelete",
        }),
        emitIsOpenChange() {
            this.$emit("input", this.isOpen);
        },
        cancelDeletion() {
            this.clear();
            this.$emit("cancel");
        },
        handleDeletionSuccess() {
            this.clear();
            this.$emit("completed");
        },
        handleDeletionError() {
            this.errorMessage = "Could not mark video for deletion";
        },
        async deleteVideo() {
            this.isDeleting = true;

            try {
                await this.markForDelete(this.videoId);
                this.handleDeletionSuccess();
            } catch (e) {
                this.handleDeletionError();
            } finally {
                this.isDeleting = false;
            }
        },
        clear() {
            this.isDeleting = false;
            this.errorMessage = null;
        },
    },
};
</script>
