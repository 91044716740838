<template>
    <VAlert v-if="show" dense outlined type="error">
        <p v-for="(line, index) in lines" :key="index" class="line">{{ line }}</p>
    </VAlert>
</template>
<script>
export default {
    name: "ErrorBox",
    props: {
        message: {
            type: String,
            default: null,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        lines() {
            return this.message
                .split("\n")
                .map((l) => l.trim())
                .filter((l) => Boolean(l.length));
        },
    },
};
</script>

<style>
.v-application .line {
    margin-bottom: 0;
}
</style>
