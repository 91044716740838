<template>
    <div>
        <SrHeadline level="3" class="mb-2" weight="bold">{{ title }}</SrHeadline>
        <div class="mb-6 subtitle">{{ description }}</div>
        <VRow>
            <VCol v-for="(type, key) in creativeTypes" :key="key" sm="12" md="6" lg="4" class="card">
                <Card
                    :active="key === selectedType"
                    :disabled="readonly && key !== selectedType"
                    :class="selectOptionClassList"
                    @click="onClick(key)"
                >
                    <SrIcon size="m" :icon="type.icon" />
                    <SrHeadline level="2" class="mb-2 mt-2">{{ type.label }}</SrHeadline>
                    <div class="description">{{ type.description }}</div>
                </Card>
            </VCol>
        </VRow>
    </div>
</template>
<script>
import Card from "@/components/Card";
import { SrHeadline, SrIcon, USER_INPUT_CLASS } from "@ads/design-system";

export default {
    name: "CreativeTypeSelector",
    components: { Card, SrIcon, SrHeadline },
    props: {
        selectedType: {
            type: String,
            required: false,
            default: "CASINO",
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        creativeTypes: {
            type: Object,
            required: true,
        },
    },
    computed: {
        selectOptionClassList() {
            return ["card", USER_INPUT_CLASS];
        },
    },
    methods: {
        onClick(type) {
            if (this.readonly) {
                return;
            }

            this.$emit("change", type);
        },
    },
};
</script>
<style scoped>
.card {
    min-height: 100%;
}

.subtitle {
    font-size: 14px;
}

.description {
    font-size: 12px;
    color: #9c9c9c;
}
</style>
