<template>
    <TemplatePreview class="preview-container" :template="template" :size="size" :font="font" v-on="$listeners" />
</template>
<script>
import TemplatePreview from "@/modules/templates/components/TemplatePreview";

const defaultHintMessage = "This is a preview and the real template is not affected before saving it.";

export default {
    name: "TemplatePreviewTabs",
    components: { TemplatePreview },
    inject: {
        isViewOnly: {
            default: false,
        },
    },
    props: {
        template: {
            type: Object,
            default: () => ({}),
        },
        size: {
            type: Object,
            default: () => ({}),
        },
        font: {
            type: Object,
            default: () => ({}),
        },
        hint: {
            type: String,
            default: null,
        },
    },
    computed: {
        hintSettings() {
            return {
                style: {
                    width: `${this.size?.width || 315}px`,
                },
                text: this.hint || defaultHintMessage,
            };
        },
    },
};
</script>
