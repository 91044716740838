<template>
    <div class="dynamic-display-mf" data-app>
        <VApp id="microfrontend-application-container" class="dynamic-display">
            <SrAppContainer id="dynamic-display-container" :hide-sidebar="false">
                <template #sidebar>
                    <SideNavigation />
                </template>
                <template #content>
                    <RouterView />
                </template>
            </SrAppContainer>
        </VApp>
    </div>
</template>
<script>
import { SrAppContainer } from "@ads/design-system";
import SideNavigation from "@/components/SideNavigation";
import "@/app.scss";

export default {
    name: "App",
    components: {
        SrAppContainer,
        SideNavigation,
    },
};
</script>
