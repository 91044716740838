export default interface ComposedFieldSearchParam {
    composedField: string;
    composedFieldName: string;
}

export function getQuickSearchResults<T>(items: T[], searchFields: string[], query: string, composedFields = []): T[] {
    return items.filter(
        (item: T) =>
            searchFields.some((field: string) => {
                if (!item[field]) {
                    return false;
                }

                const itemType = typeof item[field];
                const value = itemType === "number" || itemType === "object" ? item[field].toString() : item[field];

                return value.toLowerCase().includes(query);
            }) ||
            composedFields.some((field) => {
                const fieldValue = item[field.composedField][field.composedFieldName];
                const itemType = typeof fieldValue;

                const fieldValueConverted = itemType === "number" || itemType === "object" ? fieldValue.toString() : fieldValue;
                return fieldValueConverted.toLowerCase().includes(query);
            }),
    );
}
