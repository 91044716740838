import { CREATIVE_TYPE } from "@/modules/templates/types";
import { CreativeTypeLabels } from "@/modules/templates/strings";

export const CreativeTypes = {
    [CREATIVE_TYPE.SPORT]: {
        label: CreativeTypeLabels[CREATIVE_TYPE.SPORT],
        icon: "Football",
        description: "Dynamic sports template",
    },
    [CREATIVE_TYPE.CASINO]: {
        label: CreativeTypeLabels[CREATIVE_TYPE.CASINO],
        icon: "Casino",
        description: "Dynamic Online Casino template",
    },
};

export const UTM_TRACKING_FIELDS = [
    { name: "utmSource", label: "Source" },
    { name: "utmMedium", label: "Medium" },
    { name: "utmCampaign", label: "Campaign" },
    { name: "utmTerm", label: "Term" },
];
