<template>
    <SrSelect
        v-model="internalValue"
        v-bind="$attrs"
        :disabled="disabled"
        label="Language"
        :items="languages"
        :error-messages="errors"
        :loading="loading"
        item-text="label"
        item-value="code"
        searchable
        v-on="$listeners"
    />
</template>
<script>
import { SrSelect } from "@ads/design-system";
import { metadataService } from "@/modules/templates/services";
import LANGUAGE_CODES from "@/utils/languageCodes.json";

export default {
    name: "LanguageSelect",
    components: {
        SrSelect,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: null,
        },
        affiliateId: {
            type: [Number, String],
            required: false,
            default: null,
        },
        autoSelectFirst: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            internalValue: this.value,
            languages: [],
            loading: false,
            errors: [],
        };
    },
    watch: {
        affiliateId(affiliateId) {
            this.fetchLanguages(affiliateId);
        },
        value() {
            this.internalValue = this.value;
            this.errors = [];
        },
        internalValue(value) {
            this.$emit("input", value);
        },
    },
    created() {
        this.fetchLanguages(this.affiliateId);
    },
    methods: {
        async fetchLanguages(affiliateId) {
            if (!affiliateId) {
                return;
            }

            this.loading = true;

            try {
                const languagesCodes = await metadataService.getLanguages(affiliateId);
                this.languages = languagesCodes.map((code) => ({
                    code,
                    label: LANGUAGE_CODES[code]?.name || code.toUpperCase(),
                }));

                this.errors = [];
                this.setDefaultValue();
            } catch (e) {
                this.errors = [e.message];
            } finally {
                this.loading = false;
            }
        },
        setDefaultValue() {
            if (!this.languages.length) {
                this.$emit("input", null);
                return;
            }

            if (!this.internalValue && !this.autoSelectFirst) {
                return;
            }

            const selectedLanguage = this.findLanguage(this.internalValue);

            if (selectedLanguage) {
                return;
            }

            if (this.autoSelectFirst) {
                this.internalValue = this.languages[0].code;
            } else {
                this.errors = ["Language is missing"];
            }
        },
        findLanguage(languageCode) {
            return this.languages.find((lng) => lng.code === languageCode);
        },
    },
};
</script>
