<template>
    <VForm ref="form" v-model="isFormValid">
        <VRow no-gutters>
            <VCol sm="12">
                <SrHeadline level="3" weight="bold" class="mb-8">Content</SrHeadline>
            </VCol>
            <VCol sm="11" lg="10">
                <SrInput v-model="template.config.legalText" label="Legal Text" />
                <SrInput v-model="template.config.offerLine1" label="Offer Line 1" class="mt-2" />
                <SrInput v-model="template.config.offerLine2" label="Offer Line 2" class="mt-2" />
                <SrInput v-model="template.config.ctaText" class="mt-2" label="Button CTA" :rules="rules.ctaText" />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrInput, SrHeadline } from "@ads/design-system";
import { textValidationRules } from "@/validation/rules";

export default {
    name: "CustomizationContent",
    components: {
        SrInput,
        SrHeadline,
    },
    props: {
        template: {
            type: Object,
            default: () => ({}),
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isFormValid: this.isValid,
            rules: {
                ctaText: textValidationRules({ options: { optional: true, max: 20 } }),
            },
        };
    },
    watch: {
        "template.config": {
            deep: true,
            handler() {
                this.$emit("update:template", this.template);
            },
        },
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
    },
};
</script>
