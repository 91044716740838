<script>
export default {
    name: "BaseConfiguration",
    props: {
        template: {
            type: Object,
            default: () => ({}),
        },
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        "template.config": {
            deep: true,
            handler() {
                this.$emit("update:template", this.template);
            },
        },
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
    },
};
</script>
