<template>
    <section>
        <FilterForm
            v-model="filter"
            submit-action="filters/edit"
            after-close-route="filters-root"
            after-submit-route="filters-root"
            :after-submit-route-params="{
                updateSuccess: { name: filterName, advertiser: advertiser },
            }"
            :after-close-route-params="{
                close: { advertiser: filter.businessEntity },
            }"
        />
        <RouterView :filter="filter" />
    </section>
</template>

<script>
import FilterForm from "@/modules/filters/components/FilterForm";
import { mapGetters } from "vuex";

export default {
    name: "FiltersEdit",
    components: {
        FilterForm,
    },
    provide: {
        isEditing: true,
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            filter: null,
            isLoading: true,
        };
    },
    computed: {
        ...mapGetters({
            items: "filters/getFilters",
        }),
        filterName() {
            return this.filter?.name;
        },
        advertiser() {
            return this.filter?.businessEntity;
        },
    },
    mounted() {
        this.fetchFilter();
    },
    methods: {
        async fetchFilter() {
            const filterId = this.$route.params.filterId;

            try {
                this.filter = this.items.find((filterCandidate) => filterCandidate.filterId === filterId);
            } catch (error) {
                this.error = error.message || error.response.message;
            }

            this.isLoading = false;
        },
    },
};
</script>
