import { CREATIVE_TYPE, EVENT_VERSION_KEYS } from "@/modules/templates/types";

export const templateSets = {
    [CREATIVE_TYPE.SPORT]: {
        [EVENT_VERSION_KEYS.V1]: {
            1000: [1, 3, 4, 5, 8],
        },
        [EVENT_VERSION_KEYS.V2]: {
            1000: [1, 3, 4, 5, 8],
        },
        [EVENT_VERSION_KEYS.V2F8]: {
            1000: [1, 3, 4, 5, 8],
        },
    },
    [CREATIVE_TYPE.CASINO]: {
        [EVENT_VERSION_KEYS.V1]: {
            1000: [2, 6],
        },
    },
};
