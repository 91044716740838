import { API_URL } from "@/config";
import { ACTION_NAME, IRequestOptions, RequestService } from "@ads/iam-library";
import TournamentMapping from "../types/TournamentMapping";

export default class TournamentMappingsService {
    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: "dynamic-display",
        resource: "tournament-mapping",
    };

    constructor(private readonly requestService: RequestService) {}

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return {
            ...this.defaultRequestOptions,
            ...options,
        } as IRequestOptions;
    }

    async getTournamentMappings(sport: string, language: string, advertiserId: string): Promise<TournamentMapping[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(
            `/tournament-mapping?sport=${sport}&language=${language}&advertiserId=${advertiserId}`,
            options,
        );
    }

    async getTournamentMappingsForSportAndAdvertiser(sport: string, advertiserId: string): Promise<TournamentMapping[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/tournament-mapping?sport=${sport}&advertiserId=${advertiserId}`, options);
    }

    async saveTournamentMappings(
        tournamentMappings: TournamentMapping[],
        sport: string,
        language: string,
        advertiserId: number,
    ): Promise<TournamentMapping[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });
        const request = {
            sportId: sport,
            language,
            advertiserId: advertiserId.toString(),
            mappings: tournamentMappings,
        };

        return this.requestService.post("/tournament-mapping", request, options);
    }
}

export const tournamentMappingsService = new TournamentMappingsService(new RequestService({ baseUrl: API_URL }));
