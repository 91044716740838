import { LOADING_STATES } from "@/modules/templates/store/state";
import { Video, VideoLayout, VideosState } from "@/modules/videos/types";

export default {
    items: (state: VideosState): Video[] => state.items,
    layouts: (state: VideosState): VideoLayout[] => state.layouts,
    loadingStates:
        (state: VideosState) =>
        (key: LOADING_STATES): boolean =>
            Boolean(state.loadingStates[key]),
    adBuilderTemplates: (state: VideosState) => state.adBuilderTemplates,
};
