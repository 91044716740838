class S3PresignedGetFetchService {
    async download(url: string): Promise<File> {
        try {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`Failed to download file. Status: ${response.status}`);
            }
            // Convert the response body to a Blob
            const videoBlob = await response.blob();

            // Create a File object from the Blob
            return new File([videoBlob], "video.mp4", {
                type: response.headers.get("content-type") || "video/mp4",
            });
        } catch (error) {
            console.error("Error downloading file:", error);
            throw error;
        }
    }
}

export const s3PresignedGetFetchService = new S3PresignedGetFetchService();
