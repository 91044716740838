import { render, staticRenderFns } from "./CustomizationTemplateStyle.vue?vue&type=template&id=0c6bb162"
import script from "./CustomizationTemplateStyle.vue?vue&type=script&lang=js"
export * from "./CustomizationTemplateStyle.vue?vue&type=script&lang=js"
import style0 from "./CustomizationTemplateStyle.vue?vue&type=style&index=0&id=0c6bb162&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VForm,VRadioGroup,VRow,VTab,VTabItem})
