import { AD_BUILDER_API_URL, CONTEXT_AUTHORIZER_HEADER } from "@/config";
import { ACTION_NAME, IRequestOptions, RequestService } from "@ads/iam-library";

interface FetchTemplatesResponse {
    result: {
        data: {
            json: {
                thumbnails: {
                    id: string;
                    name: string;
                    thumbnail: string;
                }[];
            };
        };
    };
}

export default class AdBuilderService {
    private readonly defaultRequestOptions: Pick<IRequestOptions, "domain" | "resource"> = {
        domain: "template-builder",
        resource: "template",
    };

    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return {
            ...this.defaultRequestOptions,
            ...options,
        } as IRequestOptions;
    }

    public fetchTemplates(): Promise<FetchTemplatesResponse> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });
        return this.requestService.get(`/adTemplate.listThumbnails`, requestOptions);
    }
}

const defaultHeaders =
    CONTEXT_AUTHORIZER_HEADER.length > 0
        ? {
              "X-Event-Request-Context-Authorizer": CONTEXT_AUTHORIZER_HEADER,
          }
        : undefined;

export const adBuilderService = new AdBuilderService(new RequestService({ baseUrl: AD_BUILDER_API_URL, defaultHeaders }));
