import { LOADING_STATES } from "@/modules/templates/store/state";
import { templatesService } from "@/modules/templates/services/TemplatesService";
import { templateCustomizationElementsService } from "@/modules/templates/services/TemplateCustomizationElementsService";
import { CasinoConfig, DefaultImage, Layout, ProductSet, Size, SportConfig, Template } from "@/modules/templates/types";
import emptyFieldsToNull from "@/utils/emptyFieldsToNull";
import { formatSizes } from "@/modules/templates/strings";

const transformTemplateBeforeSave = (sourceTemplate: Template): Template => {
    const template = { ...sourceTemplate };

    template.config = emptyFieldsToNull<CasinoConfig | SportConfig>(template.config);

    if ("transformation" in template.config && template.config.transformation) {
        template.config.transformation = JSON.stringify(template.config.transformation);
    }

    if (typeof template.config.font === "object") {
        template.config.font = template.config.font.id;
    }

    if (typeof template.layout === "object") {
        template.layout = template.layout.id;
    }

    if (typeof template.businessEntity === "object") {
        template.businessEntity = template.businessEntity.id;
    }

    return template;
};

export default {
    initialize({ dispatch }): void {
        dispatch("fetchFonts");
        dispatch("fetchLayouts");
    },

    async fetchFonts({ commit }): Promise<void> {
        const fonts = await templateCustomizationElementsService.getAvailableFonts();

        commit("setFonts", fonts);
    },

    async fetchLayouts({ commit }): Promise<Layout[]> {
        const layouts = await templateCustomizationElementsService.getAvailableLayouts();

        commit(
            "setLayouts",
            layouts.map((layout) => ({
                ...layout,
                sizes: formatSizes(layout.sizes),
            })),
        );

        return layouts;
    },

    fetchProductSets(_, f8OperatorStrings: string[]): Promise<ProductSet[]> {
        return templatesService.getProductSets(f8OperatorStrings);
    },

    fetchDefaultImages(_, size: Size): Promise<DefaultImage[]> {
        return templatesService.getDefaultImages(size);
    },

    async fetchTemplates({ commit }): Promise<void> {
        commit("setLoading", {
            key: LOADING_STATES.ITEMS,
            value: true,
        });

        const items = await templatesService.getAll();

        commit("setTemplates", items);
        commit("setLoading", {
            key: LOADING_STATES.ITEMS,
            value: false,
        });
    },

    fetchTemplate(_, { id, typePath }: { id: number; typePath: string }): Promise<Template> {
        return templatesService.getById(id, typePath);
    },

    create(_, template: Template): Promise<Template> {
        return templatesService.create(transformTemplateBeforeSave(template));
    },

    edit(_, template: Template): Promise<Template> {
        return templatesService.edit(transformTemplateBeforeSave(template));
    },

    archive(_, template: Template): Promise<Template> {
        return templatesService.archive(template.id);
    },

    activate(_, template: Template): Promise<Template> {
        return templatesService.activate(template.id);
    },
};
