import { CreativeTypeLabels } from "@/modules/templates/strings";
import { VIDEO_TYPE } from "@/modules/videos/types";

export const creativeTypes = {
    [VIDEO_TYPE.CASINO]: {
        label: CreativeTypeLabels[VIDEO_TYPE.CASINO],
        icon: "Casino",
        description: "Dynamic Online Casino video",
    },
    [VIDEO_TYPE.SPORT]: {
        label: CreativeTypeLabels[VIDEO_TYPE.SPORT],
        icon: "Football",
        description: "Dynamic Sports video",
    },
};

export const videoIntervals = {
    [VIDEO_TYPE.CASINO]: [
        { label: "Never (Static)", value: 0 },
        { label: "15 min", value: 15 },
        { label: "30 min", value: 30 },
        { label: "60 min", value: 60 },
        { label: "2 hours", value: 120 },
        { label: "6 hours", value: 360 },
        { label: "12 hours", value: 720 },
        { label: "24 hours", value: 1440 },
    ],
    [VIDEO_TYPE.SPORT]: [
        { label: "Never (Static)", value: 0 },
        { label: "Live", value: 3 },
        { label: "15 min", value: 15 },
        { label: "30 min", value: 30 },
        { label: "60 min", value: 60 },
        { label: "2 hours", value: 120 },
        { label: "6 hours", value: 360 },
        { label: "12 hours", value: 720 },
        { label: "24 hours", value: 1440 },
    ],
};
