<template>
    <VCodeMirror
        ref="codemirror"
        v-model="value"
        class="codemirror"
        :options="cmOptions"
        v-bind="$attrs"
        @input="$emit('change', $event)"
        v-on="$listeners"
    />
</template>

<script>
import { codemirror as VCodeMirror } from "vue-codemirror";
import "codemirror/mode/css/css";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/xml/xml";
import "codemirror/lib/codemirror.css";

export default {
    name: "CodeMirror",
    components: {
        VCodeMirror,
    },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: String,
            default: "",
            required: true,
        },
        tabSize: {
            type: Number,
            default: 4,
        },
        mode: {
            type: String,
            default: "htmlmixed",
        },
        lineNumbers: {
            type: Boolean,
            default: true,
        },
        lineWrapping: {
            type: Boolean,
            default: true,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        cmOptions() {
            return {
                tabSize: this.tabSize,
                mode: this.mode,
                lineNumbers: this.lineNumbers,
                lineWrapping: this.lineWrapping,
            };
        },
    },
    watch: {
        isActive(active) {
            if (active) {
                this.$refs.codemirror?.refresh();
            }
        },
    },
};
</script>

<style scoped>
.codemirror {
    border: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
</style>
