<template>
    <div>{{ formattedDate }}</div>
</template>

<script>
import { USER_LOCALE } from "@/config";

const props = ["year", "month", "day", "hour", "minute", "dateStyle", "timeStyle"];

export default {
    name: "FormattedDate",
    props: {
        date: {
            type: String,
            default: null,
        },
        year: {
            type: [String, null],
            default: "numeric",
        },
        month: {
            type: [String, null],
            default: "2-digit",
        },
        day: {
            type: [String, null],
            default: "2-digit",
        },
        hour: {
            type: [String, null],
            default: "2-digit",
        },
        minute: {
            type: [String, null],
            default: "2-digit",
        },
        dateStyle: {
            type: [String, null],
            default: null,
        },
        timeStyle: {
            type: [String, null],
            default: null,
        },
    },
    computed: {
        formattedDate() {
            if (!this.date) {
                return "N/A";
            }

            const date = new Date(this.date);

            return new Intl.DateTimeFormat(USER_LOCALE, this.getFormatOptions()).format(date);
        },
    },
    methods: {
        getFormatOptions() {
            return props.reduce((acc, prop) => {
                if (this[prop]) {
                    acc[prop] = this[prop];
                }

                return acc;
            }, {});
        },
    },
};
</script>

<style scoped></style>
