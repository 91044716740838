import BUILD_STATUS from "@/types/buildStatus";

export function formatBuildStatus(status: BUILD_STATUS): string {
    switch (status) {
        case BUILD_STATUS.NONE:
            return "None";
        case BUILD_STATUS.PENDING:
            return "In progress";
        case BUILD_STATUS.DONE:
            return "Ready";
        case BUILD_STATUS.FAILED:
            return "Failed";
        default:
            return "";
    }
}

export function getBuildStatusColorClass(status: BUILD_STATUS): string {
    switch (status) {
        case BUILD_STATUS.PENDING:
            return "text-orange";
        case BUILD_STATUS.DONE:
            return "text-green";
        case BUILD_STATUS.FAILED:
            return "text-red";
        default:
            return "";
    }
}
