<template>
    <SrMenu v-model="optionsAreOpened" offset-y left min-width="100" :disabled="disabled">
        <template #activator="{ on, attrs }">
            <SrButton icon v-bind="attrs" :disabled="disabled" v-on="on">
                <SrIcon :active="optionsAreOpened" icon="more" size="xs" />
            </SrButton>
        </template>
        <SrList dense>
            <SrListItem
                v-for="route in actionRoutesAsArray"
                :key="route.routeName"
                :to="getRoute(route.routeName)"
                :disabled="route.disabled"
            >
                <SrListItemIcon>
                    <SrIcon :icon="route.icon" size="xs" />
                </SrListItemIcon>
                <SrListItemTitle>
                    {{ route.name }}
                </SrListItemTitle>
            </SrListItem>
        </SrList>
    </SrMenu>
</template>

<script>
import { SrButton, SrIcon, SrMenu, SrList, SrListItem, SrListItemTitle, SrListItemIcon } from "@ads/design-system";
import { creativeTypePaths } from "@/modules/templates/creativeTypePaths";

export default {
    components: { SrButton, SrIcon, SrMenu, SrList, SrListItem, SrListItemTitle, SrListItemIcon },
    props: {
        item: {
            type: Object,
            required: true,
        },
        actionRoutes: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            optionsAreOpened: false,
        };
    },
    computed: {
        actionRoutesAsArray() {
            return Object.values(this.actionRoutes);
        },
    },
    methods: {
        getRoute(path) {
            const { id, type, filterId } = this.item;
            return {
                name: path,
                params: { id, typePath: creativeTypePaths[type], filterId },
            };
        },
    },
};
</script>
