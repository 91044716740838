<template>
    <SrInput v-model="tournament.customerTournament" hide-details label="Tournament name" required />
</template>

<script>
import { SrInput } from "@ads/design-system";

export default {
    name: "TournamentMappingCustomerTournamentManualInput",
    components: {
        SrInput,
    },
    props: {
        tournament: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            srTournaments: [],
        };
    },
    computed: {
        displayedSrTournaments() {
            return this.srTournaments?.length ? this.srTournaments : this.defaultSrTournaments;
        },
    },
    watch: {
        "tournament.srTournament": function () {
            this.emitError(null);
        },
    },
    methods: {
        emitError(error) {
            this.$emit("error", error);
        },
    },
};
</script>

<style scoped></style>
