import actions from "@/modules/videos/store/actions";
import mutations from "@/modules/videos/store/mutations";
import state from "@/modules/videos/store/state";
import getters from "@/modules/videos/store/getters";

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
