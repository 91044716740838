import { templatesService } from "@/modules/templates/services/TemplatesService";
import MetadataService from "@/modules/templates/services/MetadataService";
import BUILD_STATUS from "@/types/buildStatus";
import store from "@/store";
import ExponentialRetriesPollingService from "@/modules/templates/services/ExponentialRetriesPollingService";

export const metadataService = new MetadataService();

// TODO
// Keep both services for a while until we are 100% sure that ExponentialRetriesPollingService works as intended
// After that remove PollingService

// export const pollingService = new PollingService(
//     templatesService.getById.bind(templatesService),
//     (item: { build: { status: BUILD_STATUS } }): boolean => {
//         if (item.build.status !== BUILD_STATUS.PENDING) {
//             store.commit("templates/replaceTemplate", item);
//             return true;
//         }

//         return false;
//     },
//     1000
// );

export const pollingService = new ExponentialRetriesPollingService(
    templatesService.getById.bind(templatesService),
    (item: { build: { status: BUILD_STATUS } }): boolean => {
        if (item.build.status !== BUILD_STATUS.PENDING) {
            store.commit("templates/replaceTemplate", item);
            return true;
        }

        return false;
    },
);
