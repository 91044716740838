<template>
    <section>
        <VideoForm
            v-model="video"
            submit-action="videos/edit"
            after-close-route="videos-root"
            after-submit-route="videos-root"
            :loading="isLoading"
            :after-submit-route-params="{
                editSuccess: {
                    name: video?.name,
                    productSetId: video?.productSetId,
                },
            }"
        />
        <RouterView :video="video" />
    </section>
</template>

<script>
import VideoForm from "@/modules/videos/components/VideoForm";

export default {
    name: "VideosEdit",
    components: { VideoForm },
    provide: {
        isEditing: true,
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            video: {
                id: null,
                name: "",
                businessEntity: null,
                file: null,
                layout: null,
                filter: null,
                type: null,
                presignedVideoUrl: null,
                presignedBackgroundImageUrl: null,
                templateId: null,
                productSetId: null,
            },
            isLoading: false,
        };
    },
    mounted() {
        this.fetchVideo();
    },
    methods: {
        setDefaultProperty(object, name, value) {
            if (!Number.isInteger(object[name])) {
                this.$set(object, name, value);
            }
        },
        async fetchVideo() {
            this.isLoading = true;
            const videosId = this.$route.params.id;
            const response = await this.$store.dispatch("videos/fetchVideo", videosId);
            this.video = response.video;
            // We have the background image url, but we do not use it yet since we do not have a preview of the image
            this.video.presignedBackgroundImageUrl = response.presignedBackgroundImageUrl;
            this.video.presignedVideoUrl = response.presignedVideoUrl;

            this.isLoading = false;
        },
    },
};
</script>
