<template>
    <div v-cloak class="file-upload" @drop.prevent="drop" @dragover.prevent="dragover" @dragleave.prevent="dragleave">
        <VFileInput
            ref="input"
            class="file-input"
            :value="file"
            hide-input
            hide-details
            :prepend-icon="null"
            :rules="validationRules"
            :accept="accept"
            @change="onChange"
        />
        <div v-if="label" class="label mb-2">
            <div class="label-text">{{ label }}</div>
            <div v-if="isRequired" class="required-indicator">*</div>
            <SrInfoBox v-if="hint" class="ml-2">
                {{ hint }}
            </SrInfoBox>
        </div>
        <div v-show="!file || !displayFile">
            <slot :trigger-upload="triggerUpload" :remove-file="removeFile" :has-file="Boolean(file)" :error="error">
                <SrButton class="upload-btn blue lighten-5" elevation="0" @click="triggerUpload">
                    {{ buttonLabel }}
                </SrButton>
            </slot>
        </div>
        <div v-if="file && displayFile" class="file">
            <SrIcon class="icon mt-1 mr-4" icon="ImageIcon" size="s" active />
            <div class="metadata">
                <div class="filename" :title="file.name">
                    {{ file.name }}
                </div>
                <div v-if="file.size" class="size">
                    {{ displayFileSize(file.size, displayUnit) }}
                </div>
            </div>
            <SrButton icon class="icon ml-1" @click="removeFile">
                <SrIcon icon="Trash" size="xs" />
            </SrButton>
        </div>
        <div v-show="error" class="mt-2 v-messages__message error--text v-messages">
            <span>{{ error }}</span>
        </div>
    </div>
</template>

<script>
import { SrButton, SrInfoBox, SrIcon } from "@ads/design-system";

export default {
    name: "FileUpload",
    components: {
        SrIcon,
        SrButton,
        SrInfoBox,
    },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        hint: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        buttonLabel: {
            type: String,
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        maxSize: {
            type: Number,
            default: 150000,
        },
        displayUnit: {
            type: String,
            default: "kB",
        },
        rules: {
            type: Array,
            default: () => [],
        },
        value: {
            type: File,
            default: null,
        },
        displayFile: {
            type: Boolean,
            default: true,
        },
        accept: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            file: this.value,
            error: null,
        };
    },
    computed: {
        validationRules() {
            const rules = [
                (f) =>
                    f === null ||
                    f.size < this.maxSize ||
                    `Size can't be more than ${this.displayFileSize(this.maxSize, this.displayUnit)}`,
            ];

            if (this.isRequired) {
                rules.unshift((f) => Boolean(f) || "File is required");
            }

            return rules.concat(this.rules);
        },
    },
    watch: {
        value(value) {
            this.file = value;
        },
    },
    methods: {
        emitFile(file) {
            this.$emit("change", file);
        },
        onChange(file) {
            const validationResult = this.validate(file);

            this.file = file;

            if (validationResult) {
                this.emitFile(file);
            }
        },
        validate(file) {
            for (const rule of this.validationRules) {
                const validationResult = rule(file);

                if (validationResult !== true) {
                    this.error = validationResult;
                    return false;
                }
            }

            this.error = null;
            return true;
        },
        triggerUpload() {
            this.$refs.input.$refs.input.click();
        },
        removeFile() {
            this.file = null;
            this.error = null;
            this.$refs.input.clearableCallback();
            this.emitFile(null);
            this.$emit("delete");
        },
        displayFileSize(size, unit) {
            const units = ["B", "kB", "MB", "GB"];
            const index = units.indexOf(unit);

            if (index === -1) {
                return `${size}B`;
            }

            if (index === 0) {
                return `${size} ${unit}`;
            }

            return `${(size / 1024 ** index).toFixed(2)} ${unit}`;
        },
        dragover(event) {
            if (!event.currentTarget.classList.contains("dnd-hover")) {
                event.currentTarget.classList.add("dnd-hover");
            }
        },
        dragleave(event) {
            event.currentTarget.classList.remove("dnd-hover");
        },
        drop(event) {
            this.$refs.input.onInput({ target: { files: event.dataTransfer.files } });
            event.currentTarget.classList.remove("dnd-hover");
        },
    },
};
</script>

<style lang="scss">
@import "~@ads/design-system/src/scss/variables";

.file-upload {
    position: relative;
    border: 1px dashed transparent;
    padding: 8px 4px;

    .file-input {
        padding: 0;
        margin: 0;
    }

    .required-indicator {
        color: $brand-red;
        margin-left: 4px;
    }

    .label {
        user-select: none;
        font-size: 14px;
        display: flex;
        align-items: center;
    }

    .upload-btn .v-btn__content {
        color: $blue;
    }

    &.dnd-hover {
        border: 1px dashed #b0d1e9;
        background-color: #d3ecff !important;
        border-radius: 4px;
    }
}

.file {
    display: flex;
    margin-top: 20px;

    .icon {
        flex-shrink: 0;
    }

    .metadata {
        flex-grow: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .filename {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .size {
        font-size: 12px;
        color: #9c9c9c;
        line-height: 13px;
    }
}
</style>
