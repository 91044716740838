<template>
    <VForm ref="form" v-model="isFormValid">
        <VRow no-gutters>
            <VCol sm="11" lg="10">
                <SrTabs :show-arrows="false">
                    <VTab class="flex-grow"> Layout </VTab>
                    <VTab class="flex-grow"> Style </VTab>
                    <VTabItem :transition="false">
                        <SrSelect
                            v-model="template.size"
                            class="mt-1"
                            label="Template Size"
                            :items="sizes"
                            item-value="id"
                            item-text="label"
                            :required="!isCustomHtml"
                            dense
                            :disabled="isEditing"
                            @change="onSizeChange"
                        />
                        <SrHeadline level="3">Images</SrHeadline>
                        <FileUpload
                            v-model="images.logoImage"
                            label="Upload a Logo Image"
                            :accept="acceptImageFormats"
                            :is-required="!isCustomHtml"
                            @change="onImageChange($event, 'logoImage')"
                        >
                            <template #default="{ triggerUpload }">
                                <SrButton type="secondary" elevation="0" @click="triggerUpload"> Upload Image </SrButton>
                            </template>
                        </FileUpload>
                        <FileUpload
                            v-if="template.type === creativeType.SPORT"
                            v-model="images.backgroundImage"
                            class="mt-6"
                            label="or upload a Background Image"
                            button-label="Upload Image"
                            max-size="1500000"
                            :accept="acceptImageFormats"
                            @change="onImageChange($event, 'backgroundImage')"
                        />
                        <template v-if="template.type === creativeType.CASINO">
                            <FileUpload
                                v-model="images.brandedImage"
                                class="mt-6"
                                label="Branded Image or Video"
                                button-label="Upload Image or Video"
                                :max-size="3450000"
                                :accept="acceptBackgroundFormats"
                                @change="onImageChange($event, 'brandedImage')"
                            />
                            <div class="description">
                                or select image from the
                                <a href="#" @click.prevent="openDefaultLibraryModal">Default Library</a>
                            </div>
                            <SrNotification
                                v-if="brandedVideoWarning"
                                type="warning"
                                :title="brandedVideoWarning"
                                @close="onNotificationClose"
                            />
                        </template>
                        <div class="divider-b mt-6 mb-5" />
                        <template v-if="showCompleteFeatures && template.type === creativeType.SPORT">
                            <SrHeadline level="3" class="m-0">Layout Configuration</SrHeadline>
                            <SrHeadline level="3" class="mb-1 label">Logo</SrHeadline>
                            <VRadioGroup v-model="template.config.logoPosition" hide-details class="mt-0 mb-1">
                                <SrRadioButton label="Top" :value="layoutPosition.TOP" />
                                <SrRadioButton label="Bottom" :value="layoutPosition.BOTTOM" />
                            </VRadioGroup>
                            <SrHeadline level="3" class="mb-1 mt-6 label">Offer lines</SrHeadline>
                            <VRadioGroup v-model="template.config.offerLinesPosition" hide-details class="mt-0">
                                <SrRadioButton label="Top" :value="layoutPosition.TOP" />
                                <SrRadioButton label="Bottom" :value="layoutPosition.BOTTOM" />
                            </VRadioGroup>
                            <SrHeadline level="3" class="mb-1 mt-6 label">Team's branding</SrHeadline>
                            <VRadioGroup v-model="template.config.teamsFallback" hide-details class="mt-0">
                                <SrRadioButton label="Show team logos" :value="teamImageType.LOGO" />
                                <SrRadioButton label="Show team custom badges" :value="teamImageType.BADGE" />
                                <SrRadioButton label="Show team jerseys" :value="teamImageType.JERSEY" />
                                <SrRadioButton label="Team names only" :value="teamImageType.NONE" />
                            </VRadioGroup>
                            <SrCheckbox v-model="template.config.canUseTeamColors" class="mt-3" label="Use team colors" />
                            <div class="divider-b mt-2 mb-5" />
                        </template>
                    </VTabItem>
                    <VTabItem :transition="false">
                        <SrHeadline level="3" class="m-0">Colors</SrHeadline>
                        <SrColorPicker
                            v-if="template.type === creativeType.CASINO"
                            v-model="template.config.backgroundColor"
                            class="mt-4"
                            label="Background Color"
                            mode="rgba"
                            use-alpha
                            :width="240"
                        />
                        <template v-if="template.type === creativeType.SPORT">
                            <SrColorPicker
                                v-model="template.config.backgroundColor"
                                class="mt-4"
                                label="Background Color"
                                mode="rgba"
                                use-alpha
                                :width="240"
                            />
                        </template>
                        <SrColorPicker
                            v-model="template.config.primaryColor"
                            class="mt-4"
                            label="CTA Button Color"
                            mode="rgba"
                            use-alpha
                            :width="240"
                        />
                        <SrColorPicker
                            v-model="template.config.ctaTextColor"
                            class="mt-4"
                            label="CTA Text Color"
                            mode="rgba"
                            use-alpha
                            :width="240"
                        />
                        <template v-if="template.type === creativeType.CASINO">
                            <SrColorPicker
                                v-model="template.config.tertiaryColor"
                                class="mt-4"
                                label="Offer Line 1"
                                mode="rgba"
                                use-alpha
                                :width="240"
                            />
                            <SrColorPicker
                                v-model="template.config.quaternaryColor"
                                class="mt-4"
                                label="Offer Line 2"
                                mode="rgba"
                                use-alpha
                                :width="240"
                            />
                            <SrColorPicker
                                v-model="template.config.secondaryColor"
                                class="mt-4"
                                label="Legal Text Color"
                                mode="rgba"
                                use-alpha
                                :width="240"
                            />
                        </template>
                        <template v-if="template.type === creativeType.SPORT">
                            <SrColorPicker
                                v-model="template.config.secondaryColor"
                                class="mt-4"
                                label="Text Color"
                                mode="rgba"
                                use-alpha
                                :width="240"
                            />
                            <SrColorPicker
                                v-model="template.config.tertiaryColor"
                                class="mt-4"
                                label="Odds Boxes Color"
                                mode="rgba"
                                use-alpha
                                :width="240"
                            />
                            <SrColorPicker
                                v-model="template.config.oddsTextColor"
                                class="mt-4"
                                label="Odds Boxes Text Color"
                                mode="rgba"
                                use-alpha
                                :width="240"
                            />
                        </template>
                        <div class="divider-b mt-6 mb-5" />
                        <SrHeadline level="3" class="m-0">Font</SrHeadline>
                        <SrSelect
                            v-model="template.config.font"
                            class="mt-4"
                            label="Font Face"
                            :items="fonts"
                            item-value="id"
                            item-text="name"
                            required
                            searchable
                        />
                        <div class="divider-b mt-6 mb-5" />
                        <SrHeadline level="3" class="m-0">Custom CSS</SrHeadline>
                        <CodeMirror v-model="template.config.customCss" mode="css" />
                    </VTabItem>
                </SrTabs>
            </VCol>
        </VRow>
        <DefaultLibraryModal
            :template="template"
            :size="size"
            :show="showDefaultLibraryModal"
            @close="onDefaultLibraryClose"
            @save="onDefaultLibrarySave($event, 'brandedImage')"
        />
    </VForm>
</template>

<script>
import {
    SrSelect,
    SrButton,
    SrHeadline,
    SrColorPicker,
    SrRadioButton,
    SrCheckbox,
    SrTabs,
    SrNotification,
} from "@ads/design-system";
import FileUpload from "@/components/FileUpload";
import DefaultLibraryModal from "@/modules/templates/components/DefaultLibraryModal";
import { mapGetters } from "vuex";
import { CREATIVE_TYPE } from "@/modules/templates/types";
import {
    LAYOUT_POSITION,
    TEAM_IMAGE_TYPE,
} from "@/modules/templates/components/TemplateForm/TemplateFormStepCustomization/types";
import { formatSizes } from "@/modules/templates/strings";
import CodeMirror from "@/components/CodeMirror";
import { sizesWithoutTeamsFeatures } from "../../config";

export default {
    name: "CustomizationTemplateStyle",
    components: {
        CodeMirror,
        SrButton,
        SrSelect,
        SrHeadline,
        SrColorPicker,
        FileUpload,
        DefaultLibraryModal,
        SrRadioButton,
        SrCheckbox,
        SrTabs,
        SrNotification,
    },
    inject: {
        isDuplication: {
            default: false,
        },
        isEditing: {
            default: false,
        },
    },
    props: {
        template: {
            type: Object,
            default: () => ({}),
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            layoutPosition: LAYOUT_POSITION,
            teamImageType: TEAM_IMAGE_TYPE,
            creativeType: CREATIVE_TYPE,
            selectedFont: null,
            isFormValid: this.isValid,
            images: {
                fallbackImage: null,
                backgroundImage: null,
                logoImage: null,
                brandedImage: null,
                preRollImage: null,
                postRollImage: null,
            },
            acceptImageFormats: "image/webp,image/png,image/svg+xml,image/gif,image/jpeg",
            acceptBackgroundFormats: "image/webp,image/png,image/svg+xml,image/gif,image/jpeg,video/mp4",
            showDefaultLibraryModal: false,
            sizesForSelect: [],
            showCompleteFeatures: false,
            brandedVideoWarning: null,
        };
    },
    computed: {
        ...mapGetters({
            fonts: "templates/fonts",
            getLayoutById: "templates/layoutById",
        }),
        sizes() {
            if (!this.template.layout) {
                return [];
            }

            return formatSizes(this.template.layout.sizes) || [];
        },
        isRollScreensAllowed() {
            return this.isDynamicLayout || this.isFrenchRivieraLayout || this.template.type === this.creativeType.CASINO;
        },
        isCustomHtml() {
            return this.template.layout?.id === 5;
        },
    },
    watch: {
        "template.size": function () {
            this.$emit("update:template", this.template);
            this.updateShowCompleteFeatures();
        },
        "template.layout": function () {
            this.$emit("update:template", this.template);
            this.updateShowCompleteFeatures();
        },
        "template.config": {
            deep: true,
            handler() {
                this.$emit("update:template", this.template);
            },
        },
        "template.id": function () {
            this.setMockImageFiles();
        },
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
    },
    created() {
        this.setMockImageFiles();

        this.updateShowCompleteFeatures();
    },
    methods: {
        updateShowCompleteFeatures() {
            const sizeId = typeof this.template.size === "number" ? this.template.size : this.template.size?.id;

            if (
                this.template.layout?.id &&
                sizeId &&
                // eslint-disable-next-line no-unsafe-optional-chaining
                !sizesWithoutTeamsFeatures[this.template.layout?.id - 1]?.includes(sizeId - 1)
            ) {
                this.showCompleteFeatures = true;
            } else {
                this.showCompleteFeatures = false;
            }
        },
        setMockImageFiles() {
            if (this.isDuplication || this.isEditing) {
                const config = this.template.config;

                Object.keys(this.images).forEach((key) => {
                    if (config[key]?.value) {
                        this.$set(this.images, key, this.createMockImage(`${key}.jpg`));
                    }
                });

                if (!config.brandedImage?.value && config.brandedImageUrl) {
                    this.$set(this.images, "brandedImage", this.createMockImage("brandedVideo.mp4"));
                }
            }
        },
        createMockImage(name) {
            return new File([], name, { type: "mock" });
        },
        onImageChange(file, name) {
            const extension = file.name.split(".")[1];
            if (extension === "mp4") {
                this.brandedVideoWarning = "Video will be available for preview after the template is created";
            }
            this.$emit("image-change", { file, name });
        },
        onDefaultLibrarySave(value, name) {
            this.$set(this.images, name, this.createMockImage(`${name}.jpg`));
            this.setImage(value, name);
            this.closeDefaultLibraryModal();
        },
        onSizeChange() {
            if (this.isDuplication) {
                this.$emit("size-change");
            }
        },
        openDefaultLibraryModal() {
            this.showDefaultLibraryModal = true;
        },
        closeDefaultLibraryModal() {
            this.showDefaultLibraryModal = false;
        },
        onDefaultLibraryClose() {
            this.closeDefaultLibraryModal();
        },
        onNotificationClose() {
            this.brandedVideoWarning = null;
        },
    },
};
</script>

<style lang="scss">
.description {
    font-size: 14px;
    margin-top: 14px;
}

.label {
    user-select: none;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.flex-grow {
    flex-grow: 1;
}

.v-item-group.v-slide-group.v-tabs-bar {
    margin: 0 12px 28px;
}
</style>
