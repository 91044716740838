import { API_URL } from "@/config";

export default function isProduction(): boolean {
    if (!API_URL) {
        return false;
    }

    const apiUrl = new URL(API_URL);

    return Boolean(apiUrl.pathname.endsWith("/prod"));
}
