<template>
    <SrModalPageForm
        :show.sync="show"
        class="modal-form stepper-form"
        :steps="stepMetaData"
        :loading="isLoading"
        :show-submit-button="userPermittedToCreateTemplates || userPermittedToEditTemplates"
        disable-keyboard-navigation
        :value="currentStep"
        @close="close"
        @submit="submit"
        @input="onStepChange"
    >
        <SrModalPageStep v-for="(step, index) in steps" :key="index" class="mb-4" :step="index + 1" :title="step.title">
            <keep-alive>
                <component
                    :is="step.component"
                    v-if="template"
                    :is-valid.sync="step.isValid"
                    :template="template"
                    :is-loading="isLoading"
                    :is-active="currentStep === index + 1"
                />
            </keep-alive>
        </SrModalPageStep>

        <template #footer>
            <ErrorBox :show="showErrorMessage" :message="error" />
        </template>
    </SrModalPageForm>
</template>

<script>
import { createActionValidator, SrModalPageForm, SrModalPageStep } from "@ads/design-system";
import BasicForm from "@/components/BasicForm";
import ErrorBox from "@/components/ErrorBox";
import { mapActions, mapGetters } from "vuex";
import { LOADING_STATES as CREATIVE_LOADING_STATES } from "@/modules/templates/store/state";
import { LOADING_STATES } from "@/modules/iam/store/state";
import { ACTION_NAME } from "@ads/iam-library";
import TemplateFormStepBasics from "./TemplateFormStepBasics";
import TemplateFormStepCustomization from "./TemplateFormStepCustomization";
import TemplateFormStepLayoutLibrary from "./TemplateFormStepLayoutLibrary";
import { formSteps } from "./config";

export default {
    name: "TemplateForm",
    components: {
        SrModalPageForm,
        SrModalPageStep,
        ErrorBox,
        TemplateFormStepBasics,
        TemplateFormStepLayoutLibrary,
        TemplateFormStepCustomization,
    },
    extends: BasicForm,
    data() {
        return {
            show: true,
            template: this.value,
            steps: formSteps,
            error: null,
            currentStep: 1,
            userPermittedToCreateTemplates: true,
            userPermittedToEditTemplates: true,
        };
    },
    computed: {
        ...mapGetters({
            iamLoadingStates: "iam/loadingStates",
            creativesLoadingStates: "templates/loadingStates",
            layouts: "templates/layouts",
        }),
        isLoading() {
            return (
                this.creativesLoadingStates(CREATIVE_LOADING_STATES.CREATE_CREATIVE) ||
                this.iamLoadingStates(LOADING_STATES.BUSINESS_ENTITIES) ||
                this.loading
            );
        },
        stepMetaData() {
            return this.steps.map((step) => ({
                name: step.name,
                canContinue: step.isValid,
            }));
        },
        showErrorMessage() {
            return Boolean(this.error);
        },
    },
    watch: {
        template(template) {
            this.$emit("input", template);
        },
        value(template) {
            this.template = template;
        },
        "template.layout": function () {
            this.setDefaultSize();
        },
    },
    async created() {
        await this.canCreateOrEdit();
        await this.fetchAdvertisers();
        await this.fetchTemplateLayouts();
    },
    methods: {
        ...mapActions({
            fetchBusinessEntities: "iam/fetchBusinessEntities",
            fetchLayouts: "templates/fetchLayouts",
        }),
        async canCreateOrEdit() {
            const actionValidator = await createActionValidator(this.$root.user);

            this.userPermittedToCreateTemplates = await actionValidator.validateAction({
                actionName: ACTION_NAME.CREATE,
                domainName: "dynamic-display",
                resourceType: "creative",
                userId: this.$root.user.getCognitoId(),
            });

            this.userPermittedToEditTemplates = await actionValidator.validateAction({
                actionName: ACTION_NAME.EDIT,
                domainName: "dynamic-display",
                resourceType: "creative",
                userId: this.$root.user.getCognitoId(),
            });
        },
        async fetchAdvertisers() {
            try {
                await this.fetchBusinessEntities();
            } catch (error) {
                this.error = error.message || error.response.message;
            }
        },
        async fetchTemplateLayouts() {
            try {
                await this.fetchLayouts();

                if (this.template && !this.template.layout) {
                    this.template.layout = this.layouts.find((layout) => layout.type === this.template.type);
                }
            } catch (error) {
                this.error = error.message || error.response.message;
            }
        },
        setDefaultSize() {
            const sideId = this.template.size?.id || this.template.size;

            if (this.template.layout && !this.template.layout.sizes.find((size) => size.id === sideId)) {
                this.$set(this.template, "size", this.template.layout.sizes[0]?.id);
            }
        },
        async save() {
            return this.$store.dispatch(this.submitAction, this.template);
        },
        onStepChange(currentStep) {
            this.currentStep = currentStep;
        },
    },
};
</script>

<style lang="scss">
.stepper-form {
    .v-stepper__header {
        max-width: 400px;
    }
}
</style>
