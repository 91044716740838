import { recursive as mergeRecursive } from "merge";
import Filter, { FilterDate } from "@/modules/filters/types/filter";
import BusinessEntity from "@/types/businessEntity";

export const formatBusinessEntityId = (filter: Filter, businessEntity: BusinessEntity): Filter => {
    if (typeof businessEntity === "object" && businessEntity !== null) {
        filter.businessEntity = businessEntity.dspId as unknown as BusinessEntity;
    }

    return filter;
};

export const formatDaysAhead = (filter: Filter, filterDate: FilterDate): Filter => {
    if (filterDate.daysAhead === 0) {
        filter.filterDate.daysAhead = null;
    }

    return filter;
};

export const formatCalendarWeeksAhead = (filter: Filter, filterDate: FilterDate): Filter => {
    if (filterDate.calendarWeeksAhead === 0) {
        filter.filterDate.calendarWeeksAhead = null;
    }

    return filter;
};

const transformFilterBeforeSave = (filter: Filter): Filter => {
    const { businessEntity, filterDate } = filter;
    let filterTransformed = mergeRecursive(true, filter);

    filterTransformed = formatBusinessEntityId(filterTransformed, businessEntity);
    filterTransformed = formatDaysAhead(filterTransformed, filterDate);
    filterTransformed = formatCalendarWeeksAhead(filterTransformed, filterDate);

    return filterTransformed;
};

export default transformFilterBeforeSave;
