import { LOADING_STATES } from "@/modules/templates/store/state";
import BusinessEntity from "@/types/businessEntity";
import { TEMPLATE_STATUS } from "@/types/templateStatus";

export enum MatchType {
    ANY = "ANY",
    PREMATCH = "PREMATCH",
    LIVE = "LIVE",
}

export enum CREATIVE_TYPE {
    SPORT = "SPORT",
    CASINO = "CASINO",
}

export enum EVENT_VERSION_KEYS {
    V1 = "V1",
    V2 = "V2",
    V2F8 = "V2F8",
}

export type Image = { value: string | null };

export type Font = { id: number; name: string; order: number };

export interface Layout {
    id: number;
    name: string;
    category: string;
    image: string;
    sizes: Size[];
}

export interface Size {
    id: number;
    width: number;
    height: number;
}

export interface Build {
    id: number;
    status: string;
    link: string;
    createdAt: Date;
    modifiedAt: Date;
}

export interface CasinoConfig {
    id: number;
    legalText: string;
    offerLine1: string;
    offerLine2: string;
    ctaText: string;
    fallbackUrl: string;
    category: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transformation: Record<string, any> | string;
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    quaternaryColor: string;
    ctaTextColor: string;
    backgroundColor: string;
    logoImage: Image;
    brandedImage: Image;
    fallbackImage: Image;
    font: number | Font;
    logoPlacement: string;
    offerLine: string;
    preRollImage: Image;
    useCustomColorsOrBadges: boolean;
    useCustomLogosOrTeamnames: boolean;
    customCss: string;
    customHtml: string;
    brandedImageUrl: string;
}

export interface SportConfig {
    id: number;
    legalText: string;
    offerLine1: string;
    offerLine2: string;
    ctaText: string;
    fallbackUrl: string;
    hasGeoTargeting: boolean;
    timeStatus: string;
    primaryProductSetId?: string;
    tournament: string;
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    ctaTextColor: string;
    oddsTextColor: string;
    backgroundColor: string;
    logoImage: Image;
    backgroundImage: Image;
    fallbackImage: Image;
    font: number | Font;
    filterId: string;
    sportId: string;
    logoPosition: string;
    offerLinesPosition: string;
    preRollImage: Image;
    postRollImage: Image;
    teamsFallback: string;
    canUseTeamColors: boolean;
    customCss: string;
    oddsType: string;
    customHtml: string;
    oddsFormat: string;
}

export interface Template {
    id: number;
    name: string;
    size: number | Size;
    businessEntity: number | BusinessEntity;
    layout: number | Layout;
    createdAt: Date;
    modifiedAt: Date;
    build?: Build;
    config: CasinoConfig | SportConfig;
    type: CREATIVE_TYPE;
    status: TEMPLATE_STATUS;
}

export interface TemplatesState {
    items: Template[];
    fonts: Font[];
    layouts: Layout[];
    loadingStates: {
        [key in LOADING_STATES]: boolean;
    };
}

export interface ProductSet {
    consoleProductSetId: string;
    consoleProductSetName: string;
}

export interface ProductSetApiResponse {
    error: string;
    productSets: ProductSet[];
}

export interface DefaultImage {
    id: number;
    value: string;
    size: Size;
}
