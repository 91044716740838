<template>
    <SrModalPageForm
        :show.sync="show"
        class="modal-form stepper-form"
        :steps="stepMetaData"
        :loading="isLoading"
        :show-submit-button="userPermittedToCreateFilters || userPermittedToEditFilters"
        @close="close"
        @submit="submit"
        @input="onStepChange"
    >
        <SrModalPageStep v-for="(step, index) in steps" :key="index" class="mb-4" :step="index + 1" :title="step.title">
            <keep-alive>
                <component
                    :is="step.component"
                    v-if="filter"
                    :is-valid.sync="step.isValid"
                    :filter="filter"
                    :is-loading="isLoading"
                />
            </keep-alive>
        </SrModalPageStep>

        <template #footer> <ErrorBox :show="showErrorMessage" :message="error" /> </template>
    </SrModalPageForm>
</template>

<script>
import { createActionValidator, SrModalPageForm, SrModalPageStep } from "@ads/design-system";
import BasicForm from "@/components/BasicForm";
import ErrorBox from "@/components/ErrorBox";
import { mapActions, mapGetters } from "vuex";
import { LOADING_STATES } from "@/modules/filters/types/filtersState";
import { LOADING_STATES as IAM_LOADING_STATES } from "@/modules/iam/store/state";
import { ACTION_NAME } from "@ads/iam-library";
import { formSteps } from "./config";
import FilterFormStepRuntime from "./FilterFormStepRuntime";
import FilterFormStepBasics from "./FilterFormStepBasics";

export default {
    name: "FilterForm",
    components: {
        SrModalPageForm,
        SrModalPageStep,
        ErrorBox,
        FilterFormStepBasics,
        FilterFormStepRuntime,
    },
    extends: BasicForm,
    data() {
        return {
            show: true,
            filter: this.value,
            steps: formSteps,
            error: null,
            currentStep: 1,
            userPermittedToCreateFilters: true,
            userPermittedToEditFilters: true,
        };
    },
    computed: {
        ...mapGetters({
            iamLoadingStates: "iam/loadingStates",
            loadingStates: "filters/loadingStates",
        }),
        isLoading() {
            return (
                this.loadingStates(LOADING_STATES.CREATE) ||
                this.iamLoadingStates(IAM_LOADING_STATES.BUSINESS_ENTITIES) ||
                this.loading
            );
        },
        stepMetaData() {
            return this.steps.map((step) => ({
                name: step.name,
                canContinue: step.isValid,
            }));
        },
        showErrorMessage() {
            return Boolean(this.error);
        },
    },
    watch: {
        filter(filter) {
            this.$emit("input", filter);
        },
        value(filter) {
            this.filter = filter;
        },
    },
    async created() {
        await this.canCreateOrEdit();
        this.fetchAdvertisers();
    },
    methods: {
        ...mapActions({
            fetchBusinessEntities: "iam/fetchBusinessEntities",
        }),
        async canCreateOrEdit() {
            const actionValidator = await createActionValidator(this.$root.user);

            this.userPermittedToCreateFilters = await actionValidator.validateAction({
                actionName: ACTION_NAME.CREATE,
                domainName: "dynamic-display",
                resourceType: "filter",
                userId: this.$root.user.getCognitoId(),
            });

            this.userPermittedToEditFilters = await actionValidator.validateAction({
                actionName: ACTION_NAME.EDIT,
                domainName: "dynamic-display",
                resourceType: "filter",
                userId: this.$root.user.getCognitoId(),
            });
        },
        async fetchAdvertisers() {
            try {
                await this.fetchBusinessEntities();
            } catch (error) {
                this.error = error.message || error.response.message;
            }
        },
        async save() {
            return this.$store.dispatch(this.submitAction, this.filter);
        },
        onStepChange(currentStep) {
            this.currentStep = currentStep;
        },
    },
};
</script>

<style lang="scss" scoped>
.stepper-form {
    .v-stepper__header {
        max-width: 400px;
    }
}
</style>
