<template>
    <VideosDeleteModal :video-id="id" @cancel="onDeleteCompletedOrCancelled" @completed="onDeleteCompletedOrCancelled" />
</template>

<script>
import VideosDeleteModal from "@/modules/videos/views/delete/components/VideosDeleteModal.vue";

export default {
    name: "VideosDelete",
    components: { VideosDeleteModal },
    data() {
        return {
            id: null,
        };
    },
    created() {
        this.id = this.$route.params.id;
    },
    methods: {
        onDeleteCompletedOrCancelled() {
            this.$router.back();
        },
    },
};
</script>
