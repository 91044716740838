import TEMPLATE_STATUS from "@/types/templateStatus";

export function formatStatus(status: TEMPLATE_STATUS): string {
    switch (status) {
        case TEMPLATE_STATUS.ACTIVE:
            return "Active";
        case TEMPLATE_STATUS.ARCHIVED:
            return "Archived";
        default:
            return "";
    }
}

export function getStatusColorClass(status: TEMPLATE_STATUS): string {
    switch (status) {
        case TEMPLATE_STATUS.ACTIVE:
            return "";
        case TEMPLATE_STATUS.ARCHIVED:
            return "text-gray";
        default:
            return "";
    }
}
