<template>
    <div>
        <SrHeadline level="3" class="mb-6" weight="bold"> UTM Tracking</SrHeadline>
        <VRow>
            <VCol sm="12" lg="8">
                <VRow>
                    <VCol v-for="field in fields" :key="field.name" sm="12" lg="6">
                        <SrInput
                            ref="fields"
                            v-model="template.config[field.name]"
                            :label="field.label"
                            :required="field.required"
                        />
                    </VCol>
                </VRow>
            </VCol>
        </VRow>
    </div>
</template>
<script>
import { SrHeadline, SrInput } from "@ads/design-system";
import { UTM_TRACKING_FIELDS } from "@/modules/templates/components/TemplateForm/TemplateFormStepBasics/config";

export default {
    name: "UtmTrackingConfiguration",
    components: { SrInput, SrHeadline },
    props: {
        template: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        fields() {
            const config = this.template.config;
            const fieldsFilled = UTM_TRACKING_FIELDS.map((field) => Boolean(config[field.name]?.length));
            const lastFilledIndex = fieldsFilled.lastIndexOf(true);

            return UTM_TRACKING_FIELDS.map((field, index) => ({
                ...field,
                required: index < lastFilledIndex,
            }));
        },
    },
    watch: {
        "template.config": {
            handler() {
                this.$emit("update:template", this.template);
            },
        },
        fields() {
            this.$emit("revalidation");
        },
    },
};
</script>
