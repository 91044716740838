export enum METADATA_TYPES {
    CASINO_CATEGORY = "casino_category",
    SPORT_LANGUAGES = "sport_languages",
}

export interface MetadataRequest {
    metadata_categories: METADATA_TYPES[];
    affiliate_id: string;
}

export interface MetadataRequestOptions {
    types: METADATA_TYPES[];
    affiliateId: number;
}

export interface MetadataResponse {
    invalid_metadata_categories: string[];
    metadata_categories: Record<METADATA_TYPES, string[]>;
}
