import { ACTION_NAME, IRequestOptions, RequestService } from "@ads/iam-library";
import { Font, DefaultImage, ProductSet, ProductSetApiResponse, Size, Template } from "@/modules/templates/types";
import { API_URL } from "@/config";
import { creativeTypePaths } from "@/modules/templates/creativeTypePaths";
import URLPathBuilder from "@/services/URLPathBuilder";

export default class TemplatesService {
    private readonly defaultRequestOptions: Pick<IRequestOptions, "domain" | "resource"> = {
        domain: "dynamic-display",
        resource: "creative",
    };

    private readonly requestService: RequestService;

    private readonly domainPath: string;

    private readonly fontsPath = "fonts";

    private readonly productSetsPath = "product-sets";

    private readonly defaultImagesPath = "default-images";

    private readonly typePaths: { [type: string]: string } = creativeTypePaths;

    constructor(requestService: RequestService, domainPath?: string) {
        this.requestService = requestService;
        this.domainPath = domainPath;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return {
            ...this.defaultRequestOptions,
            ...options,
        } as IRequestOptions;
    }

    async getAvailableFonts(): Promise<Font[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/${this.fontsPath}`, requestOptions);
    }

    async getProductSets(f8OperatorStrings: string[]): Promise<ProductSet[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });
        const response: ProductSetApiResponse = await this.requestService.post(
            `/${this.productSetsPath}`,
            { f8OperatorStrings },
            requestOptions,
        );

        if (response.error) {
            throw new Error(response.error);
        }

        return response.productSets;
    }

    async getDefaultImages(size: Size): Promise<DefaultImage[]> {
        const { width, height } = size;
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        const urlPathBuilder = new URLPathBuilder(`/${this.defaultImagesPath}`);
        const urlPath = urlPathBuilder.setParam("width", width).setParam("height", height).build();

        return this.requestService.get(urlPath, requestOptions);
    }

    async getById(id: number, typePath: string): Promise<Template> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/${this.domainPath}/${typePath}/${id}`, requestOptions);
    }

    async getAll(): Promise<Template[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/${this.domainPath}`, requestOptions);
    }

    async create(template: Template): Promise<Template> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.CREATE });
        const { type } = template;

        return this.requestService.post(`/${this.domainPath}/${this.typePaths[type]}`, template, requestOptions);
    }

    async edit(template: Template): Promise<Template> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });
        const { type } = template;

        return this.requestService.put(`/${this.domainPath}/${this.typePaths[type]}`, template, options);
    }

    async archive(templateId: number): Promise<Template> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });
        const payload = {
            id: templateId,
        };
        return this.requestService.put(`/${this.domainPath}/archive`, payload, options);
    }

    async activate(templateId: number): Promise<Template> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });
        const payload = {
            id: templateId,
        };
        return this.requestService.put(`/${this.domainPath}/activate`, payload, options);
    }
}

export const templatesService = new TemplatesService(new RequestService({ baseUrl: API_URL }), "creatives");
