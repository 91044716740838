import { RouteConfig } from "vue-router";
import Tournaments from "@/modules/tournaments/views";
import TournamentsMapping from "@/modules/tournaments/views/mapping";

const routes: RouteConfig[] = [
    {
        name: "tournaments-root",
        path: "/tournaments",
        component: Tournaments,
        redirect: { name: "tournaments-mapping" },
        children: [
            {
                name: "tournaments-mapping",
                path: "mapping",
                component: TournamentsMapping,
            },
        ],
    },
];

export default routes;
