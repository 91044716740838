<template>
    <div>
        <VRow v-for="tournament in suggestedMappings" :key="tournament.key">
            <VCol sm="12" lg="8" xl="6" class="mt-2">
                <TournamentMappingRow
                    :tournament="tournament"
                    :customer-tournaments="customerTournaments"
                    :sr-tournaments="srTournaments"
                >
                    <template #action>
                        <SrButton
                            color="transparent"
                            elevation="0"
                            type="secondary"
                            class="light-blue mt-2"
                            @click="confirm(tournament)"
                        >
                            Confirm
                        </SrButton>
                    </template>
                </TournamentMappingRow>
            </VCol>
        </VRow>
    </div>
</template>
<script>
import { SrButton } from "@ads/design-system";
import TournamentMappingRow from "./TournamentMappingRow.vue";

export default {
    name: "TournamentsMappingAutomatedSuggestions",
    components: { TournamentMappingRow, SrButton },
    props: {
        customerTournaments: {
            type: Array,
            required: true,
        },
        srTournaments: {
            type: Array,
            required: true,
        },
        suggestedMappings: {
            type: Array,
            required: true,
        },
    },
    methods: {
        confirm(tournament) {
            this.$emit("confirm", tournament);
        },
    },
};
</script>
