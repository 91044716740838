<template>
    <section>
        <TemplateForm
            v-model="template"
            submit-action="templates/edit"
            after-close-route="templates-root"
            after-submit-route="templates-root"
            :after-submit-route-params="{
                updateSuccess: {
                    name: templateName,
                    hasPixels: template?.businessEntity?.pixels.length > 0,
                },
            }"
            :loading="isLoading"
        />
        <RouterView :template="template" />
    </section>
</template>

<script>
import TemplateForm from "@/modules/templates/components/TemplateForm";
import { getDefaultConfig } from "@/modules/templates/components/TemplateForm/config";
import { CREATIVE_TYPE } from "@/modules/templates/types";

export default {
    name: "TemplatesEdit",
    components: { TemplateForm },
    provide: {
        isEditing: true,
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            template: {
                config: {
                    ...getDefaultConfig(),
                },
                type: CREATIVE_TYPE.SPORT,
            },
            isLoading: false,
        };
    },
    computed: {
        templateName() {
            return this.template?.name;
        },
    },
    mounted() {
        this.fetchTemplate();
    },
    methods: {
        async fetchTemplate() {
            this.isLoading = true;
            const id = Number(this.$route.params.id);
            const typePath = String(this.$route.params.typePath);

            try {
                const template = await this.$store.dispatch("templates/fetchTemplate", {
                    id,
                    typePath,
                });

                delete template.build;

                if (typeof template.config.transformation === "string") {
                    template.config.transformation = JSON.parse(template.config.transformation);
                }

                this.template = template;
            } catch (error) {
                this.error = error.message || error.response.message;
            }

            this.isLoading = false;
        },
    },
};
</script>
