import { Font } from "@/modules/templates/types";
import { TemplateImagesConfiguration, TemplateVariablesConfiguration } from "./TemplateConfiguration.types";
import { getFontImport } from "./Fonts";

export default class StylesConfiguration {
    private static readonly variablesMap = {
        backgroundColor: "--bg-color",
        primaryColor: "--primary-color",
        secondaryColor: "--secondary-color",
        tertiaryColor: "--tertiary-color",
        quaternaryColor: "--quaternary-color",
        ctaTextColor: "--cta-text-color",
        oddsTextColor: "--odds-text-color",
        font: "--font",
    };

    private static readonly imagesVariablesMap = {
        backgroundImage: "--bg-image",
        brandedImage: "--bg-image",
        fallbackImage: "--fallback-image",
        logoImage: "--logo-image",
        postRollImage: "--post-roll-image",
        preRollImage: "--pre-roll-image",
    };

    private static readonly fontFallbacks = "Helvetica, Arial, sans-serif";

    constructor(
        public variablesConfig: TemplateVariablesConfiguration,
        public imagesConfig: TemplateImagesConfiguration,
        public font: Font,
    ) {}

    private renderFontsImports(): string {
        return this.font ? getFontImport(this.font) : "";
    }

    private renderFontVariable(): string {
        return this.font ? `"${this.font.name}", ${StylesConfiguration.fontFallbacks}` : StylesConfiguration.fontFallbacks;
    }

    public renderImagesStyles(): string {
        return Object.entries(this.imagesConfig).reduce((acc, [key, image]) => {
            const imageVariableName = StylesConfiguration.imagesVariablesMap[key];
            const isImageVariable = image?.value && imageVariableName;

            return isImageVariable ? `${acc}${imageVariableName}: url(${image.value}); ` : acc;
        }, "");
    }

    public renderVariablesStyles(): string {
        return Object.entries(this.variablesConfig).reduce((acc, [key, value]) => {
            const variableName = StylesConfiguration.variablesMap[key];
            let variableValue = value;

            if (key === "font") {
                variableValue = this.renderFontVariable();
            }

            return variableName ? `${acc}${variableName}: ${variableValue}; ` : acc;
        }, "");
    }

    public renderCustomCss(): string {
        if (!this.variablesConfig?.customCss) {
            return "";
        }

        return this.variablesConfig.customCss.replace(/(\r\n|\n|\r)/gm, "");
    }

    public renderStyleTagContent(): string {
        const fonts = this.renderFontsImports();
        const variables = this.renderVariablesStyles();
        const images = this.renderImagesStyles();
        const css = this.renderCustomCss();

        return `${fonts} :root {${variables}${images}} ${css}`;
    }
}
