<template>
    <SrMenu v-model="optionsAreOpened" offset-y left min-width="100" :disabled="disabled">
        <template #activator="{ on, attrs }">
            <SrButton icon v-bind="attrs" :disabled="disabled" v-on="on">
                <SrIcon :active="optionsAreOpened" icon="more" size="xs" />
                <vSnackbar v-model="isToasterActive" timeout="2000" color="success">
                    VAST file url is copied to clipboard!
                </vSnackbar>
            </SrButton>
        </template>
        <SrList dense>
            <SrListItem
                v-for="route in actionRoutesAsArray"
                :key="route.routeName"
                :to="getRoute(route.routeName)"
                :disabled="route.disabled"
            >
                <SrListItemIcon>
                    <SrIcon :icon="route.icon" size="xs" />
                </SrListItemIcon>
                <SrListItemTitle>
                    {{ route.name }}
                </SrListItemTitle>
            </SrListItem>
            <SrListItem @click="openOriginalVideo(item.id)">
                <SrListItemIcon>
                    <SrIcon icon="observe" size="xs" />
                </SrListItemIcon>
                <SrListItemTitle> View Original Video </SrListItemTitle>
            </SrListItem>
            <SrListItem @click="openDynamicVideo(item.id)">
                <SrListItemIcon>
                    <SrIcon icon="observe" size="xs" />
                </SrListItemIcon>
                <SrListItemTitle> View Dynamic Video </SrListItemTitle>
            </SrListItem>
            <SrListItem :disabled="!isVastFileShowable" @click="copyVastFileUrl(item.vastFileUrl)">
                <SrListItemIcon>
                    <SrIcon icon="copy" size="xs" />
                </SrListItemIcon>
                <SrListItemTitle>
                    <span v-if="isVastFileShowable">Copy VAST file URL</span>
                    <span v-else>VAST file is being created...</span>
                </SrListItemTitle>
            </SrListItem>
        </SrList>
    </SrMenu>
</template>

<script>
import { SrButton, SrIcon, SrMenu, SrList, SrListItem, SrListItemTitle, SrListItemIcon } from "@ads/design-system";
import { mapActions, mapMutations } from "vuex";

export default {
    name: "VideoTableActions",
    components: {
        SrButton,
        SrIcon,
        SrMenu,
        SrList,
        SrListItem,
        SrListItemTitle,
        SrListItemIcon,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        actionRoutes: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            optionsAreOpened: false,
            isToasterActive: false,
            isVastFileShowable: false,
        };
    },
    computed: {
        actionRoutesAsArray() {
            return Object.values(this.actionRoutes).filter((route) => {
                if (route.visibilityCondition) {
                    return route.visibilityCondition(this.item);
                }

                return true;
            });
        },
    },
    created() {
        this.checkIfVastFileDownloadIsShowable();
    },
    methods: {
        ...mapMutations({
            removeVideo: "videos/removeVideo",
        }),
        ...mapActions({
            viewOriginalVideo: "videos/viewOriginalVideo",
            viewDynamicVideo: "videos/viewDynamicVideo",
            markForDelete: "videos/markForDelete",
        }),
        showToaster() {
            this.isToasterActive = true;
        },
        getRoute(path) {
            const { id } = this.item;
            return {
                name: path,
                params: { id },
            };
        },
        async openOriginalVideo(id) {
            const response = await this.viewOriginalVideo(id);
            window.open(response.url);
        },
        async openDynamicVideo(id) {
            const response = await this.viewDynamicVideo(id);
            window.open(response.url);
        },
        async copyVastFileUrl(vastFileUrl) {
            await navigator.clipboard.writeText(vastFileUrl);
            this.showToaster();
        },
        async checkIfVastFileDownloadIsShowable() {
            if (this.item.lastGenerated) {
                fetch(this.item.vastFileUrl)
                    .then((response) => {
                        if (response.status === 200) {
                            this.isVastFileShowable = true;
                        } else {
                            this.isVastFileShowable = false;
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.isVastFileShowable = false;
                    });
            }
        },
    },
};
</script>
