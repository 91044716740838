import BusinessEntity from "@/types/businessEntity";
import Filter from "@/modules/filters/types/filter";
import { TableActionTypes } from "@/components/types";

export const listHeaders = [
    {
        text: "Name",
        value: "name",
        align: "left",
        sortable: true,
        cellClass: "datatable-list__name-cell",
    },
    {
        text: "Advertiser",
        value: "businessEntity",
        align: "left",
        sortable: true,
        sort: (a: BusinessEntity, b: BusinessEntity): number => a.name.localeCompare(b.name),
    },
    {
        text: "Start Date",
        value: "startDate",
        align: "left",
        sortable: true,
    },
    {
        text: "End Date",
        value: "endDate",
        align: "left",
        sortable: true,
    },
    {
        text: "Status",
        value: "created",
        align: "left",
        sortable: true,
        sort: (a: Filter, b: Filter): number => a.created.localeCompare(b.created),
    },
    {
        text: "Actions",
        value: "actions",
        align: "right",
        sortable: false,
    },
];

export const listActionRoutes = {
    [TableActionTypes.EDIT]: {
        name: "Edit",
        icon: "Edit",
        routeName: "filters-edit",
    },
};
