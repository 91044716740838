<template>
    <SrModalPage class="modal-form" :show="show" :loading="isLoading" @close="close">
        <template #content>
            <VRow v-show="!isLoading">
                <VCol sm="12" md="6" xl="6">
                    <SrHeadline level="1" weight="bold">Overview/Code</SrHeadline>
                    <br />
                    <SrHeadline level="3" weight="bold">HTML for DSP integration</SrHeadline>
                    <CodeBlock v-if="code" class="mt-8" lang="html" :code="code" />

                    <SrHeadline level="3" weight="bold">HTML for direct publisher integration</SrHeadline>
                    <CodeBlock v-if="codeWithoutDspParams" class="mt-8" lang="html" :code="codeWithoutDspParams" />
                </VCol>
                <VCol sm="12" md="6" lg="5" offset-sm="0" offset-lg="1">
                    <SrHeadline class="mt-11" level="3" weight="bold">Preview</SrHeadline>
                    <TemplatePreviewTabs v-if="template" :template="template" :size="size" :font="font" />
                </VCol>
            </VRow>
        </template>
        <template #footer>
            <ErrorBox :show="showErrorMessage" :message="error" />
        </template>
    </SrModalPage>
</template>

<script>
import { SrModalPage, SrHeadline } from "@ads/design-system";
import TemplatePreviewTabs from "@/modules/templates/components/TemplatePreviewTabs";
import CodeBlock from "@/modules/templates/components/CodeBlock/CodeBlock";
import ErrorBox from "@/components/ErrorBox";
import { mapGetters } from "vuex";
import { CREATIVE_TYPE } from "@/modules/templates/types";

const casinoParameters =
    "dcpBidId=${BID_ID}&dcpLineItemId=${LINE_ITEM_ID}&dcpCampaignId=${CAMPAIGN_ID}&clickTag=${CLICK_URL_ENC}";
const sportParameters = "dcpBidId=${BID_ID}&dcpLineItemId=${LINE_ITEM_ID}&dcpCampaignId=${CAMPAIGN_ID}&clickTag=${CLICK_URL_ENC}";

const linkParameters = {
    [CREATIVE_TYPE.CASINO]: casinoParameters,
    [CREATIVE_TYPE.SPORT]: sportParameters,
};

const getCodeWithoutDspParams = (link, size, params) => `<html>
    <body>
        <iframe
            src="${link}${params ? `?${params}` : ""}"
            style="border:none; width: ${size.width}px; height: ${size.height}px; overflow:hidden;"
            scrolling="no"
        ></iframe>
    </body>
</html>`;

const getCode = (link, size, type) => getCodeWithoutDspParams(link, size, linkParameters[type]);

export default {
    name: "TemplateView",
    components: {
        CodeBlock,
        SrModalPage,
        SrHeadline,
        TemplatePreviewTabs,
        ErrorBox,
    },
    provide: {
        isViewOnly: true,
    },
    data() {
        return {
            template: null,
            show: true,
            error: null,
            isLoading: false,
            steps: [
                {
                    name: "Overview",
                    isValid: true,
                    component: "TemplateFormStepBasics",
                },
            ],
            stepsMeta: [
                {
                    name: "Overview",
                    canContinue: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            sizeById: "templates/sizeById",
            fontById: "templates/fontById",
        }),
        size() {
            return this.template.layout.sizes.find((s) => s.id === this.template.size.id);
        },
        font() {
            const font = this.template.config.font;

            return Number.isInteger(font) ? this.fontById(font) : font;
        },
        showErrorMessage() {
            return Boolean(this.error);
        },
        code() {
            if (!this.template) {
                return null;
            }

            const { size, build, type } = this.template;

            return getCode(build.link, size, type);
        },
        codeWithoutDspParams() {
            if (!this.template) {
                return null;
            }

            const { size, build } = this.template;

            return getCodeWithoutDspParams(build.link, size);
        },
    },
    created() {
        this.fetchTemplate();
    },
    methods: {
        async fetchTemplate() {
            this.isLoading = true;
            const id = Number(this.$route.params.id);
            const typePath = String(this.$route.params.typePath);

            try {
                const template = await this.$store.dispatch("templates/fetchTemplate", {
                    id,
                    typePath,
                });

                if (typeof template.config.transformation === "string") {
                    template.config.transformation = JSON.parse(template.config.transformation);
                }

                this.template = template;
            } catch (error) {
                this.error = error.message || error.response.message;
            }

            this.isLoading = false;
        },
        close() {
            this.$router.push({ name: "templates-root" });
        },
        submit() {
            this.$router.push({ name: "templates-root" });
        },
    },
};
</script>
