// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-23.use[1]!../../../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../../../node_modules/vue-code-highlight/themes/prism.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dynamic-display-mf .theme--light.v-application code{background-color:unset;color:unset}.dynamic-display-mf .code{position:relative}.dynamic-display-mf .code .lang{text-transform:uppercase;background-color:#fff;font-size:12px;padding:2px 5px;position:absolute;top:-10px;left:8px}.dynamic-display-mf .code pre{border:1px solid #dedede;background-color:unset;padding:.5em 1em;border-radius:3px}.dynamic-display-mf .code .buttons{text-align:right}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
