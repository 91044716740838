import BusinessEntity from "@/types/businessEntity";

export enum LOADING_STATES {
    ITEMS = "videos",
    CREATE = "videosCreate",
    EDIT = "videosEdit",
    AD_BUILDER_TEMPLATES = "adBuilderTemplates",
}

export enum VIDEO_TYPE {
    SPORT = "SPORT",
    CASINO = "CASINO",
}

export interface AdBuilderTemplate {
    id: string;
    name: string;
}

export interface VideosState {
    items: Video[];
    layouts: VideoLayout[];
    loadingStates: {
        [key in LOADING_STATES]: boolean;
    };
    adBuilderTemplates: AdBuilderTemplate[];
}

export interface Video {
    id: number;
    name: string;
    originalVideoResolution: string;
    originalVideoBitrate: string;
    recommendationType: string;
    rebuiltInterval: number;
    businessEntity: number | BusinessEntity;
    status: string;
    created: Date;
    lastGenerated: Date;
    backgroundImageUrl: string;
    videoUrl: string;
    type: VIDEO_TYPE;
    layout: number | VideoLayout;
    filterId?: number;
    productSetId?: string;
    templateId?: string;
}

export type VideoLayout = {
    id: number;
    name: string;
    type: VIDEO_TYPE;
}

export enum VIDEO_MIMETYPES {
    MP4 = "video/mp4",
}

export interface FetchUrlResponse {
    url: string;
}
