import { tournamentMappingsService } from "@/modules/tournaments/services/TournamentMappingsService";
import TournamentMapping from "../types/TournamentMapping";

export default {
    fetch(
        _,
        { sport, language, advertiserId }: { sport: string; language: string; advertiserId: string },
    ): Promise<TournamentMapping[]> {
        return tournamentMappingsService.getTournamentMappings(sport, language, advertiserId);
    },

    save(
        _,
        {
            tournamentMappings,
            sport,
            language,
            advertiserId,
        }: {
            tournamentMappings: TournamentMapping[];
            sport: string;
            language: string;
            advertiserId: number;
        },
    ): Promise<TournamentMapping[]> {
        return tournamentMappingsService.saveTournamentMappings(tournamentMappings, sport, language, advertiserId);
    },
};
