import { Build, Size } from "@/modules/templates/types";
import { TableActionTypes } from "@/components/types";
import BusinessEntity from "@/types/businessEntity";

export const listHeaders = [
    {
        text: "ID",
        value: "id",
        align: "center",
        sortable: true,
        width: "80px",
    },
    {
        text: "Name",
        value: "name",
        align: "left",
        sortable: true,
        cellClass: "datatable-list__name-cell",
    },
    {
        text: "Last Modified",
        value: "modifiedAt",
        align: "left",
        sortable: true,
    },
    {
        text: "Size",
        value: "size",
        align: "left",
        sortable: true,
        sort: (a: Size, b: Size): number => a.id - b.id,
    },
    {
        text: "Advertiser",
        value: "businessEntity",
        align: "left",
        sortable: true,
        sort: (a: BusinessEntity, b: BusinessEntity): number => a.name.localeCompare(b.name),
    },
    {
        text: "Type",
        value: "type",
        align: "left",
        sortable: true,
    },
    {
        text: "Build Status",
        value: "build",
        align: "left",
        sortable: true,
        sort: (a: Build, b: Build): number => a.status.localeCompare(b.status),
    },
    {
        text: "Status",
        value: "status",
        align: "left",
        sortable: true,
    },
    {
        text: "Actions",
        value: "actions",
        align: "right",
        sortable: false,
    },
];

export const listActionRoutes = {
    [TableActionTypes.EDIT]: {
        name: "Edit",
        icon: "Edit",
        routeName: "templates-edit",
    },
    [TableActionTypes.DUPLICATE]: {
        name: "Duplicate",
        icon: "Copy",
        routeName: "templates-duplicate",
    },
};
