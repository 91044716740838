import { RouteConfig } from "vue-router";
import Templates from "./views";
import TemplatesList from "./views/list";
import TemplatesCreate from "./views/create";
import TemplatesEdit from "./views/edit";
import TemplatesDuplicate from "./views/duplicate";
import TemplateView from "./views/view";

const routes: RouteConfig[] = [
    {
        name: "templates-root",
        path: "/templates",
        component: Templates,
        redirect: { name: "templates-list" },
        children: [
            {
                name: "templates-list",
                path: "/",
                component: TemplatesList,
                children: [
                    {
                        name: "templates-list-delete",
                        path: "delete/:typePath/:id",
                        component: null,
                    },
                ],
            },
            {
                name: "templates-create",
                path: "create",
                component: TemplatesCreate,
            },
            {
                name: "templates-edit",
                path: "edit/:typePath/:id",
                component: TemplatesEdit,
            },
            {
                name: "templates-duplicate",
                path: "duplicate/:typePath/:id",
                component: TemplatesDuplicate,
            },
            {
                name: "templates-view",
                path: "view/:typePath/:id",
                component: TemplateView,
            },
        ],
    },
];

export default routes;
