import { RouteConfig } from "vue-router";
import Filters from "./views";
import FiltersList from "./views/list";
import FiltersCreate from "./views/create";
import FiltersEdit from "./views/edit";

const routes: RouteConfig[] = [
    {
        name: "filters-root",
        path: "/filters",
        component: Filters,
        redirect: { name: "filters-list" },
        children: [
            {
                name: "filters-list",
                path: "/",
                component: FiltersList,
                children: [
                    {
                        name: "filters-list-delete",
                        path: "delete/:id",
                        component: null,
                    },
                ],
            },
            {
                name: "filters-create",
                path: "create",
                component: FiltersCreate,
            },
            {
                name: "filters-edit",
                path: "edit/:filterId",
                component: FiltersEdit,
            },
        ],
    },
];

export default routes;
