import { Video, LOADING_STATES, VideosState, VideoLayout } from "@/modules/videos/types";

export default {
    removeVideo(state: VideosState, id: number): void {
        state.items = state.items.filter((video) => video.id !== id);
    },
    setVideos(state: VideosState, items: Video[]): void {
        state.items = items;
    },
    setLoading(state: VideosState, { key, value }: { key: LOADING_STATES; value: boolean }): void {
        state.loadingStates = {
            ...state.loadingStates,
            [key]: value,
        };
    },
    setVideoLayouts(state: VideosState, layouts: VideoLayout[]): void {
        state.layouts = layouts;
    },
    setAdBuilderTemplates(state: VideosState, templates: any[]): void {
        state.adBuilderTemplates = templates;
    },
};
