<template>
    <div>
        <SrSelect
            v-model="templateFilterId"
            :disabled="disabled || !templateSportId"
            :label="filtersLabel"
            v-bind="$attrs"
            :items="filtersForDisplay"
            item-value="id"
            item-text="name"
            :loading="loadingFilters"
            clearable
            v-on="$listeners"
        >
        </SrSelect>
    </div>
</template>
<script>
import { SrSelect } from "@ads/design-system";
import { filtersService } from "@/modules/filters/services";

export default {
    name: "SportConfigurationFilterSelect",
    components: {
        SrSelect,
    },
    props: {
        templateSportId: {
            type: String,
            default: null,
            required: false,
        },
        templateFilterId: {
            type: String,
            default: null,
            required: false,
        },
        advertiser: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data() {
        return {
            sports: [],
            filters: [],
            filtersForDisplay: [],
            filtersLabel: "Select filter",
            sportsLabel: "Sports",
            loadingFilters: false,
        };
    },
    watch: {
        templateSportId() {
            this.fetchFilters();
        },
    },
    created() {
        this.fetchFilters();
    },
    methods: {
        mapToDisplayableFilter(filter) {
            return {
                id: filter.filterId,
                name: filter.name,
            };
        },
        fetchFilters() {
            let advertiserId;

            if (typeof this.advertiser === "object") {
                advertiserId = this.advertiser.dspId;
            } else {
                advertiserId = this.advertiser;
            }

            this.loadingFilters = true;
            filtersService
                .getActiveForAdvertiserAndSportIdId(advertiserId, this.templateSportId)
                .then((filterResponse) => {
                    this.filters = filterResponse;
                    this.filtersForDisplay = this.filters.map((filter) => this.mapToDisplayableFilter(filter));
                    if (this.filters.length === 0) {
                        this.filtersLabel = "No active filters";
                    } else {
                        this.filtersLabel = "Select filter";
                    }

                    this.loadingFilters = false;
                })
                .catch(() => {
                    this.loadingFilters = false;
                });
        },
    },
};
</script>
