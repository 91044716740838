<template>
    <VForm ref="form" v-model="isFormValid" class="dynamic-display-video-form" :disabled="isLoading">
        <VRow v-if="video.type === VIDEO_TYPE.SPORT">
            <VCol class="ml-1" sm="12" lg="6">
                <SrInput v-model="video.productSetId" label="Product-set Id" required />
            </VCol>
        </VRow>
        <VRow>
            <VCol class="ml-1" sm="12" lg="6">
                <SrHeadline level="3" class="mb-3" weight="bold">
                    Video
                    <span class="required-indicator">*</span>
                </SrHeadline>
                <VideoUpload
                    v-model="video.file"
                    class="video-upload"
                    name="ad"
                    :max-size="maxVideoSize"
                    :presigned-video-url="video.presignedVideoUrl"
                    is-required
                    aspect-ratio="16 / 9"
                    :accept="acceptFileFormats"
                />
            </VCol>
        </VRow>
        <VRow v-if="video.type !== VIDEO_TYPE.SPORT">
            <VCol class="ml-1 mt-1" sm="12" lg="6">
                <SrHeadline level="3" class="mb-3" weight="bold"> Background Image</SrHeadline>
                <FileUpload
                    v-model="images.backgroundImage"
                    class="mt-6"
                    label="Upload a Background Image"
                    button-label="Upload Image"
                    is-required
                    :accept="acceptImageFormats"
                    :max-size="maxBackgroundImageSize"
                    @change="onImageChange($event, 'backgroundImage')"
                />
            </VCol>
        </VRow>
        <VRow v-if="video.type === VIDEO_TYPE.SPORT">
            <VCol class="ml-1" sm="12" lg="6">
                <SrSelect
                    v-model="video.templateId"
                    label="Select template"
                    :items="adBuilderTemplates"
                    item-value="id"
                    item-text="label"
                    class="mt-4 mb-1"
                    clearable
                />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrSelect, SrInput } from "@ads/design-system";
import VideoUpload from "@/modules/videos/components/VideoForm/VideoFormStepUpload/VideoUpload.vue";
import { VIDEO_MIMETYPES, VIDEO_TYPE } from "@/modules/videos/types";
import FileUpload from "@/components/FileUpload";
import { mapActions, mapGetters } from "vuex";
import { MAX_VIDEO_SIZE, MAX_BACKGROUND_IMAGE_SIZE } from "../config";

export default {
    name: "VideoFormStepUpload",
    components: {
        SrSelect,
        VideoUpload,
        SrHeadline,
        FileUpload,
        SrInput,
    },
    inject: {
        isEditing: {
            default: false,
        },
    },
    model: {
        prop: "video",
        event: "input",
    },
    props: {
        video: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        presignedBackgroundImageUrl: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            images: {
                backgroundImage: null,
            },
            acceptImageFormats: "image/webp,image/png,image/svg+xml,image/gif,image/jpeg",
            isFormValid: this.isValid,
            acceptFileFormats: VIDEO_MIMETYPES.MP4,
            maxVideoSize: MAX_VIDEO_SIZE,
            maxBackgroundImageSize: MAX_BACKGROUND_IMAGE_SIZE,
        };
    },
    computed: {
        ...mapGetters({
            adBuilderTemplates: "videos/adBuilderTemplates",
        }),
        VIDEO_TYPE() {
            return VIDEO_TYPE;
        },
    },
    watch: {
        video() {
            this.$emit("update:video", this.video);
        },
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
        "video.presignedBackgroundImageUrl": function () {
            if (this.video.presignedBackgroundImageUrl) {
                this.setBackgroundImage();
            }
        },
        "video.type": function () {
            this.setDefaultTemplateId();
        },
    },
    created() {
        if (!this.adBuilderTemplates.length) this.fetchTemplates();
        this.setDefaultTemplateId();
        if (this.video.presignedBackgroundImageUrl) {
            this.setBackgroundImage();
        }

        console.log(this.video);
    },
    methods: {
        ...mapActions({
            fetchTemplates: "videos/fetchAdBuilderTemplates",
        }),
        setDefaultTemplateId() {
            const video = this.video;
            if (video.type === VIDEO_TYPE.SPORT && !video.templateId) {
                this.$set(this.video, "templateId", this.templates[0].id);
            }
        },
        createMockImage(name, type = "mock") {
            return new File([], name, { type });
        },
        onImageChange(file, name) {
            this.video.willUploadBackgroundImage = true;
            this.$emit("image-change", { file, name });
        },
        setBackgroundImage() {
            if (this.video.presignedBackgroundImageUrl) {
                this.images.backgroundImage = this.createMockImage("backgroundImage");
            }
        },
    },
};
</script>

<style lang="scss">
.dynamic-display-video-form {
    .video-upload {
        width: 100%;
    }

    .required-indicator {
        color: $brand-red;
        margin-left: 4px;
    }
}
</style>
