<template>
    <VForm ref="form" v-model="isFormValid" class="template-customization">
        <VRow no-gutters>
            <VCol sm="11" lg="10">
                <div>
                    <VRadioGroup v-model="templateStrategy" class="mt-0 mb-1">
                        <div>
                            <SrRadioButton label="Popularity trends" :value="templateStrategies.popularity" />
                            <div class="description">
                                The recommendation will be made based on analysis of user behavior. The most popular games will be
                                displayed.
                            </div>
                        </div>
                        <div class="inline mt-10 align-start">
                            <SrRadioButton label="Manual selection" :value="templateStrategies.manual" />
                            <SrInfoBox class="ml-2"
                                >If you select this option, only games from the selected category will be displayed.
                            </SrInfoBox>
                        </div>
                    </VRadioGroup>
                </div>
                <SrSelect
                    v-model="template.config.category"
                    label="Category"
                    :items="categories"
                    searchable
                    :disabled="templateStrategy !== templateStrategies.manual"
                    :required="templateStrategy === templateStrategies.manual"
                    :error-messages="categoriesErrors"
                    :loading="categoriesLoading"
                />
                <VRadioGroup v-if="showCompleteFeatures" v-model="template.config.oddsType" hide-details class="mt-0">
                    <SrRadioButton label="Money Line / 3-way odds" :value="oddType.THREE_WAY_ODDS" />
                    <SrRadioButton label="Money Line + Spreads + Totals" :value="oddType.SPREADS_TOTALS" />
                </VRadioGroup>
            </VCol>
        </VRow>
        <VRow no-gutters class="mt-8">
            <VCol sm="12" md="12" lg="9" xl="7">
                <div class="d-flex align-center mb-4">
                    <SrHeadline level="3" class="mb-0 section-headline brand-blue--text"> Jackpot </SrHeadline>
                    <SrInfoBox class="ml-2">Jackpot</SrInfoBox>
                </div>
                <SrCheckbox
                    v-model="template.config.isJackpotEnabled"
                    class="brand-blue--text mt-0 pt-0"
                    label="Show jackpot offer"
                />
                <div class="disable-area" :class="{ active: !template.config.isJackpotEnabled }">
                    <SrSelect
                        v-model="template.config.currency"
                        label="Currency"
                        :items="currencies"
                        item-text="labelCodeWithSymbol"
                        item-value="code"
                        searchable
                        :disabled="!template.config.isJackpotEnabled"
                    />
                    <SrInput
                        v-model="template.config.jackpotOfferLine"
                        label="Jackpot Line offer"
                        :rules="template.config.isJackpotEnabled ? rules.jackpotOfferLine : []"
                        :disabled="!template.config.isJackpotEnabled"
                    />
                    <div v-if="!template.config.isJackpotEnabled" class="disable-area-overlay" />
                </div>
                <div class="divider-b mt-2 mb-8" />
                <SrHeadline class="mb-4 section-headline brand-blue--text"> Number of casino games to be displayed </SrHeadline>
                <SrInput
                    v-model.number="template.config.amountOfEvents"
                    v-bind="$attrs"
                    label="Enter Number"
                    required
                    type="number"
                    min="1"
                    max="5"
                    :rules="amountOfEventsRules"
                />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrCheckbox, SrHeadline, SrInfoBox, SrInput, SrRadioButton, SrSelect } from "@ads/design-system";
import { metadataService } from "@/modules/templates/services";
import { metadataNetworkError } from "@/errors";
import CURRENCY_CODES from "@/utils/currencyCodes.json";
import { getMinMaxValidation, textValidationRules } from "@/validation/rules";
import BaseConfiguration from "./BaseConfiguration";

const MissingCategoryErrorMessage = "Please select another category from those currently available.";

const templateStrategies = {
    popularity: "popularity",
    manual: "manual",
};

export default {
    name: "CasinoConfiguration",
    components: {
        SrCheckbox,
        SrInput,
        SrHeadline,
        SrRadioButton,
        SrSelect,
        SrInfoBox,
    },
    extends: BaseConfiguration,
    data() {
        return {
            isFormValid: this.isValid,
            templateStrategy: templateStrategies.popularity,
            templateStrategies,
            categories: [],
            currencies: CURRENCY_CODES,
            categoriesErrors: [],
            categoriesLoading: false,
            rules: {
                jackpotOfferLine: textValidationRules({ options: { min: 0, max: 50 } }),
            },
            amountOfEventsRules: getMinMaxValidation(1, 5),
        };
    },
    watch: {
        "template.businessEntity": function (affiliateId) {
            this.fetchCategories(affiliateId.dspId);
        },
        templateStrategy(value) {
            if (value === templateStrategies.popularity) {
                this.template.config.category = null;
            }
        },
    },
    created() {
        this.fetchCategories(this.template.businessEntity.dspId);
    },
    mounted() {
        if (this.template.config.category) {
            this.templateStrategy = templateStrategies.manual;
        }
    },
    methods: {
        async fetchCategories(affiliateId) {
            this.categoriesLoading = true;

            try {
                this.categories = await metadataService.getCasinoCategories(affiliateId);

                if (this.template.config.category && this.categories.indexOf(this.template.config.category) === -1) {
                    this.categoriesErrors = [MissingCategoryErrorMessage];
                }

                this.categoriesErrors = [];
            } catch (e) {
                this.categoriesErrors = [metadataNetworkError.message];
            } finally {
                this.categoriesLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
@import "~@ads/design-system/src/scss/variables";

.theme--light.v-label {
    color: $brand-blue;
}

.template-customization {
    .description {
        font-size: 14px;
    }

    .disable-area {
        position: relative;

        &.active > * {
            pointer-events: none;
            user-select: none;
        }
    }

    .disable-area-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        filter: grayscale(1);
        z-index: 1000;
        background-color: rgba(255, 255, 255, 0.7);
    }
}
</style>
