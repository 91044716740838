import { TemplatesState } from "@/modules/templates/types";

export enum LOADING_STATES {
    ITEMS = "templates",
    CREATE_CREATIVE = "createCreative",
}

const state: TemplatesState = {
    items: [],
    fonts: [],
    layouts: [],
    loadingStates: {
        [LOADING_STATES.ITEMS]: false,
        [LOADING_STATES.CREATE_CREATIVE]: false,
    },
};

export default state;
