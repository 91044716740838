<template>
    <section>
        <FilterForm
            v-model="filter"
            submit-action="filters/create"
            after-close-route="filters-root"
            after-submit-route="filters-root"
            :after-submit-route-params="{
                createSuccess: { name: filter.name, advertiser: filter.businessEntity },
            }"
            :after-close-route-params="{
                close: { advertiser: filter.businessEntity },
            }"
        />
        <RouterView :filter="filter" />
    </section>
</template>

<script>
import FilterForm from "@/modules/filters/components/FilterForm";

export default {
    name: "FiltersCreate",
    components: {
        FilterForm,
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            filter: {
                name: "",
                active: false,
                sportId: "soccer",
                tournament: [],
                businessEntity: null,
                filterDate: {
                    startDate: null,
                    endDate: null,
                    daysAhead: null,
                    calendarWeeksAhead: null,
                    dayOfWeek: [],
                },
            },
            isLoading: true,
        };
    },
    mounted() {
        this.setAdvertiser();
    },
    methods: {
        setAdvertiser() {
            const advertiser = this.$route.params || JSON.parse(localStorage.getItem("businessEntity"));

            if (advertiser) {
                localStorage.setItem("businessEntity", JSON.stringify(advertiser));
                this.$set(this.filter, "businessEntity", advertiser);
            }
            this.isLoading = false;
        },
    },
};
</script>
